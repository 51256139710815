import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getFullName } from '../../lib/mixing';
import { getMonthAndDate, formatPhoneNumber } from '../../lib/mixing';

import fskLogo from '../../../../common/assets/images/logo_fsk.png';

import { ReactComponent as ArrowLeftIcon } from '../../assets/images/icons/arrow-left-icon.svg';
import TeamsIcon from '../../assets/images/icons/teams-icon.png';
import WhatsaAppIcon from '../../assets/images/icons/whatsapp-icon.png';

import Navigation from '../../components/Navigation/Navigation';

const PhonebookUser = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.phonebook.user);
  const [showImageModal, setShowImageModal] = useState(false);

  useEffect(() => {
    if (Object.keys(user).length < 1) {
      navigate('/hr/phonebook');
    }
  }, [user]);

  const rederectTo = (app, data) => {
    if (app === 'teams') {
      window.open(`https://teams.microsoft.com/l/chat/0/0?users=${data}`, '_blank');
    } else if (app === 'whatsapp') {
      window.open(`https://api.whatsapp.com/send?phone=7${data}`);
    }
  };

  return (
    <div className="phonebook">
      <Navigation blockName="phonebook" navTitle="Поиск по сотрудникам" path="/hr/phonebook" />
      <div className="phonebook__block">
        <div className="phonebook__block__content">
          <div className="phonebook__block__title">Карточка сотрудника</div>
          <div className="phonebook__block__info">
            <div className="phonebook__block__info-user">
              <div className="phonebook__block__info-user-top">
                <img
                  src={user.avatar ? user.avatar : fskLogo}
                  onClick={(e) => setShowImageModal(true)}
                  alt="user"
                />
                <div className="phonebook__block__info-user-top-data">
                  <p>{getFullName(user)}</p>

                  {user.email !== '' && (
                    <button
                      className="app-link teams"
                      onClick={(e) => rederectTo('teams', user.email)}
                    >
                      <img src={TeamsIcon} alt="teams-icon" /> Написать в тимз
                    </button>
                  )}
                  {user.phone !== '' && (
                    <button
                      className="app-link whatsapp"
                      onClick={(e) => rederectTo('whatsapp', user.phone)}
                    >
                      <img src={WhatsaAppIcon} alt="whatsapp-icon" />
                      Написать в Whatsapp
                    </button>
                  )}
                </div>
              </div>
              <div className="phonebook__block__info-user-bottom">
                <h4>Информация</h4>
                <ul>
                  <li>
                    <div className="light-color">Должность</div>
                    <div>{user.position ? user.position : '-'}</div>
                  </li>
                  <li>
                    <div className="light-color">Подразделение</div>
                    <div>{user.department ? user.department : '-'}</div>
                  </li>
                  <li>
                    <div className="light-color">Дата рождения</div>
                    <div>{user.birthday ? getMonthAndDate(user.birthday) : '-'}</div>
                  </li>
                  <li>
                    <div className="light-color">Телефон</div>
                    <div>{user.phone ? formatPhoneNumber(user.phone) : '-'}</div>
                  </li>
                  <li>
                    <div className="light-color">Рабочий телефон</div>
                    <div>-</div>
                  </li>
                  <li>
                    <div className="light-color">Почта</div>
                    <div>
                      {user.email ? <a href={`mailto:${user.email}`}>{user.email}</a> : '-'}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showImageModal && (
        <div className="phonebook-image-view">
          <div className="phonebook-image-view-top-part">
            <ArrowLeftIcon
              onClick={(e) => {
                setShowImageModal(false);
              }}
            />
          </div>
          <div className="phonebook-image-view-bottom-part">
            <img
              src={user.avatar ? user.avatar : fskLogo}
              onClick={(e) => setShowImageModal(true)}
              alt="fsk-logo"
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default PhonebookUser;
