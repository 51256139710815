import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TimesBar from './TimesBar';

import './MeetingItem.css';

import 'animate.css';

export default function MeetingItem({
  meeting,
  userEmail,
  setUserMeetingRoomsHandle,
  activeFilterValues,
  onRenderMeetingItem
}) {
  const bookingDisabled = useSelector((state) => state.bookings.bookingDisabled);
  const [isScheduleModalOpen, setisScheduleModalOpen] = useState(false);
  const dispatch = useDispatch();

  const [activeMeetingItem, SetActiveMeetingItem] = useState({
    Show: false,
    MeetingItem: {},
    setShow: (data) => {
      SetActiveMeetingItem({ ...activeMeetingItem, Show: data, MeetingItem: {} });
    }
  });

  const scheduleModalHandle = () => {
    const Busytimes = meeting.schedules
      .filter((schedule) => schedule.is_busy === true)
      .map((time) => time.time);
    dispatch({
      type: 'SetscheduleModalData',
      scheduleModalData: {
        show: !bookingDisabled && true,
        startTime: '',
        endTime: '',
        room: meeting.email,
        schedules: meeting.schedules,
        Busytimes: Busytimes,
        Name: meeting.name,
        Description: meeting.description
      }
    });
  };

  const HandleActiveMeetingItem = () => {
    scheduleModalHandle();
  };

  useEffect(() => {
    if (
      meeting.schedules
        .map((schedule) => {
          return schedule.is_busy
            ? schedule.required_attendees
                .map((user) => user.email.toLowerCase() === userEmail.toLowerCase())
                .indexOf(true) !== -1
            : false;
        })
        .indexOf(true) !== -1
    ) {
      setUserMeetingRoomsHandle(meeting.email);
    }

    onRenderMeetingItem();
  }, []);

  return (
    <React.Fragment>
      {meeting && (
        <div
          className={[
            'meeting-item justify-content-between animate__animated animate__fadeInUp',
            isScheduleModalOpen ? 'schedule_modal_open' : '',
            meeting.display === 'block' ? 'd-flex ' : 'd-none'
          ].join(' ')}
        >
          <div className="meeting-details py-2 px-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="m-0 p-0 h5">{meeting.name}</div>
            </div>
            <p>
              Этаж: <span className="fw-bold">{meeting.floor}</span>
            </p>
            <p>
              Вместимость: <span className="fw-bold">{meeting.seats}</span>
            </p>
            <div className="lighter">Описание</div>

            {meeting.description && <p className="mt-2">{meeting.description}</p>}
          </div>

          <div className="meeting-bookings-times d-flex">
            <div className="meeting-bookins-times-container d-flex">
              {meeting.schedules && (
                <>
                  <TimesBar
                    email={meeting.email}
                    name={meeting.name}
                    description={meeting.description}
                    schedules={meeting.schedules}
                    setisScheduleModalOpen={setisScheduleModalOpen}
                    activeFilterValues={activeFilterValues}
                  />
                  <div className="vertical-bar-time vertical-bar-time-desktop"></div>
                </>
              )}
            </div>
          </div>
          {!bookingDisabled && (
            <div
              className="text-center w-100 btn justify-content-center align-items-center go-to-meeting mt-2"
              onClick={() => HandleActiveMeetingItem(meeting)}
            >
              Забронировать
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
}
