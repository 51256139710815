import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getStaticPagesCategories, getTemplateCategories } from '../../api/hrApi';
import { getUserLocalData } from '../../../../common/lib/handleAuthData';
import { getGreenMyInfo, getCategory } from '../../../green_button/api/greenApi';

import { ReactComponent as ArrowUpIcon } from '../../assets/images/icon/ArrowUp.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/icon/ArrowDown.svg';
import { ReactComponent as ArrowRight } from '../../assets/images/icon/ArrowRight.svg';

import Navigation from '../../components/Navigation/Navigation.jsx';
import News from '../../components/News/News.jsx';

import '../../assets/css/index.scss';

const Main = () => {
  const dispatch = useDispatch();
  const [showInfo, setShowInfo] = useState(false);
  const [countData, setCountData] = useState({ pending_count: 0, total_count: 0 });

  const [pages, setPages] = useState([]);
  const navigate = useNavigate();
  const showInfoHandler = () => {
    setShowInfo(!showInfo);
  };
  const [specialReferences, setSpecialReferences] = useState();
  const [knopkaAccess, setKnopkaAccess] = useState(false);

  const getAppData = () => {
    getGreenMyInfo()
      .then((resp) => {
        if (resp.status == 200) {
          getCategory(false).then((grCatResp) => {
            if (grCatResp?.status == 200) {
              setKnopkaAccess(true);
            }
          });
        }

        const allCat = resp?.data;

        if (allCat.pending_count || allCat.total_count) {
          setCountData({
            pending_count: allCat.pending_count,
            total_count: allCat.total_count
          });
        }
      })
      .catch(() => {
        const message = 'Не удалось получить !';
      });
  };

  const getSpecialReferences = () => {
    getTemplateCategories()
      .then((resp) => {
        const category = resp.data?.find(
          (item) => item.templates.length > 0 && item.name === 'Спецсправки'
        );

        if (!category) return;

        const specCategoryies = category.templates?.map((spec) => {
          return {
            ...spec,
            category: category.id
          };
        });

        setSpecialReferences(specCategoryies);
      })
      .catch((error) => console.info(error))
      .finally(() => dispatch({ type: 'GlOBAL_LOADER', show: false }));
  };

  const getPages = () => {
    dispatch({ type: 'GlOBAL_LOADER', show: true });
    getStaticPagesCategories()
      .then((resp) => {
        const pages = resp.data
          .filter((item) => item.pages.length > 0)
          .sort((a, b) => {
            return a.index > b.index ? 1 : b.index > a.index ? -1 : 0;
          });
        setPages(pages);
      })
      .catch((error) => console.info('getPages error', error))
      .finally(() => dispatch({ type: 'GlOBAL_LOADER', show: false }));
  };

  const onLoad = () => {
    getPages();
    getSpecialReferences(); // Спецсправки
    getAppData();
  };

  useEffect(() => {
    onLoad();
  }, []);

  const showMeetings = useSelector((state) => state.common.showMeetings);
  const { ssoUserKey, greenUserKey } = getUserLocalData();

  return (
    <div className="main">
      <Navigation blockName="main" navTitle="main" />
      <div className="main__info">
        <ul className="main__info-list">
          <li className="main__info-item">
            <div className="main__info-item-wrapper first" onClick={showInfoHandler}>
              <h3>Новости</h3>
              {showInfo ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </div>
            {showInfo && <News />}
          </li>

          {(ssoUserKey || greenUserKey) && knopkaAccess && (
            <li className="main__info-item" onClick={() => navigate('/inbox')} key="">
              <div className="main__info-item-wrapper">
                <h3>Входящие задачи</h3>
                <div>
                  <span className="is-hidden">{JSON.stringify(countData)}</span>

                  {countData.pending_count ? (
                    <span className="count-mr-1 count-animated">{countData.pending_count}</span>
                  ) : (
                    ''
                  )}

                  <span className="count count-mr-1">{countData.total_count}</span>
                  <ArrowRight className="cursor-pointer" />
                </div>
              </div>
            </li>
          )}

          <li className="main__info-item" onClick={() => navigate('/hr/newsline')}>
            <div className="main__info-item-wrapper">
              <h3>Лента</h3>
              <ArrowRight />
            </div>
          </li>

          <li className="main__info-item" onClick={() => navigate('/hr/phonebook')}>
            <div className="main__info-item-wrapper">
              <h3>Поиск по сотрудникам</h3>
              <ArrowRight />
            </div>
          </li>

          {showMeetings && (
            <li className="main__info-item" onClick={() => navigate('/bookings/')}>
              <div className="main__info-item-wrapper">
                <h3>Бронирование переговорных</h3>
                <ArrowRight />
              </div>
            </li>
          )}

          <li className="main__info-item" onClick={() => navigate('/hr/references')}>
            <div className="main__info-item-wrapper">
              <h3>Справки</h3>
              <ArrowRight />
            </div>
          </li>

          {specialReferences?.map((reference) => (
            <li
              className="main__info-item"
              onClick={() => navigate(`/hr/references/${reference.category}/${reference.id}`)}
              key={reference.id}
            >
              <div className="main__info-item-wrapper">
                <h3>{reference.name}</h3>
                <ArrowRight />
              </div>
            </li>
          ))}

          {pages &&
            pages.map((category) => (
              <li
                className="main__info-item"
                onClick={() => navigate(`/hr/static-pages/${category.id}`)}
                key={category.id}
              >
                <div className="main__info-item-wrapper">
                  <h3>{category.title}</h3>
                  <ArrowRight />
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default Main;
