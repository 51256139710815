import React from 'react';
import { Outlet } from 'react-router-dom';

import SettingsModal from '../components/SettingsModal/SettingsModal.jsx';
import SuccessModal from '../components/modals/Success.jsx';

import '../../../common/assets/fonts/MuseoSansCyrl/stylesheet.css';
import '../../../common/assets/fonts/testFont/stylesheet.css';

const HrClientLayout = () => {
  return (
    <>
      <div className="App hr-client-layout">
        <SettingsModal />
        <SuccessModal />
        <Outlet />
      </div>
    </>
  );
};
export default HrClientLayout;
