import React from 'react';
import parse from 'html-react-parser';

import ItemCollapse from '../UI/ItemCollapse.jsx';

import ErrorBoundary from 'antd/lib/alert/ErrorBoundary.js';

const TaskOldApi = ({ task }) => {
  return (
    <>
      {task?.pending_task ? (
        <p className="processing-badge d-inline left-1">
          в обработке
          <span className="loader-dot">.</span>
          <span className="loader-dot">.</span>
          <span className="loader-dot">.</span>
        </p>
      ) : null}

      <ul className="main-list font-weight-500 collapseble p-0">
        {task.description && Array.isArray(task.description) ? (
          <ItemCollapse collapsed={false} title={'Описание'}>
            <ul className="list-unstyled">
              {task.description.map((item, i) => {
                switch (item.type) {
                  case 'text':
                    return (
                      <li key={i}>
                        <span className="font-weight-500">{item.name && `${item.name} : `} </span>{' '}
                        {item.value}
                      </li>
                    );
                  case 'html':
                    return (
                      <li key={i}>
                        <ErrorBoundary fallback={<p>Не удалось отобразить элемент</p>}>
                          <span className="font-weight-500">{item.name && `${item.name} : `}</span>{' '}
                          {parse(
                            item.value.search('/table/') === -1
                              ? `<div class="auto-overflow">${item.value}</div>`
                              : item.value
                          )}
                        </ErrorBoundary>
                      </li>
                    );
                  default:
                    return '';
                }
              })}
            </ul>
          </ItemCollapse>
        ) : (
          ''
        )}

        {task.execution_story ? (
          <ItemCollapse collapsed={true} title={'История выполнения'}>
            {parse(task.execution_story)}
          </ItemCollapse>
        ) : (
          ''
        )}

        {task.files ? (
          <ItemCollapse collapsed={true} title={'Документы'}>
            {task.files
              ? task.files.map((item, id) => (
                  <li key={id} className="flex align-center">
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                      <div className="file-icon text-uppercase justify-content-center">
                        {item.ext === '' ? '!' : item.ext}
                      </div>
                    </a>
                    <span className="color-orrange">
                      <a href={item.url} target="_blank" rel="noopener noreferrer">
                        {item.filename}
                      </a>
                    </span>
                  </li>
                ))
              : ''}
          </ItemCollapse>
        ) : (
          ''
        )}
      </ul>
    </>
  );
};
export default TaskOldApi;
