import React from 'react';

import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
registerLocale('ru', ru);

const DateTimePicker = (props) => {
  const handleChange = (date, e) => {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }
    const { onChange } = props;
    if (onChange) {
      date = date === null ? new Date() : date;
      onChange(date);
    }
  };

  return <DatePicker {...props} onChange={handleChange} />;
};

// DateTimePicker.defaultProps = {
//   onChange: () => {},
//   startDate: new Date()
// };

// DateTimePicker.propTypes = {
//   onChange: PropTypes.func,
//   startDate: PropTypes.object,
// };

export default DateTimePicker;
