import { useEffect } from 'react';

import { getUserLocalData } from '../lib/handleAuthData';

const style = {
  info: {
    position: 'fixed',
    left: '1.5rem',
    bottom: '1.5rem',
    opacity: '0.31',
    fontSize: '1.0rem',
    pointerEvents: 'none',
    zIndex: 1
  }
};

export default function UserBriefData() {
  const { userEmail = '', userFio = '' } = getUserLocalData() || {};
  useEffect(() => {
    if (userFio || userEmail) {
      console.info('Вы вошли под учетной записью: ' + userFio);
      console.info('Ваш e-mail: ' + userEmail);
    }
  }, [userFio, userEmail]);
  return;
}
