import TextArea from 'antd/lib/input/TextArea';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { CreateComment } from '../../api/hrApi';

import Button from '../Buttons/Button.jsx';

const AddComment = ({ articleID, newCommentsData }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [isDisabled, SetIsDisabled] = useState(true);

  const sendFormData = () => {
    dispatch({ type: 'GlOBAL_LOADER', show: true });
    CreateComment(articleID, { message: message })
      .then(({ data }) => {
        setMessage('');
        newCommentsData(data);
      })
      .catch((error) => console.info(error))
      .finally(() => dispatch({ type: 'GlOBAL_LOADER', show: false }));
  };

  useEffect(() => {
    if (message.length > 0) {
      SetIsDisabled(false);
    } else {
      SetIsDisabled(true);
    }
  }, [message]);

  return (
    <div className="comments-add">
      <TextArea
        type="text"
        placeholder="Написать комментарий..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <Button className="btn" onClick={(e) => sendFormData()} disabled={isDisabled}>
        Отправить
      </Button>
    </div>
  );
};

export default AddComment;
