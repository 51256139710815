import React, { useState } from 'react';

export default function ItemCollapse({ title, children, collapsed }) {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  return (
    <li data-toggle="collapse">
      <span
        className="flex align-center cursor-pointer px-3 py-3"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {title}
        <div className="flex justify-center align-center">
          <span className={isCollapsed ? 'arrow size-2' : 'arrow size-2 up'}></span>
        </div>
      </span>
      <div className={[isCollapsed ? 'collapse px-3' : 'expand px-3 p-3', 'info'].join(' ')}>
        {children}
      </div>
    </li>
  );
}
