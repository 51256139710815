import React, { useEffect, useState } from 'react';

import './CategoriesVisibilitySetup.css';
import { getCategory } from '../../apps/green_button/api/greenApi';

export default function CategoriesVisibilitySetup({
  showModalCategoriesSetup = false,
  setShowModalCategoriesSetup = () => {}
}) {
  if (!showModalCategoriesSetup) return null;

  const [categoriesList, setCategoriesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const changeItem = (changedItem) => {
    const finalData = categoriesList.map((item) => {
      if (item.code === changedItem.code) {
        item.checked = !changedItem.checked;
      }
      return item;
    });

    localStorage.setItem('donotclear_visible_categories_data', JSON.stringify(finalData));
    setCategoriesList(finalData);
  };

  useEffect(() => {
    let visibleCategoriesData = localStorage.getItem('donotclear_visible_categories_data');
    if (!visibleCategoriesData) visibleCategoriesData = [];
    else visibleCategoriesData = JSON.parse(visibleCategoriesData);

    setIsLoading(true);
    getCategory()
      .then((resp) => {
        const finalData = resp.data.map((item) => {
          const itemSavedValue = visibleCategoriesData.find(
            (savedItem) => savedItem.code === item.code
          );
          if (!itemSavedValue) {
            item['checked'] = true;
          } else {
            item['checked'] = itemSavedValue['checked'];
          }

          return item;
        });

        localStorage.setItem('donotclear_visible_categories_data', JSON.stringify(finalData));
        setCategoriesList(finalData);
      })
      .catch((error) => {
        console.info('--getCategory Error', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="categories-visibility-setup-modal">
      <div
        className="close-modal"
        onClick={() => {
          setShowModalCategoriesSetup(false);
        }}
      >
        <span className="close"></span>
      </div>
      <div className="categories-visibility-setup-modal-body">
        <p className="modal-title mb-3">Выберите активные категории</p>
        {isLoading && <p>Загрузка...</p>}
        {!isLoading && categoriesList.length > 0 && (
          <>
            {categoriesList.map((item) => (
              <p key={item.code}>
                <label>
                  <input
                    onChange={() => changeItem(item)}
                    className="mr-3 bigger-checkbox"
                    type="checkbox"
                    checked={item.checked}
                  />
                  {item.name}
                </label>
              </p>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
