import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import LazyLoad from 'react-lazyload';
import { useNavigate } from 'react-router-dom';

import { formatDateWithTime } from '../../../lib/mixing';

import fskLogo from '../../../../../common/assets/images/logo_fsk.png';
import { ReactComponent as DotsIcon } from '../../../assets/images/icon/dots-icon.svg';
import { ReactComponent as CommentIcon } from '../../../assets/images/icon/comment_icon.svg';

import Likes from '../../Likes/Likes.jsx';

import '../AboutCompany/AboutCompany.scss';

const CommonLine = ({ newsData, newNewsData }) => {
  const navigate = useNavigate();
  return (
    <div className="company">
      {newsData &&
        newsData.map((news, index) => (
          <LazyLoad height={200} key={index} offset={-50}>
            <div className="company__post">
              <div
                className="company__post-top cursor-pointer"
                onClick={(e) => navigate(`/hr/news/${news.id}/`)}
              >
                <div className="company__post-top-left">
                  <img className="avatar" src={fskLogo} alt="avatar" />
                  <div className="company__post-user">
                    <h4 className="company__post-user-position">{news.signature.name}</h4>
                    <span className="company__post-user-status">
                      {formatDateWithTime(news.date)}
                    </span>
                  </div>
                </div>
                <DotsIcon />
              </div>
              <div className="company__post-info html-editor">
                <h2>{news.title}</h2>
                {news.external_id !== null && (
                  <img alt="news" src={news.capture} style={{ margin: '2em 0px 1em 0px' }} />
                )}
                {ReactHtmlParser(news.body)}
                {news.external_id !== null && (
                  <div>
                    <span>подробнее: </span>
                    <a
                      href={`https://portal.fsk.ru/news/${news.external_id}/`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {' '}
                      {`https://portal.fsk.ru/news/${news.external_id}/`}
                    </a>
                  </div>
                )}
              </div>
              <div className="user-actions">
                {news.is_like_active && (
                  <span>
                    {' '}
                    <Likes article={news} newArticleData={newNewsData} />{' '}
                  </span>
                )}
                {news.is_comment_active && (
                  <span onClick={() => navigate('/hr/news/' + news.id)} className="cursor-pointer">
                    <CommentIcon />
                    {news.comment_count}
                  </span>
                )}
              </div>
            </div>
          </LazyLoad>
        ))}
    </div>
  );
};

export default CommonLine;
