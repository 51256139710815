import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { formatSpacesThousands } from '../../lib/utils';

import ResstrProjectDetailItem from './ResstrProjectDetailItem.jsx';

const ResstrProjectItem = ({ ExpenseGroup }) => {
  const [collapseStatus, setCollapseStatus] = useState(true);
  const [Amount, setAmount] = useState(0);
  const [Prepaid, setPrepaid] = useState(0);
  const collapse = useSelector((state) => state.greenButton.collapse);

  useEffect(() => {
    setCollapseStatus(collapse);
  }, [collapse]);

  useEffect(() => {
    const AmountAll = ExpenseGroup.receiver_groups
      .map((receiver_group) => receiver_group.payments.map((payment) => parseFloat(payment.amount)))
      .map((itemItem) =>
        itemItem.reduce(
          (previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue)
        )
      )
      .reduce(
        (previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue)
      );
    setAmount(AmountAll);

    const PrepaidAll = ExpenseGroup.receiver_groups
      .map((receiver_group) =>
        receiver_group.payments.map((payment) => parseFloat(payment.prepaid))
      )
      .map((itemItem) =>
        itemItem.reduce(
          (previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue)
        )
      )
      .reduce(
        (previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue)
      );
    setPrepaid(PrepaidAll);
  }, [ExpenseGroup]);

  return (
    <>
      {ExpenseGroup && (
        <li className="mb-1">
          <ul
            onClick={() => setCollapseStatus(!collapseStatus)}
            className="project-item m-0 p-0 flex-nowrap align-items-center justify-content-between table-row position-relative overflow-hidden table-grid table_column_4"
          >
            <li className="flex-1 text-center cursor-pointer border_right position-relative justify-content-start pl-3 gide-span-2">
              <div className="default_link py-4 d-inline-block font-weight-700 align-items-center d-flex">
                {ExpenseGroup.name}
                <span
                  className={[collapseStatus ? 'down' : 'up', 'arrow', 'ml-1'].join(' ')}
                ></span>
              </div>
            </li>
            <li className="flex-1 text-center cursor-pointer border_right position-relative justify-content-end pr-3">
              <div className="default_link py-4 d-inline-block font-weight-700">
                {formatSpacesThousands(Amount)}
              </div>
            </li>
            <li className="flex-2 text-center cursor-pointer border_right position-relative justify-content-end pr-3">
              <div className="default_link py-4 d-inline-block font-weight-700">
                {formatSpacesThousands(Prepaid)}
              </div>
            </li>
          </ul>

          <div className={collapseStatus ? 'collapse' : 'collapse show'}>
            <ul className="table m-0 mt-2 p-0 pb-0 title table-grid table_column_5">
              <li className="noselect flex-1 text-center">Проект</li>
              <li className="noselect flex-1 text-center">Получатель</li>
              <li className="noselect flex-2 text-center">Статья</li>
              <li className="noselect flex-2 text-center">Сумма</li>
              <li className="noselect flex-2 text-center">в т.ч. Аванс</li>
            </ul>
            {ExpenseGroup.receiver_groups.map((receiver_group, key) => (
              <React.Fragment key={key}>
                {receiver_group.payments.map((payment, index) => (
                  <ResstrProjectDetailItem Payment={payment} key={index} />
                ))}
              </React.Fragment>
            ))}
          </div>
        </li>
      )}
    </>
  );
};
export default ResstrProjectItem;
