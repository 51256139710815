import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import { getCategory, getResstrPayments, postScenarioAction } from '../api/greenApi';
import { minTwoDigits } from '../lib/utils';
import { useRequiredFields } from './useRequiredFields';
import { logSentryMessage, sortArrayOfObjectsBy } from '../../../common/lib/utils';

const TasksButtonActions = ({ tasks, updateTask, pageUrlParams, handleChangeCheckboxField }) => {
  const dispatch = useDispatch();
  const [answer, setAnswer] = useState('');
  const [clickedButton, setClickedButton] = useState({});
  const [activeComment, setActiveComment] = useState(0);
  const [activeDateField, setActiveDateField] = useState(0);

  const [activeConfirmModal, setActiveConfirmModal] = useState(false);
  const [load, setLoad] = useState();
  const { code, api } = useParams();
  const [showSuccess] = useState(false);

  const navigate = useNavigate();

  const error = () => {
    const codesWhenCommentNotRequired = [3];
    /* Вместо comment надо сделать commentCode */
    if (clickedButton.comment) {
      if (codesWhenCommentNotRequired.includes(clickedButton.comment)) {
        return false;
      }

      if (!comment) {
        handleCommentError();
        return true;
      }
    }
    if (clickedButton.date) {
      if (!date) {
        handleDateError();
        return true;
      }
    }
    return false;
  };

  const validateHandle = () => {
    switch (clickedButton['code']) {
      case 'otvet':
        console.info('--otvet', 'обязателен комментарий');
        !error() && postAction();
        break;

      case 'pers_viza':
        console.info('--pers_viza', 'обязателен кому, комментарий по желанию');
        postWhoComment();
        break;

      case 'dop_zapros':
        console.info('--dop_zapros', 'обязателен кому, обязателен комментарий');
        !error() && postWhoComment();
        break;

      case 'delegir':
        console.info('--delegir', 'обязателен кому, коммент не используется');
        postWhoComment();
        break;

      case 'sog_comment':
        !error() && postAction();
        break;

      case 'otkl':
        console.info('--otkl', 'обязателен комментарий');
        !error() && postWithConfirm();
        break;

      case 'sog':
        console.info('--sog', 'комментарий не используется');
        postAction();
        break;

      default:
        !error() && postWithConfirm();
    }
  };

  /* переписать */
  const [{ comment, date }, { CommentFieldHtml, DateFieldHtml }, { setComment, setDate }] =
    useRequiredFields(clickedButton);

  useEffect(() => {
    if (tasks && tasks.length > 0) {
      dispatch({
        type: 'setcurrentTasks',
        currentTasks: tasks.map((task) => task.guid_in_1c)
      });
    }
  }, [tasks]);

  useEffect(() => {
    setActiveComment(0);
    setComment('');
    setActiveDateField(0);
    setDate('');

    if (Object.keys(clickedButton).length > 0) {
      if (clickedButton.comment === undefined) {
        setActiveComment(1);
        validateHandle();
      } else {
        if (clickedButton.comment || clickedButton.date) {
          clickedButton.comment ? setActiveComment(1) : setActiveComment(0);
          clickedButton.date ? setActiveDateField(1) : setActiveDateField(0);
        } else {
          setActiveComment(0);
          setActiveDateField(0);
          validateHandle();
        }
      }
    }
  }, [clickedButton]);

  const dateString = () => {
    if (date !== '') {
      let newdate = new Date(date);
      const [month, day, year] = [newdate.getMonth() + 1, newdate.getDate(), newdate.getFullYear()];
      const dateString = `${year}-${minTwoDigits(month)}-${minTwoDigits(day)}`;
      return dateString;
    }
    return '';
  };

  const checkAndAutonavigateAfterSuccess = (taskIndex) => {
    if (tasks.length > taskIndex + 1) {
      logSentryMessage('prevent redirect, there are more tasks on this page');
      return;
    }

    getCategory()
      .then((resp) => {
        const allCats = resp.data;

        const currentCategory = allCats.find(
          (item) => item.code === pageUrlParams.pageCode && item.api === pageUrlParams.pageApi
        );

        if (currentCategory && currentCategory.count > 0) {
          logSentryMessage(
            'navigate to the same category because there are more tasks in current category'
          );
          navigate(`/inbox/tasks/${pageUrlParams.pageCode}/${pageUrlParams.pageApi}`, {
            replace: true
          });
          return;
        }

        const catsSortByCount = sortArrayOfObjectsBy(allCats, 'count');
        const catsSortByCountExceptCurrent = catsSortByCount?.filter((item) => {
          if (item.code === pageUrlParams.pageCode) {
            return item.api !== pageUrlParams.pageApi;
          }
          return true;
        });

        if (catsSortByCountExceptCurrent.length) {
          const nextCat = catsSortByCountExceptCurrent[0];
          if (nextCat.count > 0) {
            const message =
              'Вы были автоматически перенаправлены в следующую категорию, в которой есть задачи';
            toast.success(message);
            logSentryMessage(message);
            navigate(`/inbox/tasks/${nextCat.code}/${nextCat.api}`, {
              replace: true
            });
            return;
          }
        }

        getResstrPayments({})
          .then((resp) => {
            const allPayments = resp.data;
            if (allPayments.length > 0) {
              toast.success(
                'Вы были перенаправлены в реестр платежей, так как все задачи в категориях завершены'
              );
              logSentryMessage(
                'navigate to reest because there are no cats with count and there is some tasks in reestr'
              );
              navigate('inbox/reestr/', {
                replace: true
              });
            } else {
              toast.success('Все задачи завершены');
              logSentryMessage(
                'navigate to inbox because there are no cats with count and no reestr items'
              );
              navigate('/inbox/', {
                replace: true
              });
            }
          })
          .catch((error) => {
            console.info('--getResstrPayments Error', error);
          });
      })
      .catch((err) => {
        const message = 'Не удалось получить категории';
        console.info('--getCategory Error', message, err);
      })
      .finally(() => setLoad(false));
  };

  const postAction = (checkboxFieldData = {}) => {
    setLoad(true);
    tasks.map((task, index) => {
      let checkbox_data = {};
      if (task.guid_in_1c === checkboxFieldData.guid_in_1c && checkboxFieldData.is_checkbox) {
        checkbox_data = checkboxFieldData;
      }

      return postScenarioAction(
        task.guid_in_1c,
        clickedButton['code'],
        comment,
        '',
        dateString(),
        checkbox_data
      )
        .then((response) => {
          return response?.data;
        })
        .then((success) => {
          setLoad(false);
          if (typeof success === 'string') {
            success = JSON.parse(success);
          }

          switch (success?.code) {
            case '200':
              console.info('--TasksButtonActions, Все ок');
              // setAnswer(success.description);
              // setShowSuccess(true);
              toast.success(success.description);
              checkAndAutonavigateAfterSuccess(index);
              break;
            case '400':
              console.info('--Входящие данные не корректны');
              // setAnswer(success.description);
              // dispatch({ type: "setWarningOpen" });
              toast(success.description, {
                icon: '⚠️'
              });
              break;
            case '423':
              console.info('--Администратор уже согласовал эту визу');
              // setAnswer(success.description);
              // setShowSuccess(true);
              toast.success(success.description);
              checkAndAutonavigateAfterSuccess(index);
              break;
            case '500':
              console.info('--Ошибка на стороне сервера 1С');
              // setAnswer(success.description);
              // dispatch({ type: "setWarningOpen" });
              toast(success.description, {
                icon: '⚠️'
              });
              break;
            case '666':
              console.info('--Ошибка на стороне сервера 1С');
              // setAnswer(success);
              // dispatch({ type: "setErrorOpen" });
              console.info(success);
              break;
            default:
              console.info('--TasksButtonActions не найдено');
              // setAnswer(success);
              // dispatch({ type: "setErrorOpen" });
              navigate(`/inbox/tasks/${code}/${api}`, { replace: true });
              console.info(success);
          }

          setTimeout(() => {
            setLoad(false);
            updateTask(true);
          }, 400);
        })
        .catch((error) => {
          console.info(error);

          if (error.response) {
            setLoad(false);
            setAnswer('Произошла ошибка сервера MSBOT');
            dispatch({ type: 'setErrorOpen' });
          }
        });
    });
  };

  const postWithConfirm = () => {
    setActiveConfirmModal(true);
  };

  const postWhoComment = () => {
    dispatch({ type: 'setVizacomment', payload: comment });
    dispatch({ type: 'setVizadate', payload: dateString() });
    navigate(`/inbox/tasks/${code}/${api}/user_list/${clickedButton['code']}`, { replace: true });
  };

  const handleCommentError = () => {
    toast('Необходимо заполнить комментарий', { icon: '⚠️' });
  };

  const handleDateError = () => {
    toast('Необходимо заполнить дату', { icon: '⚠️' });
  };

  return {
    onClickButton: (item) => {
      return setClickedButton(item);
    },
    clickedButton: clickedButton,
    commentField: {
      status: activeComment || activeDateField,
      html:
        activeComment || activeDateField ? (
          <>
            {clickedButton.checkbox === 1 ? (
              <div className="px-4">
                <input
                  type="checkbox"
                  id="checkbox-field"
                  className="mr-2"
                  onChange={handleChangeCheckboxField}
                ></input>
                <label htmlFor="checkbox-field">{clickedButton.checkbox_name}</label>
              </div>
            ) : null}
            <div className="description-editor-block px-4">
              <hr></hr>
              {activeComment ? CommentFieldHtml : ''}
              {activeDateField ? DateFieldHtml : ''}
              <button className="btn approve mx-0 my-3" onClick={() => validateHandle()}>
                Отправить
              </button>
            </div>
          </>
        ) : (
          ''
        )
    },
    showSuccess: showSuccess,
    loadingData: load,
    answerData: answer,
    ConfirmModal: {
      activeConfirmModal: activeConfirmModal,
      setActiveConfirmModal: (data) => setActiveConfirmModal(data)
    },
    postAction: (checkboxFieldData) => postAction(checkboxFieldData)
  };
};

export default TasksButtonActions;
