import React, { useState, useRef } from 'react';

import { minTwoDigits } from '../lib/utils';

import { ReactComponent as CalendarIcon } from '../assets/images/icons/calendar-icon.svg';

import DateTimePicker from '../components/DateTimePicker/DateTimePicker';

import './useRequiredFields.css';

const useRequiredFields = (clickedButton) => {
  const [comment, setComment] = useState('');
  const [date, setDate] = useState('');

  const commentInputReference = useRef(null);

  const getDate = (dateData) => {
    let newdate = new Date(dateData);
    const [month, day, year] = [newdate.getMonth() + 1, newdate.getDate(), newdate.getFullYear()];
    const dateString = `${minTwoDigits(day)}.${minTwoDigits(month)}.${year}`;
    return dateString;
  };

  const commentIds = [1, 3];
  const defaultComment = clickedButton.default_comment;

  const CommentFieldHtml = () => {
    return (
      <div className="position-relative">
        {!comment && defaultComment && commentIds.includes(clickedButton.comment) ? (
          <div
            className="default-comment"
            onClick={() => {
              setComment(defaultComment);
              commentInputReference.current.focus();
            }}
          >
            {defaultComment}
          </div>
        ) : null}

        <textarea
          ref={commentInputReference}
          className="input form-control"
          id="FormControlTextarea"
          rows="3"
          placeholder="Введите текст..."
          value={comment}
          onInput={(event) => {
            setComment(event.target.value);
          }}
        />
      </div>
    );
  };

  const DateFieldHtml = () => {
    return (
      <div className="d-flex px-4 mt-3 mb-4 date_select align-items-center">
        <span className="pr-3 title">Дата</span>
        {
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label className="d-flex mb-0 ml-2">
            <span className="icon calendar">
              <CalendarIcon />
            </span>

            <DateTimePicker
              selected={date}
              onChange={(date) => setDate(new Date(date))}
              dateFormat="dd-MM-yyyy"
              placeholderText={'--/--/----'}
              locale="ru"
              minDate={new Date('2022/01/01')}
              maxDate={new Date('2025/01/01')}
            />
          </label>
        }
      </div>
    );
  };

  return [
    {
      comment,
      date
    },
    {
      CommentFieldHtml: CommentFieldHtml(),
      DateFieldHtml: DateFieldHtml()
    },
    {
      setComment,
      setDate
    }
  ];
};
export { useRequiredFields };
