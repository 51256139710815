import React from 'react';

import MeetingsWrapper from '../../apps/bookings_app/pages/MeetingsWrapper';

const BookingsRoutes = [
  {
    path: '/bookings',
    element: <MeetingsWrapper />
  }
];
export default BookingsRoutes;
