import React from 'react';

const ScheduleTypes = ({ closeTypeModal }) => {
  return (
    <React.Fragment>
      <div className="position-absolute schedules-types-modal">
        <div className="modal-body pt-4">
          <button
            type="button"
            className="btn-close position-absolute"
            aria-label="Close"
            onClick={(e) => closeTypeModal()}
          ></button>
          <div className="d-flex align-items-center justify-content-start mb-2">
            <div className="status free"></div>
            <p className="m-0">Свободно</p>
          </div>

          <div className="d-flex align-items-center justify-content-start mb-2">
            <div className="status booked"></div>
            <p className="m-0">Занято</p>
          </div>

          <div className="d-flex align-items-center justify-content-start mb-2">
            <div className="status booked_by_you"></div>
            <p className="m-0">Занято Вами</p>
          </div>

          <div className="d-flex align-items-center justify-content-start">
            <div className="status you_invited"></div>
            <p className="m-0"> Вы приглашены</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ScheduleTypes;
