import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch } from 'react-redux';

import { getNewsByID } from '../../api/hrApi';

import { formatDateWithTime } from '../../lib/mixing';

import fskLogo from '../../../../common/assets/images/logo_fsk.png';

import { ReactComponent as DotsIcon } from '../../assets/images/icon/dots-icon.svg';
import { ReactComponent as CommentIcon } from '../../assets/images/icon/comment_icon.svg';

import Navigation from '../../components/Navigation/Navigation.jsx';
import Likes from '../../components/Likes/Likes.jsx';
import Comments from '../../components/Comments';

const NewsItem = () => {
  const dispatch = useDispatch();
  const { newsId } = useParams();
  const [news, setNews] = useState();

  useEffect(() => {
    dispatch({ type: 'GlOBAL_LOADER', show: true });
    getNewsByID(newsId)
      .then(({ data }) => {
        setNews(data);
      })
      .catch((error) => console.info(error))
      .finally(() => dispatch({ type: 'GlOBAL_LOADER', show: false }));
  }, []);

  return (
    <div className="static-page">
      <Navigation blockName="allNews" title={'Лента'} />

      <div className="static-page__block main">
        <div className="static-page__post-info">
          {news && (
            <div className="company__post">
              <div className="company__post-top">
                <div className="company__post-top-left">
                  <img className="avatar" src={fskLogo} alt="avatar" />
                  <div className="company__post-user">
                    <h4 className="company__post-user-position">{news.signature.name}</h4>
                    <span className="company__post-user-status">
                      {formatDateWithTime(news.date)}
                    </span>
                  </div>
                </div>
                <DotsIcon />
              </div>
              <div className="company__post-info html-editor">
                <h2>{news.title}</h2>
                {news.external_id !== null && (
                  <img alt="news" src={news.capture} style={{ margin: '2em 0px 1em 0px' }} />
                )}
                {ReactHtmlParser(news.body)}
                {news.external_id !== null && (
                  <div>
                    <span>подробнее: </span>
                    <a
                      href={`https://portal.fsk.ru/news/${news.external_id}/`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {' '}
                      {`https://portal.fsk.ru/news/${news.external_id}/`}
                    </a>
                  </div>
                )}
              </div>
              <div className="user-actions">
                {news.is_like_active && (
                  <span>
                    {' '}
                    <Likes article={news} newArticleData={(data) => setNews(data)} />{' '}
                  </span>
                )}
                {news.is_comment_active && (
                  <span className="cursor-default">
                    <CommentIcon className="cursor-default" />
                    {news.comment_count}
                  </span>
                )}
              </div>
              {news.is_comment_active && (
                <Comments
                  Article={news}
                  setCommentsCount={(data) => setNews({ ...news, comment_count: data })}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default NewsItem;
