import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

const ConfirmActionModal = ({ time = 2, confirmAction }) => {
  const Complete = (status) => {
    confirmAction(status);
  };

  return (
    <>
      <div className="position-fixed confirmAction-modal">
        <div className="d-flex flex-column justify-content-center">
          <CountdownCircleTimer
            isPlaying
            duration={time}
            colors={['#E84E0E']}
            onComplete={() => Complete(true)}
          >
            {({ remainingTime }) => remainingTime}
          </CountdownCircleTimer>

          <button className="btn approve mt-5 px-3" onClick={() => Complete(false)}>
            Отменить
          </button>
        </div>
      </div>
    </>
  );
};
export default ConfirmActionModal;
