import store from '../../store/redux';

import { clearSiteData } from './auth';
import { hardLogout } from './auth';
import { logSentryMessage } from './utils';

export function setLocalStorageUserData(ssoUserKey, userEmail, userFio, hrUserKey = null) {
  localStorage.setItem('ssoUserKey', ssoUserKey);
  localStorage.setItem('userEmail', userEmail);
  localStorage.setItem('userFio', userFio);
  if (hrUserKey) localStorage.setItem('hrUserKey', hrUserKey);
}

export function setLocalStorageRegularUserData(greenUserKey, userEmail, userFio, hrUserKey = null) {
  localStorage.setItem('greenUserKey', greenUserKey);
  localStorage.setItem('userEmail', userEmail);
  localStorage.setItem('userFio', userFio);
  if (hrUserKey) localStorage.setItem('hrUserKey', hrUserKey);
}

export function getLocalStorageUserData() {
  const ssoUserKey = localStorage.getItem('ssoUserKey');
  const userEmail = localStorage.getItem('userEmail');
  const userFio = localStorage.getItem('userFio');
  const hrUserKey = localStorage.getItem('hrUserKey');
  const greenUserKey = localStorage.getItem('greenUserKey');
  return { userEmail, ssoUserKey, userFio, hrUserKey, greenUserKey };
}

export function clearUserData() {
  clearLocalStorageUserData();
  clearStoreUserData();
}

export function clearLocalStorageUserData() {
  localStorage.removeItem('ssoUserKey');
  localStorage.removeItem('sso_data');
  localStorage.removeItem('userEmail');
  localStorage.removeItem('userFio');
  localStorage.removeItem('hrUserKey');
  localStorage.removeItem('greenUserKey');
  localStorage.removeItem('knopka_user_email');
  clearNotImportantLocalStorageUserData();
}

export function clearNotImportantLocalStorageUserData() {
  localStorage.removeItem('last_renewed_pushsub_date');
  localStorage.removeItem('last_rejected_push_grant');
  localStorage.removeItem('is_block_push_functionality');
}

export function clearStoreUserData() {
  store.dispatch({ type: 'setUserKey', payload: '' });
  store.dispatch({ type: 'setuserEmail', payload: '' });
  store.dispatch({ type: 'setUserFio', payload: '' });
  store.dispatch({ type: 'setHrUserKey', payload: '' });
}

export function setStoreUserData(userKey, userEmail, userFio, hrUserKey) {
  store.dispatch({ type: 'setUserKey', payload: userKey });
  store.dispatch({ type: 'setuserEmail', payload: userEmail });
  store.dispatch({ type: 'setUserFio', payload: userFio });
  store.dispatch({ type: 'setHrUserKey', payload: hrUserKey });
}

export function getStoreUserData() {
  const user = store.getState().user;
  const userKey = user.userKey;
  const userEmail = user.userEmail;
  const userFio = user.userFio;
  const hrUserKey = user.hrUserKey;
  return { userKey, userEmail, userFio, hrUserKey };
}

export function setUserSsoData(ssoUserKey, userEmail, userFio, hrUserKey = null) {
  setLocalStorageUserData(ssoUserKey, userEmail, userFio, hrUserKey);
}

export function setUserRegularData(greenUserKey, userEmail, userFio, hrUserKey = null) {
  setLocalStorageRegularUserData(greenUserKey, userEmail, userFio, hrUserKey);
}

export function getUserLocalData() {
  //let data = getStoreUserData();
  let data = getLocalStorageUserData();

  //if (!data.ssoUserKey || !data.userEmail || !data.hrUserKey) {
  //data = getLocalStorageUserData();
  // if (!data.userKey || !data.userEmail || !data.hrUserKey) {
  //   return null;
  // }
  //setStoreUserData(data.userKey, data.userEmail, data.userFio, data.hrUserKey);
  //}

  return data;
}

export const clearOnceEverything = () => {
  if (localStorage.getItem('donotclear_everything_once_cleared_4') !== 'true') {
    clearSiteData();
    localStorage.setItem('donotclear_everything_once_cleared_4', 'true');
    logSentryMessage('Logout because clearOnceEverything ');
    hardLogout();
  }
};
