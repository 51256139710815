import React from 'react';

import { appVersion } from '../../config';
import { greenApiFullUrl } from '../api/commonApi';
import isPwa from '../lib/detectPwa';

import './AppVersion.css';

function getShortUrl() {
  let domain = new URL(greenApiFullUrl);
  return domain.hostname.substring(0, 17) + '..';
}

export default function AppVersion() {
  return (
    <div className="app-info">
      <span>{`${appVersion} ${isPwa() ? 'pwa' : 'web'}`}</span>
      <br />
      <span className="app-info-domain">{getShortUrl()}</span>
    </div>
  );
}
