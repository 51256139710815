import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import { getStaticPage } from '../../api/hrApi';

import { ReactComponent as CommentIcon } from '../../assets/images/icon/comment_icon.svg';

import Navigation from '../../components/Navigation/Navigation.jsx';
import Likes from '../../components/Likes/Likes.jsx';
import Comments from '../../components/Comments';

const StaticPages = () => {
  const dispatch = useDispatch();
  const { pageID } = useParams();
  const [page, setPage] = useState();

  useEffect(() => {
    dispatch({ type: 'GlOBAL_LOADER', show: true });
    getStaticPage(pageID)
      .then((resp) => {
        setPage(resp?.data);
      })
      .catch((error) => console.info(error))
      .finally(() => dispatch({ type: 'GlOBAL_LOADER', show: false }));
  }, []);

  return (
    <div className="static-page">
      <Navigation blockName="static-page" title={page && page.title} />

      <div className="static-page__block main">
        {page && (
          <div className="static-page__post">
            <div className="static-page__post-info">
              <div className="html-editor">{ReactHtmlParser(page.body)}</div>
              <div className="user-actions">
                {page.is_like_active && (
                  <span>
                    {' '}
                    <Likes article={page} newArticleData={(data) => setPage(data)} />{' '}
                  </span>
                )}
                {page.is_comment_active && (
                  <span className="cursor-default">
                    <CommentIcon className="cursor-default" />
                    {page.comment_count}
                  </span>
                )}
              </div>
              {page.is_comment_active && (
                <Comments
                  Article={page}
                  setCommentsCount={(data) => setPage({ ...page, comment_count: data })}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default StaticPages;
