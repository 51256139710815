import * as Sentry from '@sentry/browser';
import { appVersion } from '../../config';

export function logSentryMessage(message) {
  console.info('logSentryMessage', message);
  let user = localStorage.getItem('donotclear_debug_username');
  if (!user) user = localStorage.getItem('userEmail');
  Sentry.captureMessage(`${message} user:${user} v:${appVersion}`);
}

export function logSentryError(message) {
  console.info('logSentryError', message);
  let user = localStorage.getItem('donotclear_debug_username');
  if (!user) user = localStorage.getItem('userEmail');
  Sentry.captureException(`${message} user:${user} v:${appVersion}`);
}

export function sortArrayOfObjectsBy(array, key) {
  return array.sort((a, b) => {
    if (a[key] < b[key]) {
      return 1;
    }
    if (a[key] > b[key]) {
      return -1;
    }
    return 0;
  });
}
