import axios from 'axios';

import { getUserLocalData } from '../lib/handleAuthData';

const GREEN_API_FULL_URL = `${process.env.REACT_APP_DJANGO_API}/api/v1`;
export const greenApiFullUrl = GREEN_API_FULL_URL;

const HR_API_FULL_URL = `${process.env.REACT_APP_HR_DJANGO_API}/api`;
export const hrApiFullUrl = HR_API_FULL_URL;

const KEYCLOAK_SSO_AUTH_DISABLED = process.env.REACT_APP_KEYCLOAK_SSO_AUTH_DISABLED === 'true';
export const keycloakSsoAuthDisabled = KEYCLOAK_SSO_AUTH_DISABLED;
export const keycloakSsoAuthEnabled = !keycloakSsoAuthDisabled;

const PUSH_LOG_SECRETKEY = process.env.REACT_APP_PUSH_LOG_SECRETKEY;
export const pushLogSecretKey = PUSH_LOG_SECRETKEY;

export const clearSessionIdApi = async () => {
  return await axios.get(`${GREEN_API_FULL_URL}/auth/clean_sessionid/`);
};

export const sendRequestClearCookiesToRoot = async () => {
  fetch('/', {
    headers: {
      'Clear-Site-Data': '"cache", "cookies", "storage", "executionContexts", "history", "all"'
    }
  }).then(() => {
    console.info('site data has been cleared');
  });
};

export const getAppVersion = async () => {
  return await axios.post(`${GREEN_API_FULL_URL}/system-info/`);
};

export const sendLog = async (data) => {
  const { push_data, device_info } = data;

  return await axios.post(
    `${GREEN_API_FULL_URL}/push-log/`,
    {
      browser_id: localStorage.getItem('donotclear_random_hash_for_fingerprint'),
      device_info: JSON.stringify(device_info),
      message: 'Получен пуш',
      text_notification: JSON.stringify(push_data)
    },
    {
      headers: {
        SECRETKEY: 'jHn1y3GVBzKG9zuSU8UUo5trwRqtEuT5'
      }
    }
  );
};

const greenButtonAuth = async (userName, userPassword) => {
  return await axios.post(`${process.env.REACT_APP_DJANGO_API}/api/v1/auth/login/`, {
    username: userName,
    password: userPassword
  });
};

const hrAuth = async (userName, userPassword) => {
  return await axios.post(`${HR_API_FULL_URL}/auth/login/`, {
    username: userName,
    password: userPassword
  });
};

export const loginAuthRegular = async (userName, userPassword) => {
  const TryGreenButton = async () => {
    return new Promise((resolve) => {
      greenButtonAuth(userName, userPassword)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };

  const TryHr = async () => {
    return new Promise((resolve, reject) => {
      hrAuth(userName, userPassword)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  };

  let authData = {
    data: {
      key: '',
      email: '',
      fio: '',
      hrKey: ''
    }
  };

  const greeButtonData = await TryGreenButton();
  const hrData = await TryHr();

  return new Promise((resolve, reject) => {
    if (hrData.status !== 200 && greeButtonData.status !== 200) {
      reject(greeButtonData);
    }
    if (hrData?.data?.key) {
      authData.data.hrKey = hrData.data.key;
      authData.data.email = hrData.data.email;
    }
    if (greeButtonData?.data?.key) {
      authData.data = {
        ...authData.data,
        ...greeButtonData.data
      };
    }
    resolve(authData);
  });
};

export const logoutAuth = async () => {
  const { ssoUserKey, hrUserKey } = getUserLocalData() || {};
  if (hrUserKey && ssoUserKey) {
    const greenButtonLogout = await axios.post(`${GREEN_API_FULL_URL}/auth/logout/`);
    const hrLogout = await axios.post(`${HR_API_FULL_URL}/auth/logout/`);

    return new Promise((resolve) => {
      if (hrLogout.status === 200 && greenButtonLogout.status === 200) {
        resolve(true);
      }
    });
  }
  if (hrUserKey && !ssoUserKey) {
    return await axios.post(`${HR_API_FULL_URL}/auth/logout/`);
  }
  if (!hrUserKey && ssoUserKey) {
    return await axios.post(`${GREEN_API_FULL_URL}/auth/logout/`);
  }
};
