import React from 'react';

import fskLogo from '../../../../common/assets/images/logo_fsk.png';

import './NewsNotification.scss';

const NewsNotification = ({ birthDaysData }) => {
  return (
    <>
      {birthDaysData.map((birthday, index) => (
        <div className="user-notif" key={index}>
          <img
            className="avatar"
            src={birthday.author.avatar === null ? fskLogo : birthday.author.avatar}
            alt="avatar"
          />
          <div className="user-notif__wrapper">
            <h5 className="user-notif__name">{birthday.author.name}</h5>
            <span className="user-notif__status">{birthday.body}</span>
          </div>
        </div>
      ))}
    </>
  );
};

export default NewsNotification;
