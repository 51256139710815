import React from 'react';
import parse from 'html-react-parser';

import { numberWithSpaces } from '../../lib/utils';

import ItemCollapse from '../UI/ItemCollapse.jsx';

import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';

const TaskFlexApi = ({ task }) => {
  const GetValuesbyType = (item) => {
    switch (item.type) {
      case 'text':
        return (
          <li key={Math.random()}>
            <span className="font-weight-500">{item.name && `${item.name} : `} </span> {item.value}
          </li>
        );
      case 'money':
        return (
          <li key={Math.random()}>
            <span className="font-weight-500">{item.name && `${item.name} : `} </span>{' '}
            {numberWithSpaces(item.value) + ' руб.'}
          </li>
        );
      case 'html':
        return (
          <li key={Math.random()}>
            <ErrorBoundary fallback={<p>Не удалось отобразить элемент</p>}>
              <span className="font-weight-500">{item.name && `${item.name} : `}</span>{' '}
              {parse(
                item.value.search('/table/') === -1
                  ? `<div class="auto-overflow">${item.value}</div>`
                  : item.value
              )}
            </ErrorBoundary>
          </li>
        );
      case 'json':
        return (
          Object.keys(item.value).length > 0 &&
          item.value.map((jsonItem, index) => {
            return GetValuesbyType(jsonItem, index);
          })
        );
      case 'group_h_sep':
        return (
          <ul className="list-unstyled d-flex flex-wrap with_seperator" key={Math.random() * 1000}>
            {item.value.length > 0 &&
              item.value.map((hItem, index) => {
                return GetValuesbyType(hItem, index);
              })}
          </ul>
        );
      case 'group_h':
        return (
          <ul
            className="list-unstyled d-flex flex-wrap with_comma_seperator"
            key={Math.random() * 1000}
          >
            {item.value.length > 0 &&
              item.value.map((hItem, index) => {
                return GetValuesbyType(hItem, index);
              })}
          </ul>
        );
      case 'date':
        return (
          <li key={Math.random()}>
            <span className="font-weight-500">{item.name && `${item.name} : `} </span>
            {item.value}
          </li>
        );
      default:
        console.info(`--Ошибка 1200, нет обработчика для случая ${item.type}`);
    }
  };

  return (
    <>
      {task?.pending_task ? (
        <p className="processing-badge d-inline left-1">
          в обработке
          <span className="loader-dot">.</span>
          <span className="loader-dot">.</span>
          <span className="loader-dot">.</span>
        </p>
      ) : null}

      <ul className="main-list font-weight-500 collapseble p-0">
        {task.data_json &&
          Object.keys(task.data_json).length !== 0 &&
          task.data_json.map((collapsData, index) => (
            <ItemCollapse collapsed={collapsData.collapse} title={collapsData.name} key={index}>
              <ul className="list-unstyled" key={Math.random() * 1000}>
                {Object.keys(collapsData).length > 0 &&
                  collapsData.value.map((item, index) => {
                    return GetValuesbyType(item, index);
                  })}
              </ul>
            </ItemCollapse>
          ))}

        {task.files ? (
          <ItemCollapse collapsed={true} title={'Документы'}>
            {task.files
              ? task.files.map((item, id) => (
                  <li key={`${id}_file`} className="flex align-center">
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                      <div className="file-icon text-uppercase justify-content-center">
                        {item.ext === '' ? '!' : item.ext}
                      </div>
                    </a>
                    <span className="color-orrange">
                      <a href={item.url} target="_blank" rel="noopener noreferrer">
                        {item.filename}
                      </a>
                    </span>
                  </li>
                ))
              : ''}
          </ItemCollapse>
        ) : (
          ''
        )}
      </ul>
    </>
  );
};
export default TaskFlexApi;
