import React from 'react';

const ReestrPaymentsModal = ({ setModalShow, ShowMode }) => {
  const setShowModal = (data) => {
    setModalShow(data);
  };

  const setShowMode = (action) => {
    ShowMode(action);
  };
  return (
    <div className="modal fade show" id="LoadingModal" role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog m-0">
        <div className="modal-content">
          <div className="modal-body flex align-center justify-end flex-column">
            <ul className="m-0 p-0 modal-menu">
              <li className="list">
                <span onClick={() => setShowMode(false)} className="cursor-pointer modal-actions">
                  <i className="eye_icon mr-3"></i>Показать согласованные
                </span>
              </li>
              <li className="list">
                <span onClick={() => setShowMode(true)} className="cursor-pointer modal-actions">
                  <i className="calendar_icon mr-3"></i>Показать календарь
                </span>
              </li>
            </ul>
            <span
              onClick={() => setShowModal(false)}
              className="cancel btn mt-4 flex justify-center align-center w-100"
            >
              Отмена
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReestrPaymentsModal;
