import React from 'react';
import { Outlet } from 'react-router-dom';

import { useTheme } from '../../../common/hooks/useTheme';

const BookingsLayout = () => {
  const { theme } = useTheme();
  return (
    <div className="main-body bg-design">
      <div className={[theme === 'light' ? 'white-theme' : '', 'meetings_booking'].join(' ')}>
        <Outlet />
      </div>
    </div>
  );
};
export default BookingsLayout;
