import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getTemplateCategory } from '../../api/hrApi';

import { ReactComponent as ArrowRight } from '../../assets/images/icon/ArrowRight.svg';
import Navigation from '../../components/Navigation/Navigation';

const Templates = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { categoryID } = useParams();
  const [categoryData, setCategoryData] = useState();

  useEffect(() => {
    dispatch({ type: 'GlOBAL_LOADER', show: true });
    getTemplateCategory(categoryID)
      .then((resp) => {
        setCategoryData(resp.data);
      })
      .catch((error) => console.info(error))
      .finally(() => dispatch({ type: 'GlOBAL_LOADER', show: false }));
  }, []);

  return (
    <div className="references">
      <Navigation blockName="referenceTemplates" title="Справки" />

      <div className="references__block main">
        <ul>
          <li className="main__info-item">
            <div className="main__info-item-wrapper first">
              <h3>{categoryData && categoryData.name}</h3>
            </div>
          </li>

          {categoryData &&
            categoryData.templates.map((template) => (
              <li
                className="main__info-item"
                key={template.id}
                onClick={() => navigate(`/hr/references/${categoryData.id}/${template.id}/`)}
              >
                <div className="main__info-item-wrapper">
                  <h4>{template.name}</h4>
                  <ArrowRight />
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default Templates;
