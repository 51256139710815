import React from 'react';

import fskLogo from '../../../../common/assets/images/logo_fsk.png';
import { formatDateWithTime } from '../../lib/mixing';

const CommentsList = ({ comments }) => {
  return (
    <ul className="comments-list">
      {comments &&
        comments.map((comment, index) => (
          <li className="comments-list-item" key={'comment_' + index}>
            <div className="comments-details">
              <div className="comments-user">
                <div className="comments-user-avatar">
                  <img
                    className="avatar"
                    src={
                      comment.author.avatar === undefined || comment.author.avatar === null
                        ? fskLogo
                        : comment.author.avatar
                    }
                    alt="avatar"
                  />
                </div>
                <div>
                  <div className="comments-user-name">{comment.author.name}</div>
                  <div className="comments-date-time">{formatDateWithTime(comment.created_at)}</div>
                </div>
              </div>
              <div className="comments-details-comment">{comment.message}</div>
            </div>
          </li>
        ))}
    </ul>
  );
};

export default CommentsList;
