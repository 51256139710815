import React, { useState, useEffect } from 'react';

import { getFirstLetterFromString, minTwoDigits } from '../../../../lib/mixing';
import InlineLoader from '../../../UI/InlineLoader';

const ScheduleStatus = ({ scheduleData, Type, Isleft, IsTop }) => {
  const [Loading, SetLoading] = useState(true);

  const getDuration = (scheduleStart, scheduleEnd) => {
    const date = new Date(scheduleStart);
    const dateEnd = new Date(scheduleEnd);
    const [hours, minutes, hoursEnd, minutesEnd] = [
      minTwoDigits(date.getHours()),
      minTwoDigits(date.getMinutes()),
      minTwoDigits(dateEnd.getHours()),
      minTwoDigits(dateEnd.getMinutes())
    ];
    return `${hours}:${minutes} - ${hoursEnd}:${minutesEnd}`;
  };

  useEffect(() => {
    if (Loading) {
      setTimeout(() => {
        SetLoading(false);
      }, 300);
    }
  }, []);
  return (
    <React.Fragment>
      <div
        className={[
          Isleft ? 'left' : 'right',
          IsTop ? 'top' : 'bottom',
          ' position-absolute schedules-statuses-modal'
        ].join(' ')}
      >
        {Loading ? (
          <InlineLoader />
        ) : (
          <React.Fragment>
            <div className="modal-header d-flex justify-content-start flex-column align-items-start">
              <div className="h4">{scheduleData.subject}</div>
              <div className="">
                <span>{getDuration(scheduleData.start, scheduleData.end)}</span>
                <span className="status">{Type === 'you_invited' ? 'Вы приглашены' : ''}</span>
              </div>
            </div>
            <div className="modal-body pt-1">
              <div className="group-block block-with-border-bottom pb-2 mb-3">
                <div className="h5 pb-1">Организатор </div>
                <div className="user-item d-flex align-items-center justify-content-between mb-2">
                  <div className="user-image">
                    <div className="text">
                      <div className="text-label text-uppercase">
                        {scheduleData.organizer
                          ? getFirstLetterFromString(scheduleData.organizer.name, 2)
                          : '-'}
                      </div>
                    </div>
                  </div>
                  <div className="user-info">
                    <div>{scheduleData.organizer ? scheduleData.organizer.name : '-'}</div>
                    <div className="light-color mt-1">
                      {scheduleData.organizer ? scheduleData.organizer.position : ''}
                    </div>
                  </div>
                </div>
              </div>

              <div className="group-block members">
                <div className="h5 pb-1">Участники </div>
                {scheduleData.required_attendees.map((user, index) => (
                  <div
                    className="user-item d-flex align-items-center justify-content-between mb-2"
                    key={index}
                  >
                    <div className="user-image">
                      <div className="text">
                        <div className="text-label text-uppercase">
                          {getFirstLetterFromString(user.name, 2)}
                        </div>
                      </div>
                    </div>
                    <div className="user-info">
                      <div>{user.name}</div>
                      <div className="light-color mt-1">{user.position}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};
export default ScheduleStatus;
