import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Loader from '../components/UI/loader';
import ScheduleModal from '../components/UI/ScheduleModal';
import GlobalBookings from '../components/GlobalBookings';

export default function MeetingRoomsBookings() {
  const [scheduleModalShow, setscheduleModalShow] = useState(false);

  const scheduleModalState = useSelector((state) => state.bookings.scheduleModalData.show);
  useEffect(() => {
    setscheduleModalShow(scheduleModalState);
  }, [scheduleModalState]);

  return (
    <>
      <Loader />
      {scheduleModalShow && <ScheduleModal />}

      <div className="container py-4">
        <GlobalBookings />
      </div>
    </>
  );
}
