import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getStaticPagesCategories } from '../../api/hrApi';

import { ReactComponent as ArrowRight } from '../../assets/images/icon/ArrowRight.svg';
import Navigation from '../../components/Navigation/Navigation.jsx';

const StaticPages = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [newsType, setNewType] = useState('company');
  const [index, setIndex] = useState(1);
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const [page, setPage] = useState();

  useEffect(() => {
    if (location.hash) {
      setNewType(location.hash.replace('#', ''));
    }

    dispatch({ type: 'GlOBAL_LOADER', show: true });
    getStaticPagesCategories()
      .then((resp) => {
        const pageCurrent = resp.data.filter((page) => page.id === parseInt(categoryId)).pop();
        const pages = pageCurrent.pages.sort((a, b) => {
          return a.index > b.index ? 1 : b.index > a.index ? -1 : 0;
        });
        const sortedPageData = { ...pageCurrent, pages };
        setPage(sortedPageData);
      })
      .catch((error) => console.info(error))
      .finally(() => dispatch({ type: 'GlOBAL_LOADER', show: false }));
  }, [location, location.hash]);

  const showNews = (newsType, idx) => {
    navigate(location.pathname + '#' + newsType);
    setIndex(idx);
  };

  return (
    <div className="static-pages">
      <Navigation blockName="static-pages" title={page && page.title} />
      <div className="static-pages__block main">
        <ul className="main__info-list">
          {page &&
            page.pages.map((item) => (
              <li
                className="main__info-item"
                key={item.id}
                onClick={() => navigate(location.pathname + '/page/' + item.id)}
              >
                <div className="main__info-item-wrapper">
                  <h3>{item.title}</h3>
                  <ArrowRight />
                </div>
              </li>
            ))}

          {page && page.pages.length === 0 && (
            <li>
              <div className="main__info-item-wrapper">
                <p className="warning">Нет записей</p>
              </div>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default StaticPages;
