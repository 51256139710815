import axios from 'axios';

import { getUserLocalData } from '../lib/handleAuthData';
import { knopkaUserEmailKey, axiosTimeoutMilliseconds } from '../../config';
import { keycloakSsoAuthEnabled } from './commonApi';

export const axiosInstance = async (data, app) => {
  try {
    const instance = axios.create({
      timeout: axiosTimeoutMilliseconds
    });

    const tokenKeyword = keycloakSsoAuthEnabled ? 'Bearer' : 'Token';

    instance.interceptors.request.use(function (config) {
      const { ssoUserKey, userEmail, hrUserKey, greenUserKey } = getUserLocalData();
      let key;
      if (app === 'hr') key = hrUserKey;
      if (app === 'green') key = greenUserKey;
      if (keycloakSsoAuthEnabled) key = ssoUserKey;
      config.headers.Authorization = `${tokenKeyword} ${key}`;
      config.headers.email = localStorage.getItem(knopkaUserEmailKey) || userEmail;
      return config;
    });
    return instance({ ...data });
  } catch (error) {
    return error;
  }
};
