import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import { Link, useNavigate } from 'react-router-dom';
import 'react-calendar/dist/Calendar.css';

import { getResstrPayments } from '../api/greenApi';

import { errorMessages } from '../../../messages';
import { formatDate } from '../lib/utils';

import { ReactComponent as LeftArrowIcon } from '../assets/images/icons/leftArrowIcon.svg';
import { ReactComponent as DottedMenuIcon2 } from '../assets/images/icons/dottedMenuIcon2.svg';

import ReestrPaymentsModal from '../components/resstr/ReestrPaymentsModal.jsx';
import Loading from '../../../common/components/UI/Loading';

import '../assets/css/reestr.css';

const ReestrPayments = () => {
  const [load, setLoad] = useState(true);
  const [ShowModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const [calendarDate, setCalendarDate] = useState(new Date());
  const [isCalendarMode, setCalendarMode] = useState(false);

  const [ordering, SetOrdering] = useState({ ordering: 'date' });
  const [paymentsData, SetPaymentData] = useState([]);

  const setModalShow = (data) => {
    setShowModal(data);
  };

  const CalendarMode = (action) => {
    setCalendarMode(action);
    setModalShow(false);
  };

  const setOrderingHandle = (orderBy) => {
    let act = '';
    switch (orderBy) {
      case 'date':
        act = ordering.ordering === 'date' ? '-date' : 'date';
        break;
      case 'document_number':
        act = ordering.ordering === 'document_number' ? '-document_number' : 'document_number';
        break;
      case 'project':
        act = ordering.ordering === 'project' ? '-project' : 'project';
        break;
      case 'type':
        act = ordering.ordering === 'type' ? '-type' : 'type';
        break;
      default:
        console.info('--Ошибка 1201');
    }
    SetOrdering({ ordering: act });
  };

  useEffect(() => {
    getResstrPayments(ordering)
      .then((resp) => {
        const allPayments = resp.data;
        SetPaymentData(allPayments);
        setLoad(false);
      })
      .catch(() => {
        setLoad(false);
        return new Error(errorMessages.noResponse);
      });
  }, [ordering]);

  return (
    <>
      {load ? <Loading /> : ''}
      {ShowModal && <ReestrPaymentsModal setModalShow={setModalShow} ShowMode={CalendarMode} />}
      <div className="top-content-block flex align-center space-between py-3 px-3">
        <div className="flex justify-center align-center">
          {isCalendarMode ? (
            <>
              <span onClick={() => CalendarMode(false)} className="cursor-pointer">
                <LeftArrowIcon />
              </span>

              <div className="h5 active-page m-0">Календарь согласований </div>
            </>
          ) : (
            <>
              <span onClick={() => navigate(-1)} className="cursor-pointer">
                <LeftArrowIcon />
              </span>

              <div className="h5 active-page m-0">
                Реестры платежей {paymentsData && <span> | {paymentsData.length} </span>}
              </div>
            </>
          )}
        </div>

        <div className="flex align-center justify-center">
          <span className="mx-0 px-2 cursor-pointer" onClick={() => setShowModal(true)}>
            <DottedMenuIcon2 />
          </span>
        </div>
      </div>

      <div className="container p-0 pt-3 data-container-bg">
        {isCalendarMode ? (
          <div className="d-flex justify-content-center">
            <Calendar
              onChange={setCalendarDate}
              value={calendarDate}
              locale="ru-RU"
              allowPartialRange={false}
            />
          </div>
        ) : (
          <ul className="px-0">
            <li>
              <ul className="table d-flex m-0 p-0 pb-2 title table-grid table_column_4">
                <li
                  className={[
                    ordering.ordering.includes('date') ? 'color-orrange' : '',
                    ' noselect flex-1 text-center cursor-pointer'
                  ].join(' ')}
                  onClick={() => setOrderingHandle('date')}
                >
                  Дата
                  <span
                    className={[ordering.ordering === '-date' ? 'up' : '', ' arrow ml-1'].join(' ')}
                  ></span>
                </li>
                <li
                  className={[
                    ordering.ordering.includes('document_number') ? 'color-orrange' : '',
                    ' noselect flex-1 text-center cursor-pointer'
                  ].join(' ')}
                  onClick={() => setOrderingHandle('document_number')}
                >
                  Номер
                  <span
                    className={[
                      ordering.ordering === '-document_number' ? 'up' : '',
                      ' arrow ml-1'
                    ].join(' ')}
                  ></span>
                </li>

                <li
                  className={[
                    ordering.ordering.includes('project') ? 'color-orrange' : '',
                    ' noselect flex-1 text-center cursor-pointer'
                  ].join(' ')}
                  onClick={() => setOrderingHandle('project')}
                >
                  Проект
                  <span
                    className={[ordering.ordering === '-project' ? 'up' : '', ' arrow ml-1'].join(
                      ' '
                    )}
                  ></span>
                </li>

                <li
                  className={[
                    ordering.ordering.includes('type') ? 'color-orrange' : '',
                    ' noselect flex-1 text-center cursor-pointer'
                  ].join(' ')}
                  onClick={() => setOrderingHandle('type')}
                >
                  Тип
                  <span
                    className={[ordering.ordering === '-type' ? 'up' : '', ' arrow ml-1'].join(' ')}
                  ></span>
                </li>
              </ul>
            </li>

            <li className="auto-overflow fixed-height hide-scroll-bar">
              <ul className="table px-0">
                {paymentsData &&
                  paymentsData.map((payment, index) => (
                    <li className="mb-1" key={index}>
                      <ul className="d-flex m-0 p-0 flex-nowrap align-items-center justify-content-between table-row position-relative overflow-hidden table-grid table_column_4">
                        <li className="flex-1 text-center cursor-pointer border_right position-relative">
                          <Link
                            to={`/inbox/reestr/${payment.id}/`}
                            className={[
                              payment.urgency ? 'color-orrange' : '',
                              ' default_link py-3 d-inline-block w-100'
                            ].join(' ')}
                          >
                            {formatDate(payment.date)}
                          </Link>
                        </li>

                        <li className="flex-1 text-center cursor-pointer border_right position-relative">
                          <Link
                            to={`/inbox/reestr/${payment.id}/`}
                            className={[
                              payment.urgency ? 'color-orrange' : '',
                              ' default_link py-3 d-inline-block w-100 text-capitalize'
                            ].join(' ')}
                          >
                            {payment.document_number}
                          </Link>
                        </li>

                        <li className="flex-1 text-center cursor-pointer border_right position-relative">
                          <Link
                            to={`/inbox/reestr/${payment.id}/`}
                            className={[
                              payment.urgency ? 'color-orrange' : '',
                              ' default_link py-3 d-inline-block w-100 text-capitalize'
                            ].join(' ')}
                          >
                            {payment.project ? payment.project : '-'}
                          </Link>
                        </li>

                        <li className="flex-1 text-center cursor-pointer position-relative">
                          <Link
                            to={`/inbox/reestr/${payment.id}/`}
                            className={[
                              payment.urgency ? 'color-orrange' : '',
                              ' default_link py-3 d-inline-block w-100 text-capitalize'
                            ].join(' ')}
                          >
                            {payment.type}
                          </Link>
                        </li>
                      </ul>
                    </li>
                  ))}
              </ul>
            </li>
          </ul>
        )}
      </div>
    </>
  );
};
export default ReestrPayments;
