const initialState = {
  showMeetings: true
};

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
