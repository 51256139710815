import React, { useEffect, useState } from 'react';

import { useMeetings } from './hooks/use-meetings';

import Filters from './UI/Filters';
import MeetingItem from './UI/MeetingItem';

import './assets/css/styles.css';
import './assets/css/viewport.css';
import { getSchedules } from '../../../api/bookingApi';
import timeLineScrollToHour from '../verticalBarTime';

let lastSequentialLoadLoopId = [];

const DesktopBookings = () => {
  const [
    {
      isStillLoading,
      isFiltersDiabled,
      isTypeModalShow,
      userEmail,
      startDate,
      activeFilterValues,
      initialFiltersData,
      roomsData
    },
    { onClick, setDate, closeTypeModalHandle, setUserMeetingRoomsHandle }
  ] = useMeetings();

  const [roomsAndSchedulesCombined, setRoomsAndSchedulesCombined] = useState([]);

  function _timeLineScrollToHour() {
    const parentDivSelector = '.meeting-bookins-times-container';
    const verticalBarTimeSelector = '.vertical-bar-time-desktop';
    const isMultiple = true;
    timeLineScrollToHour(
      parentDivSelector,
      verticalBarTimeSelector,
      activeFilterValues?.timeStart,
      activeFilterValues?.timeEnd,
      isMultiple
    );
  }

  useEffect(() => {
    const interval = setInterval(() => {
      _timeLineScrollToHour();
    }, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!roomsData) return;
    lastSequentialLoadLoopId = Math.floor(Math.random() * 1000);
    getSchedulesSequentially();
  }, [roomsData]);

  async function getSchedulesSequentially() {
    setRoomsAndSchedulesCombined([]);

    const rememberedLastSequentialLoadLoopId = lastSequentialLoadLoopId;

    let filteredRoomsData = roomsData;
    if (activeFilterValues.room.name !== 'Все') {
      filteredRoomsData = roomsData.filter((item) => {
        return item.name === activeFilterValues.room?.name;
      });
    }

    for (let i = 0; i < filteredRoomsData.length; i++) {
      const meeting = filteredRoomsData[i];
      const scheduleDate = `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`;
      try {
        const resp = await getSchedules({
          email: meeting.email,
          date: scheduleDate
        });

        /* Filters are changed, stopping current sequntial loop, there's coming new one */
        if (rememberedLastSequentialLoadLoopId !== lastSequentialLoadLoopId) break;

        setRoomsAndSchedulesCombined((prevState) => [
          ...prevState,
          {
            ...meeting,
            schedules: resp.data
          }
        ]);
      } catch (error) {
        console.info('Error fetching schedules:', error);
      }
    }
  }

  return (
    <>
      <Filters
        isFiltersDiabled={isFiltersDiabled}
        isTypeModalShow={isTypeModalShow}
        startDate={startDate}
        activeFilterValues={activeFilterValues}
        initialFiltersData={initialFiltersData}
        onClick={(event, rooom) => {
          onClick(event, rooom);
        }}
        setDate={setDate}
        closeTypeModalHandle={closeTypeModalHandle}
      />

      <section className="meetings-block d-flex flex-column">
        {isStillLoading ? (
          <div className="no-resuilts p-3 text-center warning-block">Загрузка данных...</div>
        ) : roomsAndSchedulesCombined?.length > 0 ? (
          <>
            {roomsAndSchedulesCombined.map((meeting, index) => (
              <MeetingItem
                meeting={meeting}
                key={index}
                startDate={startDate}
                userEmail={userEmail}
                onRenderMeetingItem={() => {
                  setTimeout(() => {
                    _timeLineScrollToHour();
                  }, 100);
                }}
                myMeetingsActive={activeFilterValues.myMeetingsActive}
                activeFilterValues={activeFilterValues}
                setUserMeetingRoomsHandle={setUserMeetingRoomsHandle}
              />
            ))}
            {roomsData.map((room) => room.display).every((item) => item === 'none') && (
              <div className="no-resuilts p-3 text-center warning-block">
                Результаты не найдены
              </div>
            )}
          </>
        ) : (
          <div className="no-resuilts p-3 text-center warning-block">Результаты не найдены</div>
        )}
      </section>
    </>
  );
};
export default DesktopBookings;
