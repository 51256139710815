import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getNews } from '../../api/hrApi';

import { ReactComponent as OrangeDotIcon } from '../../assets/images/icon/orange-dot-icon.svg';
import { ReactComponent as ArrowUp } from '../../assets/images/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../assets/images/icons/arrow-down.svg';

import CommonLine from '../../components/newCreated/CommonLine/CommonLine.jsx';
import Navigation from '../../components/Navigation/Navigation.jsx';

const NewsLine = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [load, setLoad] = useState(false);

  const [newsType, setNewType] = useState('company');
  const [index, setIndex] = useState(0);
  const [tagsData, setTagsData] = useState([]);
  const [newsStore, setNewsStore] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [showArrowUp, setArrowUp] = useState(false);
  const [showArrowDown, setArrowDown] = useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    if (location.hash) {
      setNewType(location.hash.replace('#', ''));
    }
  }, [location, location.hash]);
  const showNews = (newsType, idx) => {
    navigate(location.pathname + '#' + newsType);
    setIndex(idx);
  };

  useEffect(() => {
    dispatch({ type: 'GlOBAL_LOADER', show: true });
    getNews()
      .then((resp) => {
        const news = resp.data;
        setNewsStore(news);

        const tags = [];
        news.forEach((newsItem, index) => {
          newsItem.tags.forEach((tag, index) => {
            tags.push(tag);
          });
        });

        const ids = tags.map((t) => t.id);
        const Filterdtags = tags.filter(({ id }, index) => !ids.includes(id, index + 1));

        let AllTagsData = [...Filterdtags, { id: 0, name: 'Все новости' }].sort((a, b) => {
          return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
        });
        setTagsData(AllTagsData);
      })
      .catch((error) => console.info(error))
      .finally(() => dispatch({ type: 'GlOBAL_LOADER', show: false }));
  }, []);

  useEffect(() => {
    if (tagsData.length > 0) {
      setLoad(true);
      if (tagsData[index].id === 0) {
        setNewsData(newsStore);
      } else {
        const FilterDNews = [];
        const news = newsStore.forEach((newsItem) => {
          if (newsItem.tags.find((tag) => tag.id === tagsData[index].id)) {
            FilterDNews.push(newsItem);
          }
        });

        setNewsData(FilterDNews);
      }
      setLoad(false);
    }
  }, [tagsData, index]);

  const SetChangedNewsData = (data) => {
    let Changednews = { ...newsStore.find((news) => news.id === data.id), ...data };
    let AllNews = newsStore.map((news) => {
      if (Changednews.id === news.id) {
        return Changednews;
      }
      return news;
    });
    setNewsStore(AllNews);

    let FilterdNews = newsData.map((news) => {
      if (Changednews.id === news.id) {
        return Changednews;
      }
      return news;
    });
    setNewsData(FilterdNews);
  };

  useEffect(() => {
    const checkPosition = (e) => {
      const element = document.querySelector('.newsline__block');
      const topPosition = 130;
      if (window.scrollY > topPosition) {
        setArrowUp(true);
      } else {
        setArrowUp(false);
      }
      if (window.scrollY + window.innerHeight >= element.scrollHeight) {
        setArrowDown(false);
      } else {
        setArrowDown(true);
      }
    };
    document.addEventListener('scroll', checkPosition);
    return () => {
      document.removeEventListener('scroll', checkPosition);
    };
  }, [newsData]);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };
  const goToDown = () => {
    window.scrollTo({
      top: window.scrollY + 400,
      left: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="newsline">
      <Navigation blockName="newsline" />

      <div className="newsline__block">
        <div className="newsline__block-nav">
          <ul className="newsline__block-nav-list">
            {tagsData &&
              tagsData.map((tag, tag_index) => (
                <li
                  onClick={() => showNews(tag.name.split(' ').join('_').toLowerCase(), tag_index)}
                  className="newsline__block-nav-item"
                  key={tag_index}
                >
                  <span className={index === tag_index ? 'title active' : 'title'}>
                    {tag.name}
                    {index === tag_index && (
                      <span className="dot">
                        <OrangeDotIcon />
                      </span>
                    )}
                  </span>
                </li>
              ))}
          </ul>
        </div>

        {showArrowUp && (
          <div className="arrow-up-line" onClick={goToTop}>
            <ArrowUp />
          </div>
        )}
        {showArrowDown && (
          <div className="arrow-down-line" onClick={goToDown}>
            <ArrowDown />
          </div>
        )}
        <CommonLine newsData={newsData} newNewsData={SetChangedNewsData} />
      </div>
    </div>
  );
};

export default NewsLine;
