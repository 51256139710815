import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ReactComponent as ArrowLeftIcon } from '../../assets/images/icons/arrow-left-icon.svg';
import { ReactComponent as MenuIcon } from '../../assets/images/icons/menu-icon.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/icons/search-icon.svg';
import { ReactComponent as RestartIcon } from '../../assets/images/icons/restart-icon.svg';
import { ReactComponent as DotsIcon } from '../../assets/images/icon/dots-icon-vertical.svg';
import { ReactComponent as ClearIcon } from '../../assets/images/icons/clear-icon.svg';
import { ReactComponent as FiltrationIcon } from '../../assets/images/icons/filtration-icon.svg';
import { ReactComponent as SettingIcon } from '../../assets/images/icons/setting-icon.svg';
import { ReactComponent as DotsIconVertical } from '../../assets/images/icon/dots-icon-vertical.svg';

const Navigation = ({ blockName, navTitle, title, path = '', SearchField = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState('');
  const showSearchHandler = () => {
    setShowSearch(true);
  };
  const closeInput = () => {
    setShowSearch(false);
    setSearch('');
  };

  useEffect(() => {
    dispatch({ type: 'SET_SEARCH_VALUE', value: search });
  }, [search]);

  switch (blockName) {
    case 'acts':
      return (
        <div className={`${blockName}__nav`}>
          <ArrowLeftIcon />
          <div className={`${blockName}__nav-right`}>
            {showSearch ? (
              <div className={`${blockName}__nav-input-wrapper`}>
                <input
                  className={`${blockName}__nav-input`}
                  type="text"
                  placeholder="Введите текст..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <ClearIcon className={`${blockName}__nav-clear-input`} onClick={closeInput} />
              </div>
            ) : (
              <>
                <h3 className={`${blockName}__nav-title`}>
                  {navTitle} <span>| 32</span>
                </h3>
                <div className={`${blockName}__nav-left`}>
                  <MenuIcon />
                  <span onClick={() => showSearchHandler()}>
                    <SearchIcon />
                  </span>
                  <RestartIcon />
                </div>
              </>
            )}
          </div>
        </div>
      );

    case 'act-details':
      return (
        <div className={`${blockName}__nav`}>
          <div className={`${blockName}__nav-left`}>
            <ArrowLeftIcon />
            <h3 className={`${blockName}__nav-title`}>{navTitle}</h3>
          </div>
          <DotsIcon />
        </div>
      );

    case 'notif-send':
      return (
        <div className={`${blockName}__nav`}>
          <div className={`${blockName}__nav-left`}>
            <ArrowLeftIcon />
            <h3 className={`${blockName}__nav-title`}>Отправка уведомления</h3>
          </div>
          <FiltrationIcon />
        </div>
      );

    case 'main':
      return (
        <div className="main__nav">
          <div className="main__nav__title">
            ГК ФСК
            <span>место силы</span>
          </div>
          <SettingIcon
            className="cursor-pointer"
            data-testid="settingsButton"
            onClick={() => dispatch({ type: 'SETTINGS_MODAL', show: true })}
          />
        </div>
      );

    case 'newsline':
      return (
        <div className="newsline__nav">
          <ArrowLeftIcon
            onClick={(e) => {
              navigate('/hr/');
            }}
          />
          <div className="newsline__nav-wrapper">
            <h3 className="newsline__nav-title">Лента</h3>
            <div className="newsline__nav-icons">
              <DotsIconVertical />
            </div>
          </div>
        </div>
      );

    case 'allNews':
      return (
        <div className="newsline__nav">
          <ArrowLeftIcon
            onClick={(e) => {
              navigate('/hr/newsline/');
            }}
          />
          <div className="newsline__nav-wrapper">
            <h3 className="newsline__nav-title">Лента</h3>
            <div className="newsline__nav-icons">
              <DotsIconVertical />
            </div>
          </div>
        </div>
      );

    case 'static-pages':
      return (
        <div className="newsline__nav">
          <ArrowLeftIcon
            onClick={(e) => {
              navigate(-1);
            }}
          />
          <div className="newsline__nav-wrapper">
            <h3 className="newsline__nav-title">{title}</h3>
            <div className="newsline__nav-icons">
              <DotsIconVertical />
            </div>
          </div>
        </div>
      );

    case 'static-page':
      return (
        <div className="newsline__nav">
          <ArrowLeftIcon
            onClick={(e) => {
              navigate(-1);
            }}
          />
          <div className="newsline__nav-wrapper">
            <h3 className="newsline__nav-title">{title}</h3>
            <div className="newsline__nav-icons">
              <DotsIconVertical />
            </div>
          </div>
        </div>
      );

    case 'references':
      return (
        <div className="newsline__nav">
          <ArrowLeftIcon
            onClick={(e) => {
              navigate('/hr/');
            }}
          />
          <div className="newsline__nav-wrapper">
            <h3 className="newsline__nav-title">{title}</h3>
            <div className="newsline__nav-icons">
              <DotsIconVertical />
            </div>
          </div>
        </div>
      );

    case 'referenceTemplates':
      return (
        <div className="newsline__nav">
          <ArrowLeftIcon
            onClick={(e) => {
              navigate('/hr/references');
            }}
          />
          <div className="newsline__nav-wrapper">
            <h3 className="newsline__nav-title">{title}</h3>
            <div className="newsline__nav-icons">
              <DotsIconVertical />
            </div>
          </div>
        </div>
      );

    case 'reference':
      return (
        <div className="newsline__nav">
          <ArrowLeftIcon
            onClick={(e) => {
              navigate(-1);
            }}
          />
          <div className="newsline__nav-wrapper">
            <h3 className="newsline__nav-title">{title}</h3>
            <div className="newsline__nav-icons">
              <DotsIconVertical />
            </div>
          </div>
        </div>
      );

    case 'booking':
      return (
        <div className="newsline__nav">
          <ArrowLeftIcon
            onClick={(e) => {
              navigate('/hr/');
            }}
          />
          <div className="newsline__nav-wrapper">
            <h3 className="newsline__nav-title">{title}</h3>
            <div className="newsline__nav-icons">
              <DotsIconVertical />
            </div>
          </div>
        </div>
      );

    case 'phonebook':
      return (
        <div className="phonebook__nav">
          <ArrowLeftIcon
            onClick={(e) => {
              path !== '' && navigate(path);
            }}
          />
          <div className="phonebook__nav-wrapper">
            <h3 className="phonebook__nav-title">{navTitle}</h3>
            {showSearch && (
              <div className={`${blockName}__nav-input-wrapper`}>
                <input
                  className={`${blockName}__nav-input`}
                  type="text"
                  placeholder="Поиск сотрудников..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="search-icon">
                  <ClearIcon className={`${blockName}__nav-clear-input`} onClick={closeInput} />
                </span>
              </div>
            )}
            <div className="phonebook__nav-icons">
              {SearchField && (
                <span onClick={() => showSearchHandler()}>{!showSearch && <SearchIcon />}</span>
              )}

              {!showSearch && <DotsIconVertical />}
            </div>
          </div>
        </div>
      );

    default:
      return <div>no nav!</div>;
  }
};

export default Navigation;
