import jwt_decode from 'jwt-decode';

import Keycloak from './keycloack';

import {
  keycloakSsoAuthEnabled,
  loginAuthRegular,
  sendRequestClearCookiesToRoot
} from '../api/commonApi';
import {
  setUserSsoData,
  clearUserData,
  setUserRegularData,
  clearNotImportantLocalStorageUserData
} from '../lib/handleAuthData';

import { getGreenMyInfo } from '../../apps/green_button/api/greenApi';

import { clearSessionIdApi } from '../api/commonApi';

import { getStaticPagesCategories } from '../../apps/hr_client/api/hrApi';

import navigationService from '../../navigationService';

import { getUserLocalData } from '../lib/handleAuthData';

import { knopkaUserEmailKey } from '../../config';
import { isWebPushDisabled, unsubscribePush } from '../plugins/pushNotifications/main.js';

import { logSentryError, logSentryMessage } from './utils';

export const setUserSsoDataByKeycloakData = (ssoKeycloakData) => {
  let ssoUserDecodedInformation;

  try {
    ssoUserDecodedInformation = jwt_decode(ssoKeycloakData.access_token);
  } catch (error) {
    console.info('Error decoding JWT:', error);
    return;
  }

  let hrUserKey = null;
  if (ssoUserDecodedInformation.realm_access.roles.includes('ad-fsk')) {
    console.info('ad-fsk!');
    /* этой роли должны быть доступны и кнопка и hr */
    hrUserKey = ssoKeycloakData.access_token;
  } else {
    console.info('no ad-fsk!');
  }

  localStorage.setItem(knopkaUserEmailKey, ssoUserDecodedInformation.email);

  setUserSsoData(
    ssoKeycloakData.access_token,
    ssoUserDecodedInformation.email,
    ssoUserDecodedInformation.name,
    hrUserKey
  );
};

export const loginRegular = async (userName, userPassword) => {
  let result;
  try {
    result = await loginAuthRegular(userName, userPassword);
    const { key, email, fio, hrKey } = result.data;
    setUserRegularData(key, email, fio, hrKey);
    return result;
  } catch (error) {
    return error;
  }
};

export const refreshSsoAccessTokenByRefreshToken = async () => {
  if (location.pathname.indexOf('/keycloack') == 0) {
    return 'index_keycloack';
  }

  return await Keycloak.refreshAccessToken();
};

export const softUpdate = async () => {
  if (!isWebPushDisabled()) await unsubscribePush();
  clearSiteData();
  clearNotImportantLocalStorageUserData();
  setTimeout(() => {
    window.location.reload();
  }, 1000);
};

export const hardLogout = async () => {
  clearSiteData();
  clearUserData();
  logSentryMessage('Navigate to login, because of logout');
  navigateToLogin();
  setTimeout(() => {
    window.location.reload();
  }, 1000);
};

export const saveKnopkaEmailApi = async () => {
  return getGreenMyInfo()
    .then((resp) => {
      const data = resp.data;
      // localStorage.setItem(knopkaUserEmailKey, data.email);
      return true;
    })
    .catch((error) => {
      logSentryError(`Не удалось получить данные приложения (/my-info) ${error}`);
      return false;
    });
};

export const navigateToLogin = () => {
  navigationService.navigate('/login');
};

export const unregisterServiceWorkers = () => {
  navigator?.serviceWorker
    ?.getRegistrations()
    .then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
      console.info('service workers were unregistered');
    })
    .catch((error) => {
      logSentryError(`Error unregistering service worker:', ${error}`);
    });
};

export const clearCookies = () => {
  document.cookie = 'csrftoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie = 'sessionid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure; HttpOnly';

  let allCookies = document.cookie.split(';');
  // The "expire" attribute of every cookie is
  // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
  for (let i = 0; i < allCookies.length; i++)
    document.cookie = allCookies[i] + '=;expires=' + new Date(0).toUTCString();

  document.cookie = 'sessionid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
};

export const clearSiteData = () => {
  clearSessionIdApi();
  sendRequestClearCookiesToRoot();
  clearCookies();
  unregisterServiceWorkers();
};

export const handleAutoNavigateOnLoadRegular = async () => {
  const { greenUserKey, hrUserKey } = getUserLocalData() || {};
  if (!greenUserKey && !hrUserKey) {
    /* user was not loggged in yet, so we redirect to login */
    logSentryMessage(
      'Navigate to login1, because no ssoUserKey and no hrUserKey'
    );
    navigateToLogin();
    return;
  }
  navigationService.navigate('/hr');
};

export const handleAutoNavigateOnLoadMobile = async () => {
  if (keycloakSsoAuthEnabled) {
    handleAutoNavigateOnLoadSso();
  } else {
    handleAutoNavigateOnLoadRegular();
  }
};

export const handleAutoNavigateOnLoadSso = async (onRootLayoutReload = null) => {
  const { ssoUserKey, hrUserKey } = getUserLocalData() || {};

  if (!ssoUserKey && !hrUserKey) {
    /* user was not loggged in yet, so we redirect to login */
    logSentryMessage(
      `Navigate to login2, because no ssoUserKey and no hrUserKey, onRootLayoutReload:${onRootLayoutReload}`
    );
    navigateToLogin();
    return;
  } else {
    /* user was logged in, but we checking actuality of access keys,
    this is for preventing from endless cycle
    */
    let greenButtonAcessStatus;
    let hrAcessStatus;

    if (hrUserKey) {
      hrAcessStatus = await getStaticPagesCategories(false);
      hrAcessStatus = hrAcessStatus?.status;
    }

    if (ssoUserKey) {
      greenButtonAcessStatus = await getGreenMyInfo(false);
      greenButtonAcessStatus = greenButtonAcessStatus?.status;
    }

    if (hrAcessStatus === 200) {
      logSentryMessage('Auto navigate to /hr');
      navigationService.navigate('/hr');
      return;
    }

    if (greenButtonAcessStatus === 200) {
      logSentryMessage(
        'Auto navigate to /inbox because user has access to only green button and not to hr'
      );
      navigationService.navigate('/inbox');
      return;
    }

    logSentryError(
      `Could not decide where auto navigate to during handleAutoNavigateOnLoad ${onRootLayoutReload}`
    );

    // hardLogout();
  }
};
