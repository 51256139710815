import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import { setResstrAction } from '../../api/greenApi';
import { formatSpacesThousands } from '../../lib/utils';

import ConfirmActionModal from '../UI/ConfirmActionModal.jsx';
import ResstrProjectItem from './ResstrProjectItem.jsx';

import Loading from '../../../../common/components/UI/Loading';
import Success from '../UI/Success.jsx';
import Error from '../UI/Error.jsx';
import Alert from '../UI/Alert.jsx';

const ResstrProjects = ({ ExpenseGroups, ReestrType }) => {
  const { external_id } = useParams();
  const [Amount, setAmount] = useState(0);
  const [Prepaid, setPrepaid] = useState(0);
  const [activeConfirmModal, setActiveConfirmModal] = useState(false);
  const [buttonAction, SetButtonAction] = useState('');
  const [load, setLoad] = useState(false);
  const [activeComment, setActiveComment] = useState(false);
  const [comment, setComment] = useState('');
  const [answer, setAnswer] = useState('');

  const [showSuccess] = useState(false);
  const showError = useSelector((state) => state.greenButton.showError);
  const showWarning = useSelector((state) => state.greenButton.showWarning);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const AmountAll = ExpenseGroups.map((ExpenseGroup) =>
      ExpenseGroup.receiver_groups.map((receiver_group) =>
        receiver_group.payments.map((payment) => parseFloat(payment.amount))
      )
    )
      .map((pItem) =>
        pItem.map((tt) =>
          tt.reduce(
            (previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue)
          )
        )
      )
      .map((itemItem) =>
        itemItem.reduce(
          (previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue)
        )
      )
      .reduce(
        (previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue)
      );
    setAmount(AmountAll);

    const PrepaidAll = ExpenseGroups.map((ExpenseGroup) =>
      ExpenseGroup.receiver_groups.map((receiver_group) =>
        receiver_group.payments.map((payment) => parseFloat(payment.prepaid))
      )
    )
      .map((pItem) =>
        pItem.map((tt) =>
          tt.reduce(
            (previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue)
          )
        )
      )
      .map((itemItem) =>
        itemItem.reduce(
          (previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue)
        )
      )
      .reduce(
        (previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue)
      );
    setPrepaid(PrepaidAll);
  }, [ExpenseGroups]);

  const buttonHandle = (action) => {
    SetButtonAction(action);
  };

  useEffect(() => {
    if (buttonAction !== '') {
      if (buttonAction === 'approve') {
        confirmAction(true);
      } else {
        setActiveConfirmModal(true);
      }
    }
  }, [buttonAction]);

  const confirmAction = (success) => {
    setActiveConfirmModal(false);
    if (success) {
      setLoad(true);
      setResstrAction(external_id, buttonAction, comment)
        .then((response) => response.data)
        .then((success) => {
          setLoad(false);
          switch (success.code) {
            case '200':
              console.info('--Все ок');
              navigate('/inbox/reestr/', { replace: true });
              //toast.success(success.description);
              toast.success(success.description);
              break;
            case '400':
              console.info('--Входящие данные не корректны');
              // setAnswer(success.description);
              // dispatch({ type: "setWarningOpen" });
              navigate('/inbox/reestr/', { replace: true });
              console.info(success.description);
              break;
            case '423':
              console.info('--Администратор уже согласовал эту визу');
              // setAnswer(success.description);
              // setShowSuccess(true);
              navigate('/inbox/reestr/', { replace: true });
              toast.success(success.description);
              break;
            case '500':
              console.info('--Ошибка на стороне сервера 1С');
              navigate('/inbox/reestr/', { replace: true });
              toast(success.description, {
                icon: '⚠️'
              });
              break;
            case '666':
              console.info('Ошибка на стороне сервера 1С');
              // setAnswer(success);
              // dispatch({ type: "setErrorOpen" });
              navigate('/inbox/reestr/', { replace: true });
              console.info(success);
              break;
            default:
              console.info('--setResstrAction не найдено');
              // setAnswer(success);
              // dispatch({ type: "setErrorOpen" });
              navigate('/inbox/reestr/', { replace: true });
              console.info(success);
          }
        })
        .catch((error) => {
          if (error.response) {
            setLoad(false);
            setAnswer('Произошла ошибка сервера MSBOT');
            dispatch({ type: 'setErrorOpen' });
          }
        });
    }
  };

  useEffect(() => {
    if (activeComment) {
      setTimeout(() => {
        const element = document.querySelector('.auto-overflow');
        element.scrollTo({
          top: element.scrollHeight,
          left: 0,
          behavior: 'smooth'
        });
      }, 500);
    }
  }, [activeComment]);

  return (
    <>
      {showSuccess && <Success>{answer}</Success>}
      {showError ? <Error>{answer}</Error> : ''}
      {showWarning ? <Alert>{answer}</Alert> : ''}
      {load ? <Loading /> : ''}
      {activeConfirmModal && <ConfirmActionModal confirmAction={confirmAction} time={2} />}

      <div className="h4 px-3 pt-2 pb-0 font-weight-500">Реестр платежей - {ReestrType}</div>
      <ul className="table m-0 mt-1 p-0 pb-0 title table-grid table_column_4">
        <li className="noselect flex-1 text-center justify-content-start pl-3 gide-span-2">
          Проект
        </li>
        <li className="noselect flex-1 text-center justify-content-end pr-3">Сумма</li>
        <li className="noselect flex-2 text-center justify-content-end pr-3">в т.ч. Аванс</li>
      </ul>

      <ul className="table projects px-0">
        <li className="mb-1 font-weight-700">
          <ul className="m-0 p-0 py-2 align-items-center justify-content-between total-row position-relative overflow-hidden table-grid table_column_4">
            <li className="flex-1 text-center cursor-pointer border_right position-relative justify-content-start pl-3 gide-span-2">
              <div className="default_link py-3 d-inline-block font-weight-700 align-items-center d-flex">
                ИТОГО
              </div>
            </li>
            <li className="flex-1 text-center cursor-pointer border_right position-relative justify-content-end pr-3">
              <div className="py-3 d-inline-block">{formatSpacesThousands(parseFloat(Amount))}</div>
            </li>
            <li className="flex-2 text-center cursor-pointer border_right position-relative justify-content-end pr-3">
              <div className="py-3 d-inline-block">
                {formatSpacesThousands(parseFloat(Prepaid))}
              </div>
            </li>
          </ul>
        </li>

        {ExpenseGroups && (
          <>
            {ExpenseGroups.map((ExpenseGroup, index) => (
              <ResstrProjectItem key={index} ExpenseGroup={ExpenseGroup} />
            ))}
          </>
        )}

        <li className="mb-0 font-weight-700">
          <ul className="m-0 p-0 py-2 align-items-center justify-content-between total-row position-relative overflow-hidden table-grid table_column_4">
            <li className="flex-1 text-center cursor-pointer border_right position-relative justify-content-start pl-3 gide-span-2">
              <div className="default_link py-3 d-inline-block font-weight-700 align-items-center d-flex">
                ИТОГО
              </div>
            </li>
            <li className="flex-1 text-center cursor-pointer border_right position-relative justify-content-end pr-3">
              <div className="py-3 d-inline-block">{formatSpacesThousands(parseFloat(Amount))}</div>
            </li>
            <li className="flex-2 text-center cursor-pointer border_right position-relative justify-content-end pr-3">
              <div className="py-3 d-inline-block">
                {formatSpacesThousands(parseFloat(Prepaid))}
              </div>
            </li>
          </ul>
        </li>

        <li>
          <div className="d-flex flex-wrap px-3 mt-5 justify-content-center">
            <button className="btn approve mx-3 my-0" onClick={() => buttonHandle('approve')}>
              Согласовать
            </button>
            <button className="btn reject mx-3 my-0" onClick={() => setActiveComment(true)}>
              Отклонить
            </button>
          </div>

          {activeComment && (
            <div className="description-editor-block px-4">
              <hr></hr>
              <textarea
                className="input form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                placeholder="Введите текст..."
                onInput={(e) => {
                  setComment(e.target.value);
                }}
              ></textarea>
              <button
                className="btn approve mx-0 my-3 send-with-comment"
                onClick={() => comment.length > 0 && buttonHandle('reject')}
                disabled={comment.length > 0 ? false : true}
              >
                Отправить
              </button>
            </div>
          )}
        </li>
      </ul>
    </>
  );
};
export default ResstrProjects;
