import React from 'react';
import { useNavigate } from 'react-router-dom';

import ok from '../../assets/images/icons/okIcon.svg';

export default function Success({ children }) {
  const navigate = useNavigate();

  return (
    <div className="modal fade show" id="SuccessModal" role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog m-0">
        <div className="modal-content">
          <div className="modal-body flex align-center justify-center flex-column">
            <img alt="Success" src={ok} />
            <p className="mt-4 mb-5">{children ? children : 'Все ок!'}</p>
            <button onClick={() => navigate(-1)} className="btn return mt-5">
              Назад
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
