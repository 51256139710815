import React, { useEffect } from 'react';

import { hrApiFullUrl } from '../../common/api/commonApi';
import { handleAutoNavigateOnLoadMobile } from '../../common/lib/auth';

import './mobileAppPage.css';

const MobileAppPage = () => {
  useEffect(() => {
    localStorage.setItem('hrApiFullUrl', hrApiFullUrl);
    localStorage.setItem('disableTopNavGreenButtonMobileWeb', true);

    initListenMessageFromIframe();
  }, []);

  const initListenMessageFromIframe = () => {
    window.addEventListener('message', handleMessageFromIframe);
  };

  const handleMessageFromIframe = (event) => {
    if (event.data === 'goBack') {
      handleAutoNavigateOnLoadMobile();
    }
  };

  return (
    <>
      <div className="mobile-app-container">
        <iframe className="mobile-app-iframe" src="./mobile_dist/index.html" title="My React App" />
      </div>
    </>
  );
};

export default MobileAppPage;
