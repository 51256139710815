import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ScheduleStatus from './ScheduleStatus';

const TimeItem = ({
  schedules,
  classes,
  type,
  scheduleData,
  Timevalue,
  scheduleModalData,
  startTime,
  StatusModalStatus,
  timer,
  setisScheduleModalOpen
}) => {
  const [isShowStatusModal, setShowStatusModal] = useState(false);
  const [Isleft, setIsleft] = useState(true);
  const [IsTop, setIsTop] = useState(true);
  const [SModalData, setSModalData] = useState({});

  const ShowStatusModelHandle = (event) => {
    setisScheduleModalOpen(true);
    let ModalWidth = 322;
    let ModalHeight = 437;
    StatusModalStatus.setBusy(true);
    timer.current = setTimeout(() => {
      setShowStatusModal(true);
      if (window.innerWidth - ModalWidth < event.clientX) {
        setIsleft(false);
      }
      if (window.innerHeight - ModalHeight < event.clientY) {
        setIsTop(false);
      }
    }, 500);
  };

  const SectionStart = (event) => {
    const elements = event.target.offsetParent.querySelectorAll('.time-period');
    elements.forEach((element) => {
      element.classList.remove('selected');
    });

    setSModalData({
      ...SModalData,
      StartTime: event.target.dataset.value,
      EndTime: undefined
    });
  };

  const SectionEnd = (event) => {
    let times = schedules.map((time) => time.time);
    times.push('21:00');
    let filterTimes = times
      .filter(
        (time) =>
          parseFloat(String(time).replace(':', '.')) >
          parseFloat(String(event.target.dataset.value).replace(':', '.'))
      )
      .shift();

    if (
      parseFloat(String(SModalData.StartTime).replace(':', '.')) !==
      parseFloat(String(event.target.dataset.value).replace(':', '.'))
    ) {
      setSModalData({
        ...SModalData,
        StartTime: startTime,
        EndTime: filterTimes,
        target: event
      });
    } else {
      setSModalData({
        ...SModalData,
        StartTime: startTime,
        EndTime: filterTimes
      });
    }
  };

  const isMobileView = () => {
    if (window.innerWidth > 767) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (SModalData.StartTime !== undefined || SModalData.EndTime !== undefined) {
      scheduleModalData(SModalData);
    }
  }, [SModalData]);

  const OnMouseLeaveHandle = (event) => {
    // event.preventDefault();
    if (StatusModalStatus.isBusy) {
      clearInterval(timer.current);
      if (isShowStatusModal) {
        setShowStatusModal(false);
        StatusModalStatus.setBusy(false);
      }
      setisScheduleModalOpen(false);
    }
  };

  const bookingDisabled = useSelector((state) => state.bookings.bookingDisabled);

  return (
    <React.Fragment>
      <div
        className={classes}
        onMouseEnter={(e) => (type !== 'free' && !isMobileView() ? ShowStatusModelHandle(e) : '')}
        onMouseLeave={(event) => OnMouseLeaveHandle(event)}
        // onClick={(e) => type === 'free' ? scheduleModalHandle() : '' }
        onMouseDown={(e) =>
          !bookingDisabled && type === 'free' && !isMobileView() ? SectionStart(e) : ''
        }
        onMouseUp={(e) => !bookingDisabled && type === 'free' && SectionEnd(e)}
        data-value={Timevalue}
      >
        {StatusModalStatus.isBusy && isShowStatusModal && (
          <ScheduleStatus scheduleData={scheduleData} Type={type} Isleft={Isleft} IsTop={IsTop} />
        )}
      </div>
    </React.Fragment>
  );
};

export default TimeItem;
