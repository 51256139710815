import React from 'react';
import { useSelector } from 'react-redux';

import './loader.css';

const GlobalLoader = () => {
  const loader = useSelector((state) => state.loader.show);
  return (
    loader && (
      <div className="loader-block">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  );
};

export default GlobalLoader;
