import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Filters from '../DesktopBookings/UI/Filters';
import { ConfigProvider, Space, Button, DatePicker } from 'antd';
import 'antd/dist/antd.min.css';
import { ReactComponent as SettingIcon } from './assets/images/icons/mi_filter.svg';
import { ReactComponent as ClearFiltersIcon } from './assets/images/icons/clear-filters.svg';
import './assets/styles.scss';
import ru_RU from 'antd/lib/locale-provider/ru_RU';
import 'moment/locale/ru';
import { useMeetings } from '../../GlobalBookings/DesktopBookings/hooks/use-meetings';

import MeetingItemMobileLeftPane from './UI/MeetingItemMobileLeftPane';
import MeetingItemMobileRightPane from './UI/MeetingItemMobileRightPane';

import './MobileBookings.css';
import { getSchedules } from '../../../api/bookingApi';
import timeLineScrollToHour from '../verticalBarTime';

let lastSequentialLoadLoopId = [];

const MobileBookings = () => {
  const [
    {
      isStillLoading,
      isFiltersDiabled,
      isTypeModalShow,
      userEmail,
      startDate,
      activeFilterValues,
      initialFiltersData,
      roomsData
    },
    { onClick, setDate, closeTypeModalHandle, setUserMeetingRoomsHandle, resetFilters, resetDate }
  ] = useMeetings();

  const dispatch = useDispatch();
  const filterModal = useSelector((state) => state.filterModal.show);
  const [roomsAndSchedulesCombined, setRoomsAndSchedulesCombined] = useState([]);

  function _timeLineScrollToHour() {
    const parentDivSelector = '.right-pane-booking';
    const verticalBarTimeSelector = '.vertical-bar-time-mobile';
    const isMultiple = false;
    timeLineScrollToHour(
      parentDivSelector,
      verticalBarTimeSelector,
      activeFilterValues?.timeStart,
      activeFilterValues?.timeEnd,
      isMultiple
    );
  }

  useEffect(() => {
    const interval = setInterval(() => {
      _timeLineScrollToHour();
    }, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!roomsData) return;
    lastSequentialLoadLoopId = Math.floor(Math.random() * 1000);
    getSchedulesSequentially();
  }, [roomsData]);

  async function getSchedulesSequentially() {
    setRoomsAndSchedulesCombined([]);

    const rememberedLastSequentialLoadLoopId = lastSequentialLoadLoopId;

    let filteredRoomsData = roomsData;
    if (activeFilterValues.room.name !== 'Все') {
      filteredRoomsData = roomsData.filter((item) => {
        return item.name === activeFilterValues.room?.name;
      });
    }

    for (let i = 0; i < filteredRoomsData.length; i++) {
      const meeting = filteredRoomsData[i];
      const scheduleDate = `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`;
      try {
        const resp = await getSchedules({
          email: meeting.email,
          date: scheduleDate
        });

        /* Filters are changed, stopping current sequntial loop, there's coming new one */
        if (rememberedLastSequentialLoadLoopId !== lastSequentialLoadLoopId) break;

        setRoomsAndSchedulesCombined((prevState) => [
          ...prevState,
          {
            ...meeting,
            schedules: resp.data
          }
        ]);
      } catch (error) {
        console.info('Error fetching schedules:', error);
      }
    }
  }

  return (
    <ConfigProvider locale={ru_RU}>
      <div className="hr-client-layout mobile-booking-modal">
        {filterModal && (
          <div className="modal show" id="SettingsModal" role="dialog" style={{ display: 'block' }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <Filters
                    isFiltersDiabled={isFiltersDiabled}
                    isTypeModalShow={isTypeModalShow}
                    startDate={startDate}
                    activeFilterValues={activeFilterValues}
                    initialFiltersData={initialFiltersData}
                    onClick={onClick}
                    setDate={setDate}
                    closeTypeModalHandle={closeTypeModalHandle}
                  />
                  <button
                    onClick={() => {
                      resetFilters();
                      resetDate();
                    }}
                    className="reset-filter-link"
                  >
                    Сбросить фильтры
                  </button>
                </div>
                <div
                  className="modal-footer"
                  onClick={() => dispatch({ type: 'FILTER_MODAL', show: false })}
                >
                  Назад
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {
        <Space size={10} style={{ margin: 10 }}>
          <Button
            size="large"
            fill="outline"
            color="primary"
            className={[
              'd-flex align-items-center justify-content-center my-meetings-button',
              activeFilterValues.myMeetingsActive ? 'active' : ''
            ].join(' ')}
            onClick={(event) => {
              onClick(event, { myMeetingsActive: !activeFilterValues?.myMeetingsActive });
            }}
          >
            Мои встречи
          </Button>
          <Space>
            <DatePicker
              disabled={isFiltersDiabled}
              locale="ru"
              selected={startDate}
              onChange={(date) => setDate(date)}
              format="DD.MM.YYYY"
              style={{ width: '100%' }}
            />
          </Space>
          <Button
            size="large"
            fill="outline"
            icon={<SettingIcon />}
            onClick={() => {
              dispatch({ type: 'FILTER_MODAL', show: true });
            }}
          />
          <Button
            size="large"
            fill="outline"
            icon={<ClearFiltersIcon />}
            onClick={() => {
              resetFilters();
              resetDate();
            }}
          />
        </Space>
      }

      {isStillLoading ? (
        <div className="no-resuilts p-3 text-center warning-block">Загрузка данных...</div>
      ) : roomsAndSchedulesCombined.length > 0 ? (
        <section className="meetings-block d-flex flex-row">
          <>
            <div className="left-pane-booking">
              {roomsAndSchedulesCombined.map((meeting, index) => (
                <MeetingItemMobileLeftPane
                  meeting={meeting}
                  key={index}
                  startDate={startDate}
                  userEmail={userEmail}
                  myMeetingsActive={activeFilterValues.myMeetingsActive}
                  activeFilterValues={activeFilterValues}
                  setUserMeetingRoomsHandle={setUserMeetingRoomsHandle}
                />
              ))}
            </div>
            <div className="right-pane-booking">
              <div className="right-pane-booking-items-wrapper">
                <div className="vertical-bar-time vertical-bar-time-mobile"></div>

                {roomsAndSchedulesCombined.map((meeting, index) => (
                  <MeetingItemMobileRightPane
                    meeting={meeting}
                    key={index}
                    startDate={startDate}
                    userEmail={userEmail}
                    myMeetingsActive={activeFilterValues.myMeetingsActive}
                    activeFilterValues={activeFilterValues}
                    setUserMeetingRoomsHandle={setUserMeetingRoomsHandle}
                    onRenderMeetingItem={() => {
                      setTimeout(() => {
                        _timeLineScrollToHour();
                      }, 100);
                    }}
                  />
                ))}
              </div>
            </div>

            {roomsData.map((room) => room.display).every((item) => item === 'none') && (
              <div className="no-resuilts p-3 text-center warning-block">Результаты не найдены</div>
            )}
          </>
        </section>
      ) : (
        <div className="no-resuilts p-3 text-center warning-block">Результаты не найдены</div>
      )}
    </ConfigProvider>
  );
};
export default MobileBookings;
