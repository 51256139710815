import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { getResstrPayment } from '../api/greenApi';

import { formatDate, formatSpacesThousands } from '../lib/utils';
import { errorMessages } from '../../../messages';

import { ReactComponent as LeftArrowIcon } from '../assets/images/icons/leftArrowIcon.svg';
import { ReactComponent as DottedMenuIcon2 } from '../assets/images/icons/dottedMenuIcon2.svg';

import Loading from '../../../common/components/UI/Loading';
import ResstrProjects from '../components/resstr/ResstrProjects.jsx';
import ReestrPaymentDetailsModal from '../components/resstr/ReestrPaymentDetailsModal.jsx';

const ReestrPaymentItem = () => {
  const collapse = useSelector((state) => state.greenButton.collapse);

  const { external_id } = useParams();

  const [load, setLoad] = useState(false);
  const [ShowModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const [PaymentData, setPaymentData] = useState();

  const setModalShow = (data) => {
    setShowModal(data);
  };

  useEffect(() => {
    setLoad(true);
    getResstrPayment(external_id)
      .then((resp) => {
        if (!resp) {
          console.info('страница не найдена', { id: Math.random, position: 'top-right' });
          navigate('/inbox');
          return;
        }
        const PaymentData = resp.data;
        setPaymentData(PaymentData);
        setLoad(false);
      })
      .catch(() => {
        setLoad(false);
        return new Error(errorMessages.noResponse);
      });
  }, [external_id]);

  return (
    <>
      {load ? <Loading /> : ''}
      {ShowModal && (
        <ReestrPaymentDetailsModal
          collapse={collapse}
          setModalShow={setModalShow}
          fileUrl={PaymentData.urlfile}
        />
      )}

      {PaymentData && (
        <>
          <div className="top-content-block flex align-center space-between py-3 px-3">
            <div className="flex justify-center align-center">
              <span onClick={() => navigate(-1)} className="cursor-pointer">
                <LeftArrowIcon />
              </span>

              <div className="h5 active-page m-0">
                Реестр платежей {formatDate(PaymentData.date)}
              </div>
            </div>
            <div className="flex align-center justify-center">
              <span className="mx-0 px-2 cursor-pointer" onClick={() => setShowModal(true)}>
                <DottedMenuIcon2 />
              </span>
            </div>
          </div>

          <div className="container p-0 pt-3 data-container-bg y-scroll-hidden">
            <div className="h4 px-3 pt-2 pb-0 font-weight-500">
              {PaymentData.project ? PaymentData.project : '<Без проекта>'}
            </div>
            <ul className="px-0">
              <li>
                <ul className="table m-0 p-0 pb-0 title table-grid table_column_5">
                  <li className="noselect flex-1 text-center">Описание</li>
                  <li className="noselect flex-1 text-center">Вх.ост</li>
                  <li className="noselect flex-1 text-center">Поступл.</li>
                  <li className="noselect flex-1 text-center">Выплаты</li>
                  <li className="noselect flex-1 text-center">Исх.ост</li>
                </ul>
              </li>

              <li className="auto-overflow fixed-height hide-scroll-bar">
                <ul className="table px-0 payments">
                  {PaymentData.reestr_details &&
                    PaymentData.reestr_details.map((detailItem, index) => (
                      <li className="mb-1" key={index}>
                        <ul className="m-0 p-0 flex-nowrap align-items-center justify-content-between table-row position-relative overflow-hidden table-grid table_column_5">
                          <li className="flex-1 text-center cursor-pointer border_right position-relative">
                            <div className="default_link py-3 px-2 d-inline-block w-100">
                              {detailItem.description}
                            </div>
                          </li>
                          <li className="flex-1 text-center cursor-pointer border_right position-relative">
                            <div className="default_link  py-3 d-inline-block w-100">
                              {formatSpacesThousands(parseFloat(detailItem.input_balance))}
                            </div>
                          </li>
                          <li className="flex-1 text-center cursor-pointer border_right position-relative">
                            <div className="default_link py-3 d-inline-block w-100">
                              {formatSpacesThousands(parseFloat(detailItem.input_value))}
                            </div>
                          </li>
                          <li className="flex-1 text-center cursor-pointer border_right position-relative">
                            <div className="default_link  py-3 d-inline-block w-100">
                              {formatSpacesThousands(parseFloat(detailItem.output_value))}
                            </div>
                          </li>
                          <li className="flex-1 text-center cursor-pointer position-relative">
                            <div className="default_link py-3 d-inline-block w-100">
                              {formatSpacesThousands(parseFloat(detailItem.output_balance))}
                            </div>
                          </li>
                        </ul>
                      </li>
                    ))}
                </ul>

                <ResstrProjects
                  ExpenseGroups={PaymentData.expense_groups}
                  ReestrType={PaymentData.type}
                />
              </li>
            </ul>
          </div>
        </>
      )}
    </>
  );
};
export default ReestrPaymentItem;
