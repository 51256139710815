import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Space, List, Modal } from 'antd';

import { ReactComponent as InfoIcon } from '../assets/images/icons/material-symbols_info-rounded.svg';
import { ReactComponent as RoomsIcon } from '../assets/images/icons/material-symbols_stairs.svg';
import { ReactComponent as UsersIcon } from '../assets/images/icons/Users.svg';

import './MeetingItemMobile.scss'

const timesStyle = {
  width: '60%',
  textAlign: 'center'
};

const RoomElementStyle = {
  width: '40%',
  textAlign: 'left',
  minWidth: '195px',
  flexWrap: 'no-wrap'
};

export default function MeetingItem({
  meeting,
  startDate,
  userEmail,
  activeFilterValues,
  onRenderMeetingItem,
  setUserMeetingRoomsHandle
}) {
  const [activeMeetingItem, SetActiveMeetingItem] = useState({
    Show: false,
    MeetingItem: {},
    setShow: (data) => {
      SetActiveMeetingItem({ ...activeMeetingItem, Show: data, MeetingItem: {} });
    }
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const bookingDisabled = useSelector((state) => state.bookings.bookingDisabled);
  const dispatch = useDispatch();

  const addFifteenMinutes = (time) => {
    const d = new Date();
    const splittedTime = time.split(':');
    d.setHours(splittedTime[0], splittedTime[1]);
    d.setMinutes(d.getMinutes() + 15);
    return d.getHours() + ':' + d.getMinutes();
  };

  const scheduleModalHandle = (item) => {
    const Busytimes = meeting.schedules
      .filter((schedule) => schedule.is_busy === true)
      .map((time) => time.time);
    dispatch({
      type: 'SetScheduleModalData',
      scheduleModalData: {
        show: !bookingDisabled && true,
        startTime: item.time,
        endTime: addFifteenMinutes(item.time),
        room: meeting.email,
        schedules: meeting.schedules,
        Busytimes: Busytimes,
        Name: meeting.name,
        Description: meeting.description
      }
    });
  };

  const HandleActiveMeetingItem = (item) => {
    if (item) scheduleModalHandle(item);
  };

  const getCardStatus = (item) => {
    if (item.organizer && item.organizer.email.toLowerCase() === userEmail.toLowerCase())
      return 'book-by-me';
    return item.is_busy ? 'busy-card' : 'free-card';
  };

  const processMeetingItemTemplate = () => {
    const result = [];
    const timeStartNumber = parseInt(activeFilterValues.timeStart.split(':')[0]);
    const timeEndNumber = parseInt(activeFilterValues.timeEnd.split(':')[0]);
    meeting.schedules.forEach((item, index) => {
      let availableItem = null;
      if (item.time.split(':')[1] == '00') {
        let cardContent = [];
        availableItem = !item.is_busy ? item : null;
        for (let i = 0; i < 4; i++) {
          cardContent.push(<div className={getCardStatus(meeting.schedules[index + i])}></div>);
          if (!meeting.schedules[index + i].is_busy && availableItem == null)
            availableItem = meeting.schedules[index + i];
        }
        const itemTimeNumber = parseInt(item.time.split(':')[0]);
        if (itemTimeNumber >= timeStartNumber && itemTimeNumber < timeEndNumber)
          result.push(
            <Card
              className="mobile_bookings__main_list_item__card__time_item"
              key={item.time}
              onClick={() => HandleActiveMeetingItem(availableItem)}
            >
              <div className="card-time">{item.time}</div>
              <div className="mobile_bookings__time-grid">{cardContent}</div>
            </Card>
          );
      }
    });
    return result;
  };

  useEffect(() => {
    if (
      meeting.schedules
        .map((schedule) => {
          return schedule.is_busy
            ? schedule.required_attendees
                .map((user) => user.email.toLowerCase() === userEmail.toLowerCase())
                .indexOf(true) !== -1
            : false;
        })
        .indexOf(true) !== -1
    ) {
      setUserMeetingRoomsHandle(meeting.email);
    } else {
      console.info('no-meetings found');
    }

    onRenderMeetingItem();
  }, []);

  return (
    <React.Fragment>
      {meeting && (
        <List.Item className="mobile_bookings__main_list_item" style={{ display: meeting.display }}>
          <Card size={'small'} className="w-100" bordered={false}>
            <Card.Grid
              style={timesStyle}
              hoverable={false}
              className="mobile_bookings__main_list_item__card time_wrapper"
            >
              <Space size={2}>{processMeetingItemTemplate()}</Space>
            </Card.Grid>
          </Card>
        </List.Item>
      )}

      <Modal
        title={
          <Space align="center" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Space align="center" size={10}>
              <InfoIcon />
              {meeting.name}
            </Space>
            <Space align="center" size={10} style={{ marginRight: 20 }}>
              <Space align="center" size={2}>
                <RoomsIcon />
                {meeting.floor}
              </Space>
              <Space align="center" size={2}>
                <UsersIcon />
                {meeting.seats}
              </Space>
            </Space>
          </Space>
        }
        className="roomDetailsModal"
        open={isModalOpen}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
      >
        <p>{meeting.description}</p>
      </Modal>
    </React.Fragment>
  );
}
