const patchOldLocalStorageItems = () => {
  /* for 1.54.3 -> 1.54.4 */
  const old_pwa_actual_version = localStorage.getItem('pwa_actual_version');
  if (old_pwa_actual_version) {
    localStorage.removeItem('pwa_actual_version');
    localStorage.setItem('donotclear_pwa_last_actual_version', old_pwa_actual_version);
  }

  localStorage.removeItem('donotclear_random_string_for_fingerprint');

  const old_appTheme = localStorage.getItem('donotclear_app-theme');
  if (old_appTheme) {
    localStorage.removeItem('donotclear_app-theme');
    localStorage.setItem('donotclear_app_theme', old_appTheme);
  }

  const old_app_theme = localStorage.getItem('app-theme');
  if (old_app_theme) {
    localStorage.removeItem('app-theme');
    localStorage.setItem('donotclear_app-theme', old_app_theme);
  }

  const old_debug_username = localStorage.getItem('debug_username');
  if (old_debug_username) {
    localStorage.removeItem('debug_username');
    localStorage.setItem('donotclear_debug_username', old_debug_username);
  }

  const old_ShowAllCategories = localStorage.getItem('ShowAllCategories');
  if (old_ShowAllCategories) {
    localStorage.removeItem('ShowAllCategories');
    localStorage.setItem('donotclear_show_all_categories', old_ShowAllCategories);
  }

  localStorage.removeItem('everything_once_cleared');
  localStorage.removeItem('everything_once_cleared_1');
  localStorage.removeItem('everything_once_cleared_2');
  localStorage.removeItem('everything_once_cleared_3');
};

export default patchOldLocalStorageItems;
