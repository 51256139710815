export function formatSpacesThousands(val) {
  if (typeof val !== 'number') return;
  const _val = val;
  var num_parts = _val.toString().split('.');
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return num_parts.join('.');
}

export function roundToTwo(num) {
  return +(Math.round(num + 'e+2') + 'e-2');
}

export function minTwoDigits(n) {
  return (n < 10 ? '0' : '') + n;
}

export function addZeroes(numer) {
  let num = Number(numer);
  if (isNaN(num)) {
    return 0;
  }
  if (String(num).split('.').length < 2 || String(num).split('.')[1].length <= 2) {
    num = num.toFixed(2);
  }
  return num;
}

export function differenceInDays(date1, date2) {
  let differenceInDays = Math.ceil((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));
  return differenceInDays;
}

export function formatDate(d1) {
  const date = new Date(d1);
  const [month, day, year] = [
    minTwoDigits(date.getMonth() + 1),
    date.getDate(),
    date.getFullYear()
  ];
  return `${day}/${month}/${year}`;
}

export function getDayAddition(num) {
  let preLastDigit = (num % 100) / 10;
  if (preLastDigit == 1) {
    return `${num} дней`;
  }
  switch (num % 10) {
    case 1:
      return `${num} день`;
    case 2:
    case 3:
    case 4:
      return `${num} дня`;
    default:
      return `${num} дней`;
  }
}

export function numberWithSpaces(num) {
  return num
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    .replace('.', ',');
}

export function roundTONearestInteger(num) {
  return Math.round(num);
}

export function getFirstLetterFromString(string, words = 2) {
  return string
    .split(' ')
    .slice(0, words)
    .map((word) => word[0])
    .join('');
}
