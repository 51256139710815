import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { getAppVersion, keycloakSsoAuthEnabled } from '../api/commonApi';

import { hardLogout, softUpdate } from '../lib/auth';

import { getUserLocalData } from '../lib/handleAuthData';

import './UpdateVersionFoundModal.css';
import { logSentryError, logSentryMessage } from '../lib/utils';

export default function updateVersionFoundModal() {
  const [newVersionCode, setNewVersionCode] = useState(null);
  const { ssoUserKey, greenUserKey, hrUserKey } = getUserLocalData();

  const location = useLocation();

  const updateMinorVersion = () => {
    softUpdate();
  };

  const checkNewVersion = () => {
    getAppVersion()
      .then((data) => {
        const actualVersion = data?.data?.pwa_actual_version;

        const actualVersionSemantic = actualVersion.split('.');
        if (actualVersionSemantic.length !== 3) {
          logSentryError(`Backend returned latest app version in wrong format: ${actualVersion}`);
          return;
        }

        const actualMajorVer = Number(actualVersionSemantic[0]);
        const actualMinorVer = Number(actualVersionSemantic[1]);
        const actualPatchVer = Number(actualVersionSemantic[2]);

        let lastActualVersion = localStorage.getItem('donotclear_pwa_last_actual_version');
        let lastActualVersionSemantic = lastActualVersion?.split('.');

        if (lastActualVersionSemantic?.length !== 3) {
          /* This is the first time we are storing version, do not reload */
          logSentryMessage(
            `This is the first time we are storing version, do not reload ${actualVersion}`
          );
          localStorage.setItem('donotclear_pwa_last_actual_version', actualVersion);
          return;
        }

        const lastActualMajorVer = Number(lastActualVersionSemantic[0]);
        const lastActualMinorVer = Number(lastActualVersionSemantic[1]);
        const lastActualPatchVer = Number(lastActualVersionSemantic[2]);

        if (actualMajorVer > lastActualMajorVer) {
          logSentryMessage('Hard logout, because major version update available');
          localStorage.setItem('donotclear_pwa_last_actual_version', actualVersion);
          hardLogout();
          return;
        }

        if (actualMinorVer > lastActualMinorVer) {
          logSentryMessage('Soft Update, because minor version update available');
          localStorage.setItem('donotclear_pwa_last_actual_version', actualVersion);
          updateMinorVersion();
          return;
        }

        if (actualPatchVer > lastActualPatchVer) {
          logSentryMessage('new patch version available, reloading the page');
          localStorage.setItem('donotclear_pwa_last_actual_version', actualVersion);
          window.location.reload();
        }
      })
      .catch((error) => {
        logSentryError(`Не удалось получить данные my-info ${error}`);
      });
  };

  useEffect(() => {
    if (keycloakSsoAuthEnabled) {
      if (!ssoUserKey) return;
    } else {
      if (!greenUserKey && !hrUserKey) return;
    }

    checkNewVersion();
  }, [location]);

  return <></>;

  /* 
  return (
    <div className="updateVersion_found_modal position-fixed" id="updateVersion_found_modal">
      <div>
        <p>
          Хорошие новости!
          <br />
          <br />
          Мы только что обновили приложение до версии {newVersionCode}
          <br />
          Чтобы получить обновления, нажмите на кнопку ниже (страница перезагрузится).
          <br /> <br />
          После обновления будет необходимо повторно авторизоваться.
        </p>
        <button
          onClick={updateMinorVersion}
          id="updateVersion_found_modal_button"
          className="cursor-pointer"
        >
          Обновить
        </button>
      </div>
    </div>
  ); */
}
