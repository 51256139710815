import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { resumePushApi } from '../pushApi';
import { isWebPushDisabled, subscribePush } from '../main';

import { isPushPermissionGranted } from '../main';
import { askAgainToAllowPushIntervalHours } from '../../../../config';

import './AskingToAllowWebPushModal.css';
import { logSentryMessage } from '../../../lib/utils';
import { keycloakSsoAuthEnabled } from '../../../api/commonApi';
import { getUserLocalData } from '../../../lib/handleAuthData';

export default function AskingToAllowWebPushModal() {
  if (isPushPermissionGranted()) return;
  if (isWebPushDisabled()) return;

  const [showModal, setShowModal] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  const location = useLocation();

  useEffect(() => {
    onload();
  }, [location]);

  useEffect(() => {
    onload();
  }, []);

  const onload = () => {
    if (keycloakSsoAuthEnabled) {
      const ssoData = localStorage.getItem('sso_data');
      if (ssoData) {
        setUserLoggedIn(true);
        checkIntervalPassedSinceLastRejected();
      }
    } else {
      const { ssoUserKey, greenUserKey, hrUserKey } = getUserLocalData();
      if (ssoUserKey || greenUserKey || hrUserKey) {
        setUserLoggedIn(true);
        checkIntervalPassedSinceLastRejected();
      }
    }
  };

  const checkIntervalPassedSinceLastRejected = () => {
    const currentDate = new Date();
    const txtLastRejectedDate = localStorage.getItem('last_rejected_push_grant');
    const lastRejectedDate = new Date(txtLastRejectedDate);

    if (!txtLastRejectedDate) {
      setShowModal(true);
      return;
    }

    const timeDiff = currentDate.getTime() - lastRejectedDate.getTime();
    const hoursPassed = Math.floor(timeDiff / (1000 * 60 * 60));

    if (hoursPassed >= askAgainToAllowPushIntervalHours) {
      setShowModal(true);
    }
  };

  const rememberLastRejectedDate = () => {
    const currentDate = new Date();
    localStorage.setItem('last_rejected_push_grant', currentDate);
  };

  const allowAndsubscribePush = () => {
    logSentryMessage('user clicked allow in push notifications modal');

    setShowModal(false);
    rememberLastRejectedDate();

    resumePushApi().then((data) => {
      subscribePush();
    });
  };

  const handleReject = () => {
    logSentryMessage('user clicked not now in allow push notifications modal');
    rememberLastRejectedDate();
    setShowModal(false);
  };

  if (!userLoggedIn) return;
  if (!showModal) return;

  return (
    <div className="asking-to-allow-push-container">
      <img className="atap-logo" src="fsk.png" alt="fsk-logo" />
      <div className="atap-main">
        <p>Ничего нельзя пропустить, разрешите отправку Вам пуш-уведомлений</p>
        <div className="atap-buttons">
          <button className="atap-text-button" onClick={handleReject}>
            Не сейчас
          </button>
          <button className="atap-main-button" onClick={allowAndsubscribePush}>
            Разрешить
          </button>
        </div>
      </div>
      <div className="close-dark" onClick={() => setShowModal(false)}></div>
    </div>
  );
}
