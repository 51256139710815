import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import alert from '../../assets/images/icons/alertIcon.svg';

export default function Alert({ children }) {
  const dispatch = useDispatch();

  return (
    <div className="modal fade show" id="AlertModal" role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog m-0">
        <div className="modal-content">
          <div className="modal-body flex align-center justify-center flex-column">
            <img alt="Alert" src={alert} />
            <p className="mt-4 mb-5">{children ? children : 'Заявка уже была согласована ранее'}</p>

            <button
              onClick={() => {
                dispatch({ type: 'setWarningClose' });
              }}
              className="btn return mt-5"
            >
              Назад
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

Alert.propTypes = {
  children: PropTypes.string
};

Alert.defaultProps = {
  children: ''
};
