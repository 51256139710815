const initialState = {
  showLoader: false,
  date: new Date(),
  scheduleModalData: {
    show: false,
    startTime: '8:00',
    endTime: '',
    room: '',
    schedules: [],
    Busytimes: [],
    Name: '',
    Description: ''
  },
  userData: { mail: '' },
  refresh: false,
  bookingDisabled: false
};

export const bookingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ShowLoader':
      return { ...state, showLoader: true };
    case 'HideLoader':
      return { ...state, showLoader: false };
    case 'SetDate':
      return { ...state, date: action.date };
    case 'SetScheduleModalData':
      return { ...state, scheduleModalData: { ...action.scheduleModalData } };
    case 'RefreshMeetings':
      return { ...state, refresh: action };
    case 'SetUserMail':
      return { ...state, userData: { mail: action.mail } };
    default:
      return state;
  }
};
