import React, { useEffect, useState } from 'react';
import { Card, Space, List, Tooltip, Modal } from 'antd';

import { ReactComponent as InfoIcon } from '../assets/images/icons/material-symbols_info-rounded.svg';
import { ReactComponent as RoomsIcon } from '../assets/images/icons/material-symbols_stairs.svg';
import { ReactComponent as UsersIcon } from '../assets/images/icons/Users.svg';

import './MeetingItemMobile.scss'

const timesStyle = {
  width: '60%',
  textAlign: 'center'
};

const RoomElementStyle = {
  width: '40%',
  textAlign: 'left',
  minWidth: '195px',
  flexWrap: 'no-wrap'
};

export default function MeetingItem({ meeting, userEmail, setUserMeetingRoomsHandle }) {
  const [activeMeetingItem, SetActiveMeetingItem] = useState({
    Show: false,
    MeetingItem: {},
    setShow: (data) => {
      SetActiveMeetingItem({ ...activeMeetingItem, Show: data, MeetingItem: {} });
    }
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  useEffect(() => {
    if (
      meeting.schedules
        .map((schedule) => {
          return schedule.is_busy
            ? schedule.required_attendees
                .map((user) => user.email.toLowerCase() === userEmail.toLowerCase())
                .indexOf(true) !== -1
            : false;
        })
        .indexOf(true) !== -1
    ) {
      setUserMeetingRoomsHandle(meeting.email);
    } else {
      console.info('no-meetings found');
    }
  }, []);

  return (
    <React.Fragment>
      {meeting && (
        <List.Item className="mobile_bookings__main_list_item" style={{ display: meeting.display }}>
          <Card size={'small'} className="w-100" bordered={false}>
            <Card.Grid
              style={RoomElementStyle}
              hoverable={false}
              className="mobile_bookings__main_list_item__card"
            >
              <Space align="left" wrap>
                <Space align="center" size={2}>
                  {meeting.description && <InfoIcon onClick={showModal} />}
                  <Tooltip title="Sky gardenn" zIndex={1}>
                    <span className="mobile_bookings__main_list_item__card__room_name">
                      {meeting.name}
                    </span>
                  </Tooltip>
                </Space>
                <Space align="center" size={2}>
                  <RoomsIcon />
                  {meeting.floor}
                </Space>
                <Space align="center" size={2}>
                  <UsersIcon />
                  {meeting.seats}
                </Space>
              </Space>
            </Card.Grid>
          </Card>
        </List.Item>
      )}

      <Modal
        title={
          <Space align="center" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Space align="center" size={10}>
              <InfoIcon />
              {meeting.name}
            </Space>
            <Space align="center" size={10} style={{ marginRight: 20 }}>
              <Space align="center" size={2}>
                <RoomsIcon />
                {meeting.floor}
              </Space>
              <Space align="center" size={2}>
                <UsersIcon />
                {meeting.seats}
              </Space>
            </Space>
          </Space>
        }
        className="roomDetailsModal"
        open={isModalOpen}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
      >
        <p>{meeting.description}</p>
      </Modal>
    </React.Fragment>
  );
}
