import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Meetings from './Meetings';

import { ReactComponent as ArrowLeftIcon } from '../assets/images/icons/arrow-left-icon.svg';

export default function MeetingRoomsBookings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToMainPage = () => {
    dispatch({ type: 'GlOBAL_LOADER', show: false });
    navigate('/hr');
  };

  return (
    <>
      <div className="meetings__nav d-flex align-items-center">
        <ArrowLeftIcon onClick={goToMainPage} />
        <div className="main__nav__title ml-2">
          ГК ФСК<span>место силы</span>
        </div>
      </div>
      <Meetings />
    </>
  );
}
