import React, { useState } from 'react';
import { useEffect } from 'react';

import { MOBILE_WIDTH } from './../../consts';

import DesktopBookings from './DesktopBookings/DesktopBookings';
import MobileBookings from './MobileBookings/MobileBookings';

const GlobalBookings = () => {
  const [showDesktopVersion, setShowDesktopVersion] = useState();

  const checkPageWidth = () => {
    const pageWidth = document.querySelector('body').offsetWidth;
    if (pageWidth > MOBILE_WIDTH) {
      setShowDesktopVersion(true);
      return;
    }
    setShowDesktopVersion(false);
  };

  useEffect(() => {
    checkPageWidth();
    window.addEventListener('resize', checkPageWidth);
    return () => {
      window.removeEventListener('resize', checkPageWidth);
    };
  }, []);

  return <>{showDesktopVersion ? <DesktopBookings /> : <MobileBookings />}</>;
};
export default GlobalBookings;
