import UAParser from 'ua-parser-js';

import {
  getPushIsEnabledApi,
  getVapidKeyApi,
  sendDemoPushApi,
  subscribeApi,
  unsubscribeApi
} from './pushApi';
import { getBrowserFingerPrintHash } from './utils';
import { knopkaUserEmailKey, renewPushSubIntervalSeconds } from '../../../config';
import { logSentryError, logSentryMessage } from '../../lib/utils';
import isPwa from '../../lib/detectPwa';

export function isMobileOrTablet() {
  let parser = new UAParser(); // you need to pass the user-agent for nodejs
  let parserResults = parser.getResult();

  return ['mobile', 'tablet'].includes(parserResults.device.type);
}

export function getDeviceType() {
  const isMobile = isMobileOrTablet();
  const is_pwa = isPwa();
  const deviceType = is_pwa ? 'pwa' : isMobile ? 'mobile' : 'desktop';
  return deviceType;
}

export function persistIsWebPushDisabled() {
  const lcstKey = 'is_block_push_functionality';
  localStorage.setItem(lcstKey, true);

  try {
    if (Notification.permission) {
      if (isAllowedDeviceAndVersion()) {
        logSentryMessage('supported device and version, web push will be turned on');
        localStorage.setItem(lcstKey, false);
        return;
      } else {
        logSentryMessage('unsupported device or version, web push will be turned off');
      }
    }
  } catch (err) {
    logSentryError('It seems push notifications are not supported on this device: ' + err);
  }
}

export function isWebPushDisabled() {
  const lcstKey = 'is_block_push_functionality';

  if (localStorage.getItem(lcstKey) === 'true') {
    return true;
  }

  return false;
}

export function isAllowedDeviceAndVersion() {
  let parser = new UAParser();
  let parserResults = parser.getResult();
  const deviceVendor = parserResults.device.vendor;

  if (deviceVendor !== 'Apple') {
    return true;
  }

  const latestIosVer = '16.2';
  const latestIpadSafariVer = '16.4';
  const latestIpadOsVer = '16.4';
  const latestMacosVer = '10.15.7';

  /* ipad fakes its operating system and 
  does give away its ipad os version on safari browser entry,  
  but if someone opened app via chrome on ipad,
  there is no way determine ipad os ver except entry macos ver > 10.15.7
  */
  const latestIpadFakeMacOsVer = '10.15.8';

  const osName = parserResults?.os?.name;
  const osVer = parserResults?.os?.version;
  const browserVer = parserResults?.browser?.version;
  const browserName = parserResults?.browser?.name;
  const deviceModel = parserResults?.device?.model;

  if (deviceModel === 'iPhone' && osName === 'iOS' && osVer >= latestIosVer) {
    return true;
  }

  if (deviceModel === 'iPad') {
    if (browserName === 'Safari' && browserVer >= latestIpadSafariVer) {
      return true;
    }

    if (browserName === 'Chrome' && osVer >= latestIpadFakeMacOsVer) {
      return true;
    }
  }

  if (deviceModel === 'Macintosh' && osName === 'Mac OS' && osVer >= latestMacosVer) {
    return true;
  }

  return false;
}

export const pushNotificationsInit = () => {
  if (isWebPushDisabled()) return;

  checkAndLogPushSubPermissions();
};

function getPushManagerInstance() {
  if (!navigator.serviceWorker) {
    return new Promise((resolve, reject) => {
      reject('serviceWorker is not supported!');
    });
  }

  return navigator.serviceWorker?.ready.then((swRegistration) => {
    return swRegistration.pushManager;
  });
}

function getPushSubscriptionInstance() {
  return getPushManagerInstance().then((pushManagerInstance) => {
    return pushManagerInstance?.getSubscription()?.then((subscriptionInstance) => {
      return subscriptionInstance;
    });
  });
}

export function renewSub() {
  unsubscribeBrowserPush().then(() => {
    console.info('unsubsribed pushes and now subscribing again');
    subscribePush();
  });
}

export function checkDateAndRenewSub() {
  if (isWebPushDisabled()) {
    logSentryError(
      'could not renew push sub, because push notifications are not supported by device'
    );
    return;
  }

  if (!isPushPermissionGranted()) {
    logSentryError('could not renew push sub, because user not granted web push notifications yet');
    return;
  }

  getPushSubscriptionInstance().then((subscriptionInstance) => {
    if (!subscriptionInstance) {
      logSentryMessage(
        'user browser has no push subscription yet, so we are subscribing user to web push'
      );
      subscribePush();
    }

    if (subscriptionInstance) {
      const currentDate = new Date();
      const stringLastRenewedPushSubDate = localStorage.getItem('last_renewed_pushsub_date');

      if (!stringLastRenewedPushSubDate) {
        localStorage.setItem('last_renewed_pushsub_date', currentDate);
        logSentryMessage(
          'user browser has a subscription, but no last_renewed_pushsub_date entry, so we are renewing push subscribtion'
        );
        renewSub();
        return;
      }

      const lastRenewedPushSubDate = new Date(stringLastRenewedPushSubDate);

      const timeDiff = currentDate.getTime() - lastRenewedPushSubDate.getTime();
      const secondsPassed = Math.floor(timeDiff / 1000);

      if (secondsPassed >= renewPushSubIntervalSeconds) {
        logSentryMessage('user browser subscription expired, so we are renewing push subscribtion');
        renewSub();
      }
    }
  });
}

function checkAndLogPushSubPermissions() {
  getPushIsEnabledApi().then((data) => {
    const hash = getBrowserFingerPrintHash();

    if (!data?.data?.notification_enabled) {
      logSentryMessage('backend said notification_enabled == false' + hash);
    }

    if (!isPushPermissionGranted()) {
      logSentryMessage('push notifications not granted yet to browser or pwa ' + hash);
    }
  });
}

export const isPushPermissionGranted = () => {
  if (isWebPushDisabled()) {
    return false;
  } else {
    try {
      if (Notification.permission === 'granted') {
        return true;
      }
    } catch (err) {
      return false;
    }
  }

  return false;
};

export function subscribePush() {
  if (isWebPushDisabled()) return;

  getVapidKeyApi().then((vapidKey) => {
    getPushManagerInstance().then((pushManagerInstance) => {
      if (!isPushPermissionGranted()) {
        console.info('user has not granted pushes yet');
      }

      /* at this point shows the push confirmation */
      const subscriptionInstance = pushManagerInstance.subscribe({
        userVisibleOnly: true,
        applicationServerKey: vapidKey,
        expirationTime: Date.now() / 1000 + 86400
      });

      subscriptionInstance
        .then((subscriptionData) => {
          const sub = JSON.parse(JSON.stringify(subscriptionData));
          const browser_id = getBrowserFingerPrintHash();
          const sub_data = {
            browser_id: `${browser_id}`,
            device_type: getDeviceType(),
            push_data: {
              endpoint: sub?.endpoint,
              p256dh: sub?.keys?.p256dh,
              auth: sub?.keys?.auth
            }
          };

          subscribeApi(sub_data).then((_data) => {
            if (_data?.status === 200) {
              const currentDate = new Date();
              localStorage.setItem('last_renewed_pushsub_date', currentDate);
              logSentryMessage('user subscribed to push messages');
            } else {
              logSentryError('user could not subscribe to push notifications1');
            }
          });
        })
        .catch((err) => {
          logSentryError(`user could not subscribe to push notifications2 ${err}`);
        });
    });
  });
}

function unsubscribeBrowserPush() {
  return getPushSubscriptionInstance().then((subscriptionInstance) => {
    if (!subscriptionInstance) {
      return true;
    }
    return subscriptionInstance?.unsubscribe();
  });
}

export const sendDemoPush = () => {
  getPushSubscriptionInstance().then(() => {
    sendDemoPushApi();
  });
};

export const unsubscribePush = async () => {
  unsubscribeBrowserPush();
  const email = localStorage.getItem(knopkaUserEmailKey);
  const browser_id = getBrowserFingerPrintHash();

  return await unsubscribeApi({ email: email, browser_id: browser_id });
};
