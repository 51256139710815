import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hardLogout } from '../../../../common/lib/auth';
import { useTheme } from '../../../../common/hooks/useTheme';
import { logSentryMessage } from '../../../../common/lib/utils';

export default function ThemeModal({ setShowModalCategoriesSetup }) {
  const dispatch = useDispatch();
  const showTheme = useSelector((state) => state.greenButton.showTheme);
  const showAllCategories = useSelector((state) => state.greenButton.showAllCategories);
  const { theme, setTheme } = useTheme();

  const black = () => {
    setTheme('dark');
    dispatch({ type: 'closeTheme' });
  };

  const white = () => {
    setTheme('light');
    dispatch({ type: 'closeTheme' });
  };

  const setvisibilityOfCategories = () => {
    dispatch({ type: 'setShowAllCategories' });
    dispatch({ type: 'closeTheme' });
  };

  const switcherCls = `switcher ml-0 mr-3 ${showAllCategories ? 'on' : 'off'}`;

  return (
    <div className="modal fade show" id="LoadingModal" role="dialog" style={{ display: showTheme }}>
      <div className="modal-dialog m-0">
        <div className="modal-content">
          <div className="modal-body flex align-center justify-end flex-column p-0">
            <ul className="m-0 p-0 modal-menu">
              <li className={theme === 'light' ? 'active' : ''}>
                <span onClick={() => white()} className="cursor-pointer modal-actions">
                  <i className=" mr-3"></i>Светлая тема
                </span>
              </li>
              <li className={theme === 'dark' ? 'active' : ''}>
                <span onClick={() => black()} className="cursor-pointer modal-actions">
                  <i className="theme_icon mr-3 dark"></i>Темная тема
                </span>
              </li>
              <li>
                <div onClick={() => setvisibilityOfCategories()} className="cursor-pointer">
                  <div className={switcherCls}>
                    <span className="slider"></span>
                    <input type="checkbox" checked="checked" onChange={() => {}} />
                  </div>
                  Не визуализировать категории без заявок
                </div>
              </li>
              <li className="cursor-pointer">
                <span
                  onClick={() => {
                    setShowModalCategoriesSetup(true);
                  }}
                  className="cursor-pointer modal-actions"
                >
                  <i className="eye_icon mr-3"></i>Настройка категорий
                </span>
              </li>
              <li>
                <div>
                  <span
                    data-testid="logoutButton"
                    className="cursor-pointer w-100 text-center"
                    onClick={() => {
                      logSentryMessage('Logout because logout clicked 2');
                      hardLogout();
                    }}
                  >
                    Выйти
                  </span>
                </div>
              </li>
            </ul>
            <span
              onClick={() => dispatch({ type: 'closeTheme' })}
              className="cancel btn mt-4 flex justify-center align-center w-100"
            >
              Отмена
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
