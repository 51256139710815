import React, { useEffect, useState, useCallback } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useSelector, useDispatch } from 'react-redux';

import navigationService from '../../../../navigationService';

import { getNotificationsData } from '../../api/hrApi';

import {
  sendDemoPush,
  isPushPermissionGranted,
  isWebPushDisabled
} from '../../../../common/plugins/pushNotifications/main.js';
import {
  getPushIsEnabledApi,
  pausePushApi,
  resumePushApi
} from '../../../../common/plugins/pushNotifications/pushApi';

import { useTheme } from '../../../../common/hooks/useTheme';

import { subscribePush } from '../../../../common/plugins/pushNotifications/main';
import { hardLogout } from '../../../../common/lib/auth';

import { ReactComponent as ThemeIcon } from '../../assets/images/icons/theme_light.svg';
import { ReactComponent as BellIcon } from '../../assets/images/icons/bell.svg';
import { ReactComponent as TelegramIcon } from '../../assets/images/icons/telegram.svg';
import { ReactComponent as EditIcon } from '../../assets/images/icon/mailingIcons/edit-icon.svg';

import './SettingsModal.scss';
import { logSentryMessage } from '../../../../common/lib/utils';

export default function SettingsModal() {
  const dispatch = useDispatch();
  const { theme, setTheme } = useTheme();
  const [notificaitonSettings, setNotificaitonSettings] = useState(false);
  const [pushIsEnabled, setPushIsEnabled] = useState(false);
  const [showDemoPushButton, setShowDemoPushButton] = useState(false);
  const [notificaitonSettingsData, setNotificaitonSettingsData] = useState({});

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const handleLightThemeClick = () => {
    setTheme('light');
    closeModal();
  };
  const handleDarkThemeClick = () => {
    setTheme('dark');
    closeModal();
  };
  const toggleSettingsModal = () => {
    setNotificaitonSettings(!notificaitonSettings);
  };
  const closeModal = () => {
    dispatch({ type: 'SETTINGS_MODAL', show: false });
    setNotificaitonSettings(false);
  };

  const togglePushIsEnabled = () => {
    if (navigator?.serviceWorker?.controller) {
      subscribePush();
    }

    if (pushIsEnabled) {
      pausePushApi().then((data) => {
        setPushIsEnabled(false);
      });
    } else {
      resumePushApi().then((data) => {
        setPushIsEnabled(true);
      });
    }
  };

  const handleChange = (event) => {
    setNotificaitonSettingsData({
      ...notificaitonSettingsData,
      [event.target.name]:
        event.target.type == 'checkbox' ? event.target.checked : event.target.value
    });
  };

  const settingsModal = useSelector((state) => state.settingsModal.show);

  const onLoad = () => {
    if (localStorage.getItem('donotclear_show_demo_push_button') === 'true') {
      setShowDemoPushButton(true);
    }

    if (process.env.REACT_APP_SHOW_DEMO_PUSH_BUTTON === 'true') {
      setShowDemoPushButton(true);
    }

    getPushIsEnabledApi().then((pushResp) => {
      const isEnabled = pushResp.data.notification_enabled;
      setPushIsEnabled(isEnabled);
    });

    getNotificationsData().then((resp) => {
      let data = resp.data;
      setNotificaitonSettingsData(data);
    });
  };

  const _subscribePush = () => {
    resumePushApi().then((data) => {
      setPushIsEnabled(true);
      subscribePush();
      setTimeout(() => {
        forceUpdate();
      }, 2500);
    });
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    settingsModal && (
      <div className="modal show" id="SettingsModal" role="dialog" style={{ display: 'block' }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              {!notificaitonSettings && (
                <ul>
                  <li onClick={() => toggleSettingsModal()}>
                    <BellIcon className="icon bell-icon" />
                    Настройка уведомлений
                  </li>
                  <li
                    onClick={() => {
                      navigationService.navigate('/mobile-app');
                    }}
                  >
                    <EditIcon className="icon edit-icon" />
                    Отпуска и замещения
                  </li>
                  <li onClick={() => handleLightThemeClick()}>
                    <ThemeIcon className="icon" />
                    Светлая тема
                  </li>
                  <li onClick={() => handleDarkThemeClick()}>
                    <ThemeIcon className="icon dark" />
                    Темная тема
                  </li>
                  <li>
                    <span
                      data-testid="logoutButton"
                      className="cursor-pointer w-100 text-center"
                      onClick={() => {
                        closeModal();
                        logSentryMessage('Logout because logout clicked 1 ');
                        hardLogout();
                      }}
                    >
                      Выйти
                    </span>
                  </li>
                </ul>
              )}
              {notificaitonSettings && (
                <div className="notifications-form">
                  <p>Настройка уведомлений</p>
                  <div className="font-size-0-875">
                    {ReactHtmlParser(notificaitonSettingsData.telegram_help_text_1)}
                  </div>

                  <section className="is-hidden">
                    <div>
                      <label className="modal-titile mr-4 mt-2">
                        <input
                          type="checkbox"
                          name="telegram_is_active"
                          disabled
                          checked={notificaitonSettingsData.telegram_is_active}
                          onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>

                    <div className="position-relative flex-1">
                      <TelegramIcon className="input-icon-left" />
                      <input
                        type="text"
                        name="telegram_value"
                        placeholder="Ваш ник в Telegram"
                        className="telegram-input"
                        disabled
                        value={notificaitonSettingsData.telegram_value}
                        onChange={handleChange}
                      />
                      <div className="font-size-0-875">
                        {ReactHtmlParser(notificaitonSettingsData.telegram_help_text_2)}
                      </div>
                    </div>
                  </section>

                  <section>
                    {!isWebPushDisabled() && isPushPermissionGranted() ? (
                      <div>
                        <label className="modal-titile mr-4 mt-2">
                          <input
                            type="checkbox"
                            name="notification_enabled"
                            checked={pushIsEnabled}
                            onChange={togglePushIsEnabled}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    ) : null}

                    <div className="position-relative flex-1">
                      {!isWebPushDisabled() && isPushPermissionGranted() ? (
                        <div>
                          <span className="mr-5">Включить push уведомления</span>

                          {showDemoPushButton ? (
                            <span
                              className="cursor-pointer push-small-button"
                              onClick={() => sendDemoPush()}
                            >
                              Test Push
                            </span>
                          ) : null}
                        </div>
                      ) : null}

                      {navigator?.serviceWorker && 'controller' in navigator.serviceWorker ? (
                        <>
                          {!isWebPushDisabled() && !isPushPermissionGranted() ? (
                            <>
                              <span className="color-red font-size-0-5 mr-3">
                                Пуш-уведомления не разрешены пользователем
                              </span>
                              <span
                                className="cursor-pointer push-small-button"
                                onClick={_subscribePush}
                              >
                                Разрешить Пуш-уведомления
                              </span>
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </div>
                  </section>

                  <div className="mb-5"></div>
                </div>
              )}
            </div>

            {notificaitonSettings ? (
              <div className="modal-footer" onClick={() => toggleSettingsModal()}>
                Назад
              </div>
            ) : null}

            {!notificaitonSettings ? (
              <div className="modal-footer" onClick={() => closeModal()}>
                Закрыть
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  );
}
