import React from 'react';

import { formatSpacesThousands } from '../../lib/utils';

const ResstrProjectDetailItem = ({ Payment }) => {
  return (
    <ul className="table px-0 m-0">
      <li className="mb-1">
        <ul className="m-0 p-0 flex-nowrap align-items-center justify-content-between table-row position-relative overflow-hidden table-grid table_column_5">
          <li className="flex-1 text-center border_right position-relative">
            <div className="default_link py-2 d-inline-block px-2">
              {Payment.project ? Payment.project : '-'}
            </div>
          </li>
          <li className="flex-1 text-center border_right position-relative">
            <div className="default_link py-2 d-inline-block px-2">{Payment.payment_receiver}</div>
          </li>
          <li className="flex-2 text-center border_right position-relative">
            <div className="default_link py-2 d-inline-block px-2">{Payment.expense}</div>
          </li>
          <li className="flex-2 text-center border_right position-relative">
            <div className="default_link py-2 d-inline-block">
              {formatSpacesThousands(parseFloat(Payment.amount))}
            </div>
          </li>
          <li className="flex-2 text-center border_right position-relative">
            <div className="default_link py-2 d-inline-block">
              {formatSpacesThousands(parseFloat(Payment.prepaid))}
            </div>
          </li>
        </ul>
        {Payment.comment && (
          <div className="comment py-3 text-center">
            <span className="color-orrange">Комментарий:</span> {Payment.comment}
          </div>
        )}
      </li>
    </ul>
  );
};
export default ResstrProjectDetailItem;
