import React from 'react';
import { useDispatch } from 'react-redux';

const ReestrPaymentDetailsModal = ({ collapse, setModalShow, fileUrl }) => {
  const dispatch = useDispatch();
  const setShowModal = (data) => {
    setModalShow(data);
  };
  const SetAction = (action) => {
    if (action === 'download_all') {
      window.open(fileUrl, '_blank');
    } else {
      dispatch({ type: 'toggleCollaps' });
      setModalShow(false);
    }
  };

  return (
    <div className="modal fade show" id="LoadingModal" role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog m-0">
        <div className="modal-content">
          <div className="modal-body flex align-center justify-end flex-column">
            <ul className="m-0 p-0 modal-menu">
              <li className="list">
                <span
                  onClick={() => SetAction('collapse')}
                  className="cursor-pointer modal-actions"
                >
                  <i className="expanded_icon mr-3"></i>
                  {collapse ? 'Развернуть все' : 'Свернуть все'}
                </span>
              </li>
              <li className="list">
                <span
                  onClick={() => SetAction('download_all')}
                  className="cursor-pointer modal-actions"
                >
                  <i className="download-pdf_icon mr-3"></i>Скачать пдф
                </span>
              </li>
            </ul>
            <span
              onClick={() => setShowModal(false)}
              className="cancel btn mt-4 flex justify-center align-center w-100"
            >
              Отмена
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReestrPaymentDetailsModal;
