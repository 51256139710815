import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getCommentsByID } from '../../api/hrApi';

import CommentsList from './CommentsList.jsx';
import AddComment from './AddComment.jsx';

import './Comments.scss';

const Comments = ({ Article, setCommentsCount }) => {
  const dispatch = useDispatch();
  const [comments, setComments] = useState();

  useEffect(() => {
    dispatch({ type: 'GlOBAL_LOADER', show: true });
    getCommentsByID(Article.id)
      .then(({ data }) => {
        setComments(data);
      })
      .catch((error) => console.info(error))
      .finally(() => dispatch({ type: 'GlOBAL_LOADER', show: false }));
  }, []);

  const SetNewCommentsData = (data) => {
    setComments([...comments, data]);
    setCommentsCount(Article.comment_count + 1);
  };

  return (
    <React.Fragment>
      <div className="comments">
        <CommentsList comments={comments} />
        <AddComment articleID={Article.id} newCommentsData={SetNewCommentsData} />
      </div>
    </React.Fragment>
  );
};
export default Comments;
