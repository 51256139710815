import React, { useState } from 'react';

import { SetLike } from '../../api/hrApi';

import { ReactComponent as Like } from '../../assets/images/icons/Like.svg';

const Likes = ({ article, newArticleData }) => {
  const [Clicked, setCLicked] = useState(false);
  // const dispatch = useDispatch();

  const setClick = () => {
    // dispatch({ type: "GlOBAL_LOADER", show: true })
    let LikesCount = article.like_count;
    SetLike(article.id)
      .then(({ data }) => {
        if (data.like) {
          setCLicked(true);
          let timer = setTimeout(() => {
            setCLicked(false);
            clearTimeout(timer);
          }, 1000);
        }

        let ChangedData = {
          ...article,
          is_like: data.like,
          like_count: data.like ? LikesCount + 1 : LikesCount - 1
        };
        newArticleData(ChangedData);
      })
      .catch((error) => console.info(error));
    // .finally(() =>
    //   // dispatch({ type: "GlOBAL_LOADER", show: false })
    // );
  };

  return (
    <React.Fragment>
      <Like
        className={[article && article.is_like ? 'active' : '', Clicked && 'cliked'].join(' ')}
        onClick={(e) => setClick()}
      />
      {article && article.like_count}
    </React.Fragment>
  );
};
export default Likes;
