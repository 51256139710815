export function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
}

export const generateRandomString = (length = 8) => {
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  return Array.from({ length: length }, () =>
    characters.charAt(Math.floor(Math.random() * characters.length))
  ).join('');
};

export const generate9randomDigits = () => {
  var minm = 100000000;
  var maxm = 999999999;
  return Math.floor(Math.random() * (maxm - minm + 1)) + minm;
};

export const getBrowserFingerPrintHash = () => {
  const prevRandomHashForFingerprint = localStorage.getItem(
    'donotclear_random_hash_for_fingerprint'
  );
  if (prevRandomHashForFingerprint) return prevRandomHashForFingerprint;

  const newRandomHashForFingerprint = generate9randomDigits();
  localStorage.setItem('donotclear_random_hash_for_fingerprint', newRandomHashForFingerprint);
  return newRandomHashForFingerprint;
};

export const getBrowserFingerPrintHash_deprecated = () => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  ctx.fillStyle = 'rgb(255,0,255)';
  ctx.beginPath();
  ctx.rect(20, 20, 150, 100);
  ctx.fill();
  ctx.stroke();
  ctx.closePath();
  ctx.beginPath();
  ctx.fillStyle = 'rgb(0,255,255)';
  ctx.arc(50, 50, 50, 0, Math.PI * 2, true);
  ctx.fill();
  ctx.stroke();
  ctx.closePath();

  const txt = 'abz190#$%^@£éú';
  ctx.textBaseline = 'top';
  ctx.font = '17px "Arial 17"';
  ctx.textBaseline = 'alphabetic';
  ctx.fillStyle = 'rgb(255,5,5)';
  ctx.rotate(0.03);
  ctx.fillText(txt, 4, 17);
  ctx.fillStyle = 'rgb(155,255,5)';
  ctx.shadowBlur = 8;
  ctx.shadowColor = 'red';
  ctx.fillRect(20, 12, 100, 5);

  // hashing function
  const src = canvas.toDataURL();
  let hash = 0;

  for (let i = 0; i < src.length; i++) {
    const char = src.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  return hash;
};
