import React from 'react';

import InboxPage from '../../apps/green_button/pages/InboxPage.jsx';
import TasksPage from '../../apps/green_button/pages/TasksPage.jsx';
import ItemTaskPage from '../../apps/green_button/pages/ItemTaskPage.jsx';
import UserListPage from '../../apps/green_button/pages/UserListPage.jsx';
import ReestrPaymentsPage from '../../apps/green_button/pages/ReestrPaymentsPage.jsx';
import ReestrPaymentItemPage from '../../apps/green_button/pages/ReestrPaymentItemPage.jsx';

const GreenButtonRoutes = [
  {
    path: '/inbox',
    element: <InboxPage />
  },
  {
    path: '/inbox/tasks/:code/:api',
    element: <TasksPage />
  },
  {
    path: '/inbox/tasks/:code/:api/:id',
    element: <ItemTaskPage />
  },
  {
    path: '/inbox/tasks/:code/:api/user_list/:action',
    element: <UserListPage />
  },
  {
    path: '/inbox/reestr/',
    element: <ReestrPaymentsPage />
  },
  {
    path: '/inbox/reestr/:external_id',
    element: <ReestrPaymentItemPage />
  }
];
export default GreenButtonRoutes;
