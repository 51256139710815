import React from 'react';
import { Routes, Route } from 'react-router-dom';

import RootLayout from '../RootLayout.jsx';
import Layouts from './Layouts.jsx';

import LoginPage from '../common/pages/LoginPage.jsx';
import NotFoundPage from '../common/pages/NotFoundPage.jsx';

import MobileAppPage from '../apps/mobile_app/mobileAppPage.jsx';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<RootLayout />}>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/mobile-app" element={<MobileAppPage />} />
        {Layouts.map((layout, id) => (
          <Route path={layout.path} element={layout.element} key={id}>
            {layout?.subElements.map((item, idx) => (
              <Route path={item.path} element={item.element} key={idx} />
            ))}
          </Route>
        ))}

        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
