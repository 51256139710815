import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import { getUsersByFilters } from '../../api/hrApi';
import { getFullName } from '../../lib/mixing';

import Navigation from '../../components/Navigation/Navigation';

const Phonebook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchValue = useSelector((state) => state.search.value);

  const [items, setItems] = useState([]);
  const [hasMore, setHasmore] = useState(true);
  const [noResults, setNoResults] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  let [actvateSearch, setActiveSearch] = useState(false);

  const height = document.body.clientHeight - 150;

  const fetchMoreData = () => {
    setNoResults(false);

    getUsersByFilters(page, searchValue)
      .then(({ data }) => {
        page === 1 && setCount(data.count);
        setItems(items.length > 0 ? [...items, ...data.results] : [...data.results]);
        if (data.count === 0) {
          setPage(1);
          setHasmore(false);
          setNoResults(true);
        } else {
          setPage(page + 1);
        }
      })
      .catch((error) => console.info(error))
      .finally(() => dispatch({ type: 'GlOBAL_LOADER', show: false }));
  };

  useEffect(() => {
    fetchMoreData();
  }, []);

  useEffect(() => {
    if (items.length > 0) {
      if (items.length >= count || count === 0) {
        setHasmore(false);
      }
    }
    if (items.length === 0 && count !== 0 && actvateSearch) {
      fetchMoreData();
    }
  }, [items]);

  const onClick = (item) => {
    dispatch({ type: 'SET_PHONEBOOK_USER', user: item });
    navigate(location.pathname + `/${item.id}`);
  };

  useEffect(() => {
    if (actvateSearch) {
      setPage(1);
      setItems([]);
      setHasmore(true);
      if (count === 0 && page === 1) {
        fetchMoreData();
      }
    }
    setActiveSearch(true);
  }, [searchValue]);

  return (
    <div className="phonebook">
      <Navigation
        blockName="phonebook"
        navTitle="Поиск по сотрудникам"
        path="/hr/"
        SearchField={true}
      />

      <div className="phonebook__block">
        <div className="phonebook__block__content">
          <div className="phonebook__block__title">Поиск по сотрудникам</div>
          <div className="phonebook__block__info">
            {items && (
              <InfiniteScroll
                dataLength={items.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<div className="loading">Загружается...</div>}
                height={height}
                endMessage={
                  <p style={{ textAlign: 'center', fontWeight: 500 }}>
                    {noResults ? 'По вашему запросу ничего не найдено' : ''}
                  </p>
                }
              >
                {items.map((item, index) => (
                  <div
                    className="phonebook__block__info-item-wrapper"
                    key={index}
                    onClick={(e) => onClick(item)}
                  >
                    <h3>{getFullName(item)}</h3>
                    <h4>{`${item.position ? item.position + ',' : ''} ${
                      item.department ? item.department : ''
                    }`}</h4>
                  </div>
                ))}
              </InfiniteScroll>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Phonebook;
