import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { getSpecialNews } from '../../api/hrApi';

import { formatDateWithTime } from '../../lib/mixing';

import fskLogo from '../../../../common/assets/images/logo_fsk.png';
import { ReactComponent as DotsIcon } from '../../assets/images/icon/dots-icon.svg';

import NewsNotification from '../NewsNotification/NewsNotification.jsx';

import './New.scss';

const settings = {
  infinite: true,
  dots: true,
  slidesToShow: 1,
  arrows: false,
  slidesToScroll: 1,
  lazyLoad: false
};

const News = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [news, setNews] = useState();
  const [birthDaysData, setBirthDaysData] = useState();

  useEffect(() => {
    dispatch({ type: 'GlOBAL_LOADER', show: true });
    getSpecialNews()
      .then((resp) => {
        const allNews = resp.data;
        const news = allNews.filter((newsItem) => newsItem.id !== 0);
        const newNews = news.map((news) => {
          const regex = /(<(?!\/?a(?=>|\s.*>))\/?.*?>)/gi;
          let body = news.body.replace(regex, '');
          body = body.length > 250 ? body.substring(0, 250) + '...' : body;
          return { ...news, body: body };
        });
        setNews(newNews);
        const birthdayData = allNews.filter((newsItem) => newsItem.id === 0);
        const birthdays = [];
        birthdayData.forEach((element, index) => {
          if (index % 2 === 0) {
            const birth = birthdayData.slice(index, index + 2);
            birthdays.push(birth);
          }
        });
        setBirthDaysData(birthdays);
      })
      .catch((error) => console.info(error))
      .finally(() => dispatch({ type: 'GlOBAL_LOADER', show: false }));
  }, []);

  return (
    <Slider {...settings}>
      {news &&
        news.slice(0, 1).map((newItem) => (
          <div
            className="news"
            key={newItem.id}
            onClick={(e) => navigate(`/hr/news/${newItem.id}/`)}
          >
            <div className="news__head">
              <div className="news__user">
                <div className="news__user-wrapper">
                  <img src={fskLogo} alt="avatar" />
                  <div className="news__user-info">
                    <h3 className="news__user-position">{newItem.signature.name}</h3>
                    <span className="news__user-status">{formatDateWithTime(newItem.date)}</span>
                  </div>
                </div>
              </div>
              <DotsIcon />
            </div>
            <div className="news__info">{ReactHtmlParser(newItem.body)}</div>
          </div>
        ))}

      {birthDaysData &&
        birthDaysData.map((birthDaysDataItem, index) => (
          <NewsNotification birthDaysData={birthDaysDataItem} key={index} />
        ))}

      {news &&
        news.slice(1, news.length).map((newItem) => (
          <div
            className="news"
            key={newItem.id}
            onClick={(e) => navigate(`/hr/news/${newItem.id}/`)}
          >
            <div className="news__head">
              <div className="news__user">
                <div className="news__user-wrapper">
                  <img src={fskLogo} alt="avatar" />
                  <div className="news__user-info">
                    <h3 className="news__user-position">{newItem.signature.name}</h3>
                    <span className="news__user-status">{formatDateWithTime(newItem.date)}</span>
                  </div>
                </div>
              </div>
              <DotsIcon />
            </div>
            <div className="news__info">{ReactHtmlParser(newItem.body)}</div>
          </div>
        ))}
    </Slider>
  );
};

export default News;
