import * as DeviceData from 'react-device-detect';

import { greenApiFullUrl } from '../../../common/api/commonApi';
import axiosAuth from '../../../common/api/axiosAuth';
import { knopkaUserEmailKey } from '../../../config';

const deviceData = {
  deviceType: DeviceData.deviceType,
  osName: DeviceData.osName,
  osVersion: DeviceData.osVersion,
  browserName: DeviceData.browserName,
  isMobile: DeviceData.isMobile,
  isTablet: DeviceData.isTablet
};

const API_FULL_URL = greenApiFullUrl;

export const getScenarios = () =>
  axiosAuth({ url: `${API_FULL_URL}/scenarios/`, method: 'get' }, 'green');

export const getCategory = (autoLogout = true) => {
  return axiosAuth({ url: `${API_FULL_URL}/category/`, method: 'get' }, 'green', autoLogout);
};

export const getTasksByCategory = (code, search, ordering) =>
  axiosAuth(
    {
      url: `${API_FULL_URL}/tasks/${code}/?search=${search}&ordering=${ordering}`,
      method: 'get'
    },
    'green'
  );
export const getTaskById = (id) =>
  axiosAuth({ url: `${API_FULL_URL}/task/${id}/`, method: 'get' }, 'green');
export const getAllUsers = (search = '', ordering = '') =>
  axiosAuth(
    {
      url: `${API_FULL_URL}/user/?search=${search}&ordering=${ordering}`,
      method: 'get'
    },
    'green'
  );

export const postScenarioAction = (
  task,
  action,
  comment = '',
  toEmail = '',
  date = '',
  checkbox_data = {}
) => {
  const userEmail = localStorage.getItem(knopkaUserEmailKey);

  const savingData = {
    task: task,
    mail: userEmail,
    scenario: action,
    comment: comment,
    to_who: toEmail,
    is_mobile: deviceData.isMobile,
    is_tablet: deviceData.isTablet,
    os_name: deviceData.osName
  };

  if (checkbox_data?.is_checkbox) {
    savingData.checkbox = checkbox_data?.checkbox_value === true ? 1 : 0;
  }

  if (date !== '') savingData.date = date;
  return axiosAuth(
    {
      url: `${API_FULL_URL}/task/action/`,
      method: 'post',
      data: savingData
    },
    'green'
  );
};

// reester_api
export const getResstrPayments = (ordering) =>
  axiosAuth({ url: `${API_FULL_URL}/reestr/`, method: 'get', params: ordering }, 'green');

export const getResstrPayment = (params) =>
  axiosAuth({ url: `${API_FULL_URL}/reestr/${params}/`, method: 'get' }, 'green');

export const setResstrAction = (reestr, action, comment = '') =>
  axiosAuth(
    {
      url: `${API_FULL_URL}/reestr/action/`,
      method: 'post',
      data: {
        reestr: reestr,
        type: action,
        comment: action === 'reject' ? comment : '',
        is_mobile: deviceData.isMobile,
        is_tablet: deviceData.isTablet,
        os_name: deviceData.osName
      }
    },
    'green'
  );

export const getGreenMyInfo = (autoLogout = true) => {
  return axiosAuth({ url: `${API_FULL_URL}/my-info/`, method: 'post' }, 'green', autoLogout);
};

export const cancelSending = (taskId, data) =>
  axiosAuth(
    { url: `${API_FULL_URL}/task/${taskId}/cancel_sending/`, data, method: 'post' },
    'green'
  );
