import React from 'react';
import ReactDOM from 'react-dom/client';

import * as Sentry from '@sentry/react';

import App from './App.jsx';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

/* 
if entry REACT_APP_DISABLE_SENTRY non existent in .env or not equal true, 
the value will be false, i.e. sentry will be enabled 
*/
const DISABLE_SENTRY = process.env?.REACT_APP_DISABLE_SENTRY === 'true';
const ENABLE_SENTRY = !DISABLE_SENTRY;
if (ENABLE_SENTRY) {
  Sentry.init({
    // https://fsk-cp.sentry.io/issues/?project=4505883981381632#welcome
    // someuser8765@gmail.com
    dsn: 'https://5292a7a8a02634d6545a6abae51b9f08@o4505272195612672.ingest.sentry.io/4505883981381632',
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/]
      }),
      new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

serviceWorkerRegistration.register();
