import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import UAParser from 'ua-parser-js';

import navigationService from './navigationService';

import {
  checkDateAndRenewSub,
  isWebPushDisabled,
  pushNotificationsInit,
  persistIsWebPushDisabled
} from './common/plugins/pushNotifications/main';

import {
  handleAutoNavigateOnLoadSso,
  handleAutoNavigateOnLoadRegular,
  navigateToLogin
} from './common/lib/auth';

import patchOldLocalStorageItems from './common/lib/patchOldLocalStorageItems';

import AppVersion from './common/components/AppVersion.jsx';
import UserBriefData from './common/components/UserBriefData.jsx';

import { logSentryError, logSentryMessage } from './common/lib/utils';
import { greenApiFullUrl, keycloakSsoAuthEnabled, pushLogSecretKey } from './common/api/commonApi';
import { shouldBePresentEnvs } from './config';
import { getUserLocalData } from './common/lib/handleAuthData';
import { getBrowserFingerPrintHash } from './common/plugins/pushNotifications/utils';

import AskingToAllowWebPushModal from './common/plugins/pushNotifications/modals/AskingToAllowWebPushModal';
import UpdateVersionFoundModal from './common/modals/UpdateVersionFoundModal';
import GlobalLoader from './apps/hr_client/components/GlobalLoader/GlobalLoader';

export default function RootLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const [showContent, setShowContent] = useState(false);

  /* 
  for navigating in regular js files, 
  without using window object, which doesn't work in jest tests
  */
  const saveNavigationServiceInstance = () => {
    navigationService.navigate = navigate;
  };

  useEffect(() => {
    saveNavigationServiceInstance();
  }, [navigate]);

  const handleAppTheme = () => {
    if (localStorage.getItem('dark-theme') === 'true') {
      document.body.classList.add('dark-theme');
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  /* check push subscription date on each user nav event */
  // TODO: вынести в hook или компонент
  useEffect(() => {
    if (keycloakSsoAuthEnabled) {
      checkDateAndRenewSub();
    } else {
      const { greenUserKey, hrUserKey } = getUserLocalData() || {};
      if (greenUserKey && hrUserKey) {
        checkDateAndRenewSub();
      }
    }
  }, [location]);

  const regularAuthProcess = async () => {
    const { hrUserKey, greenUserKey } = getUserLocalData();

    if (!hrUserKey || !greenUserKey) {
      logSentryMessage('logout because empty tokens hrUserKey and greenUserKey for regular auth');
      navigateToLogin();
      return;
    }

    pushNotificationsInit();
    handleAutoNavigateOnLoadRegular();
  };

  const checkPresentEnvs = async () => {
    const unpresentEnvs = [];
    shouldBePresentEnvs.map((envName) => {
      if (!process.env[envName]) {
        unpresentEnvs.push(envName);
      }
    });
    if (unpresentEnvs.length) {
      logSentryError(`unpresent envs detected: ${JSON.stringify(unpresentEnvs)}`);
    }
  };

  const persistBrowserInfoInServiceWorker = () => {
    const browser_id = getBrowserFingerPrintHash();
    let deviceParser = new UAParser();
    let deviceParserResults = deviceParser.getResult();
    if (navigator?.serviceWorker?.controller) {
      navigator?.serviceWorker?.controller.postMessage({
        type: 'persist-browser-info',
        data: {
          browser_id: browser_id,
          device_info: JSON.stringify(deviceParserResults),
          api_full_url: greenApiFullUrl,
          push_log_secret_key: pushLogSecretKey
        }
      });
    }
  };

  const proxyAllServiceWorkerMessagesToSentry = () => {
    navigator.serviceWorker?.addEventListener('message', (event) => {
      const pData = JSON.parse(event.data);
      logSentryMessage(`received a message from service worker: ${JSON.stringify(pData.data)}`);
    });
  };

  const onLoad = async () => {
    setTimeout(() => {
      setShowContent(true);
    }, 1000);

    patchOldLocalStorageItems();
    checkPresentEnvs();
    handleAppTheme();
    persistIsWebPushDisabled();
    if (!isWebPushDisabled()) {
      persistBrowserInfoInServiceWorker();
      proxyAllServiceWorkerMessagesToSentry();
    }

    if (keycloakSsoAuthEnabled) {
      logSentryMessage('onLoad Sso auth process');
      handleAutoNavigateOnLoadSso();
    } else {
      logSentryMessage('onLoad regular auth process');
      regularAuthProcess();
    }
  };

  return (
    <div className="main-body">
      <Toaster />

      {showContent && (
        <>
          <UpdateVersionFoundModal />
          <Outlet />
          <UserBriefData />
          <AskingToAllowWebPushModal />
        </>
      )}

      <GlobalLoader />
      <AppVersion />
    </div>
  );
}
