import { useState } from 'react';

const date = new Date();
let currentHour = date.getHours();
let currentMinute = date.getMinutes().toString().padStart(2, '0');
let showHour = currentHour;
let showMinute = currentMinute;

showMinute = Math.floor(showMinute / 15) * 15;
if (showMinute >= 60) {
  showMinute = 0;
}

const timeHoursStartRange = 8;
const timeHoursEndRange = 21;

if (showHour < timeHoursStartRange) {
  showHour = timeHoursStartRange;
}
if (showHour > timeHoursEndRange) {
  showHour = timeHoursStartRange;
}

showHour = `${showHour < 10 ? '0' + showHour : showHour}`;
showMinute = `${showMinute < 10 ? '0' + showMinute : showMinute}`;

const currentTimeHHmm = `${showHour}:${showMinute}`;

const initialValues = {
  room: { email: 'Все', name: 'Все' },
  floors: 'Все',
  seats: 'Все',
  canShowMyMeetingsButton: false,
  myMeetingsActive: false,
  timeStart: currentTimeHHmm,
  //timeStart: '8:00',
  timeEnd: '21:00'
};

export function useFilters(data) {
  const [isFiltersDiabled, setIsFiltersDiabled] = useState(true);
  const [activeFilterValues, setActiveFilterValues] = useState(initialValues);
  const [startDate, setStartDate] = useState(date);
  const [isTypeModalShow, setTypeModalShow] = useState(false);

  const initialFiltersData = data
    ? {
        rooms: [
          { email: 'Все', name: 'Все' },
          ...data.map((roomItem) => {
            return { email: roomItem.email, name: roomItem.name };
          })
        ],
        floors: [
          ...new Set(['Все', ...data.map((roomItem) => roomItem.floor).sort((a, b) => a - b)])
        ],
        seats: [
          ...new Set(['Все', ...data.map((roomItem) => roomItem.seats).sort((a, b) => a - b)])
        ],
        timeInterval: [...Array(14).keys()].map((i) => (i + 8).toString() + ':00')
      }
    : {};

  const onClick = (event, room) => {
    event.preventDefault();
    setActiveFilterValues({
      ...activeFilterValues,
      ...room
    });
  };

  const closeTypeModalHandle = () => {
    setTypeModalShow(!isTypeModalShow);
  };

  const setDate = (date) => {
    if (date && date._isAMomentObject) date = date.toDate();
    setStartDate(date);
  };

  const resetDate = () => {
    setDate(date);
  };

  const resetFilters = () => {
    setActiveFilterValues(initialValues);
  };

  const setCanShowMyMeetingsButton = () => {
    setActiveFilterValues({
      ...activeFilterValues,
      canShowMyMeetingsButton: !activeFilterValues.canShowMyMeetingsButton
    });
  };
  return [
    {
      isFiltersDiabled,
      isTypeModalShow,
      startDate,
      initialFiltersData,
      activeFilterValues
    },
    {
      setIsFiltersDiabled,
      onClick,
      setDate,
      closeTypeModalHandle,
      resetFilters,
      setCanShowMyMeetingsButton,
      resetDate
    }
  ];
}
