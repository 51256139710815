/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { minTwoDigits } from '../../lib/mixing';

import { getTemplate, CreateReferenceOrder, getTemplateCategory } from '../../api/hrApi';

import { ReactComponent as CalendarIcon } from '../../assets/images/icons/calendar-icon.svg';

import TextArea from '../../components/TextArea/TextArea';
import Button from '../../components/Buttons/Button';
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';
import Navigation from '../../components/Navigation/Navigation';

const Reference = () => {
  const dispatch = useDispatch();
  const { categoryID, referenceID } = useParams();
  const [templateData, setTemplateData] = useState();
  const [categoryTemplateCount, setCategoryTemplateCount] = useState();

  const [startDate, setStartDate] = useState('');
  const [errors, setErrors] = useState([]);
  const [saveOrder, setSaveOrder] = useState(false);

  const [formData, setFormData] = useState();

  const GetTemplateData = () => {
    dispatch({ type: 'GlOBAL_LOADER', show: true });
    getTemplate(referenceID)
      .then((resp) => {
        setTemplateData(resp.data);
        // const object = resp.data.fields.reduce(
        //   (obj, item) => Object.assign(obj, { [item.code]: '' }), {})

        // setFormData(object)
        const data = resp.data.fields.map((field) => {
          return {
            code: field.code,
            value: field.type === 'range_calendar' || field.type === 'range_work' ? ['', ''] : '',
            type: field.type
          };
        });
        setFormData(data);
      })
      .catch((error) => console.info(error))
      .finally(() => dispatch({ type: 'GlOBAL_LOADER', show: false }));
  };

  const getCategoryTemplates = () => {
    dispatch({ type: 'GlOBAL_LOADER', show: true });
    getTemplateCategory(categoryID)
      .then((resp) => {
        setCategoryTemplateCount(resp.data.templates.length);
        // setCategoryData(resp.data)
      })
      .catch((error) => console.info(error))
      .finally(() => dispatch({ type: 'GlOBAL_LOADER', show: false }));
  };

  useEffect(() => {
    GetTemplateData();
    getCategoryTemplates();
  }, []);

  const getDate = (dateData) => {
    let newdate = new Date(dateData);
    const [month, day, year] = [newdate.getMonth() + 1, newdate.getDate(), newdate.getFullYear()];
    const dateString = `${minTwoDigits(day)}.${minTwoDigits(month)}.${year}`;
    return dateString;
  };

  const getDateObject = (dateData) => {
    const [month, day, year] = [
      dateData.split('.')[0],
      dateData.split('.')[1],
      dateData.split('.')[2]
    ];
    let dateString = `${year}/${day}/${month}`;
    let date = new Date(dateString);
    return date;
  };

  const setData = (field, value, index) => {
    let ChangingField = formData.map((item) => {
      if (item.code === field.code) {
        return {
          ...item,
          value: ((field, value, index) => {
            switch (field.type) {
              case 'date':
                return getDate(value);
              case 'range_calendar':
                return formData
                  .find((item) => item.code === field.code)
                  .value.map((item, idx) => (idx === index ? getDate(value) : item));
              case 'range_work':
                return formData
                  .find((item) => item.code === field.code)
                  .value.map((item, idx) => (idx === index ? getDate(value) : item));
              default:
                return value;
            }
          })(field, value, index)
        };
      }
      return item;
    });
    setFormData(ChangingField);
    let newErrors = [];
    if (field.type === 'range_calendar' || field.type === 'range_work') {
      newErrors = errors.filter((error) => field.code !== error.code || error.index !== index);
    } else {
      newErrors = errors.filter((error) => field.code !== error);
    }
    setErrors(newErrors);
  };

  const renderSwitch = (field) => {
    switch (field.type) {
      case 'date':
        return (
          <div
            className={['template-data calendar', errors.includes(field.code) ? 'error' : ''].join(
              ' '
            )}
          >
            <label data-message="Пожалуйста, заполните это поле.">
              <span className="icon calendar">
                <CalendarIcon />
              </span>

              <DateTimePicker
                selected={
                  formData &&
                  (formData.find((item) => item.code === field.code).value === ''
                    ? startDate
                    : getDateObject(formData.find((item) => item.code === field.code).value))
                }
                onChange={(date) => setData(field, date, 'single')}
                dateFormat="dd-MM-yyyy"
                placeholderText={'--/--/----'}
                locale="ru"
              />
            </label>
          </div>
        );
      case 'range_work':
        return (
          <React.Fragment>
            <div className="range-wrapper">
              <div
                className={[
                  'template-data calendar range',
                  errors.filter((object) => object.code === field.code && object.index === 0)
                    .length > 0
                    ? 'error'
                    : ''
                ].join(' ')}
              >
                <span className="template-data-title">C</span>
                <label data-message="Пожалуйста, заполните это поле.">
                  <span className="icon calendar">
                    <CalendarIcon />
                  </span>

                  <DateTimePicker
                    selected={
                      formData &&
                      (formData.find((item) => item.code === field.code).value[0] === ''
                        ? startDate
                        : getDateObject(formData.find((item) => item.code === field.code).value[0]))
                    }
                    onChange={(date) => setData(field, date, 0)}
                    dateFormat="dd-MM-yyyy"
                    placeholderText={'--/--/----'}
                    locale="ru"
                    minDate={new Date('2022/01/01')}
                    maxDate={new Date('2025/01/01')}
                  />
                </label>
              </div>

              <div
                className={[
                  'template-data calendar range',
                  errors.filter((object) => object.code === field.code && object.index === 1)
                    .length > 0
                    ? 'error'
                    : ''
                ].join(' ')}
              >
                <span className="template-data-title">По</span>
                <label data-message="Пожалуйста, заполните это поле.">
                  <span className="icon calendar">
                    <CalendarIcon />
                  </span>

                  <DateTimePicker
                    selected={
                      formData &&
                      (formData.find((item) => item.code === field.code).value[1] === ''
                        ? startDate
                        : getDateObject(formData.find((item) => item.code === field.code).value[1]))
                    }
                    onChange={(date) => setData(field, date, 1)}
                    dateFormat="dd-MM-yyyy"
                    placeholderText={'--/--/----'}
                    locale="ru"
                    minDate={new Date('2022/01/01')}
                    maxDate={new Date('2025/01/01')}
                  />
                </label>
              </div>
            </div>
          </React.Fragment>
        );
      case 'range_calendar':
        return (
          <React.Fragment>
            <div className="range-wrapper">
              <div
                className={[
                  'template-data calendar range',
                  errors.filter((object) => object.code === field.code && object.index === 0)
                    .length > 0
                    ? 'error'
                    : ''
                ].join(' ')}
              >
                <span className="template-data-title">C</span>
                <label data-message="Пожалуйста, заполните это поле.">
                  <span className="icon calendar">
                    <CalendarIcon />
                  </span>

                  <DateTimePicker
                    selected={
                      formData &&
                      (formData.find((item) => item.code === field.code).value[0] === ''
                        ? startDate
                        : getDateObject(formData.find((item) => item.code === field.code).value[0]))
                    }
                    onChange={(date) => setData(field, date, 0)}
                    dateFormat="dd-MM-yyyy"
                    placeholderText={'--/--/----'}
                    locale="ru"
                    minDate={new Date('2022/01/01')}
                    maxDate={new Date('2025/01/01')}
                  />
                </label>
              </div>

              <div
                className={[
                  'template-data calendar range',
                  errors.filter((object) => object.code === field.code && object.index === 1)
                    .length > 0
                    ? 'error'
                    : ''
                ].join(' ')}
              >
                <span className="template-data-title">По</span>
                <label data-message="Пожалуйста, заполните это поле.">
                  <span className="icon calendar">
                    <CalendarIcon />
                  </span>

                  <DateTimePicker
                    selected={
                      formData &&
                      (formData.find((item) => item.code === field.code).value[1] === ''
                        ? startDate
                        : getDateObject(formData.find((item) => item.code === field.code).value[1]))
                    }
                    onChange={(date) => setData(field, date, 1)}
                    dateFormat="dd-MM-yyyy"
                    placeholderText={'--/--/----'}
                    locale="ru"
                    minDate={new Date('2022/01/01')}
                    maxDate={new Date('2025/01/01')}
                  />
                </label>
              </div>
            </div>
          </React.Fragment>
        );
      default:
        return (
          <div className={['template-data', errors.includes(field.code) ? 'error' : ''].join(' ')}>
            <div data-message="Пожалуйста, заполните это поле.">
              <TextArea
                placeholder="Выведите текст"
                rows={'2'}
                onChange={(event) => setData(field, event.target.value, 'single')}
              />
            </div>
          </div>
        );
    }
  };

  const CreateOrder = () => {
    let newerrors = [];
    formData.forEach((item) => {
      if (typeof item.value === 'string') {
        if (item.value === '') {
          newerrors.push(item.code);
        }
      } else if (typeof item.value === 'object') {
        item.value.map((value, index) => {
          if (value === '') {
            newerrors.push({ code: item.code, index: index });
          }
          return false;
        });
      }
    });

    setErrors(newerrors);
    if (newerrors.length === 0) {
      setSaveOrder(true);
    }
  };

  useEffect(() => {
    if (saveOrder) {
      const dataTOSave = {
        template: referenceID,
        fields: formData
      };
      dispatch({ type: 'GlOBAL_LOADER', show: true });
      CreateReferenceOrder(dataTOSave)
        .then((resp) => {
          if (resp.statusText === 'Accepted') {
            dispatch({
              type: 'SUCCESS_MODAL',
              ...{ show: true, text: 'Заявка создана', path: '/hr/references/' }
            });
          }
        })
        .catch((error) => console.info(error))
        .finally(() => {
          dispatch({ type: 'GlOBAL_LOADER', show: false });
        });
    }
  }, [saveOrder]);

  return (
    <div className="references">
      <Navigation
        blockName="reference"
        title="Справки"
        path={
          categoryTemplateCount && categoryTemplateCount === 1
            ? '/hr/references/'
            : `${'/hr/references'}/${categoryID}/`
        }
      />
      <div className="references__block main">
        <div className="references__info">
          <h3>{templateData && templateData.name}</h3>

          {templateData &&
            templateData.fields.map((field, index) => (
              <div className="template-data-wrapper" key={index}>
                <div className="template-data-title">{field.title}</div>
                {renderSwitch(field)}
              </div>
            ))}
          {templateData && (
            <Button className={'save_reference'} onClick={(event) => CreateOrder()}>
              Заказать
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Reference;
