import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getUserLocalData } from '../../../../../../common/lib/handleAuthData';

import TimeItem from './TimeItem';

const TimesBar = ({
  email,
  name,
  description,
  schedules,
  setisScheduleModalOpen,
  activeFilterValues
}) => {
  const { userEmail } = getUserLocalData() || {};

  const timer = useRef(null);
  const bookingDisabled = useSelector((state) => state.bookings.bookingDisabled);

  const dispatch = useDispatch();

  const [startTime, setStartTime] = useState();
  const [StatusModalStatus, setStatusModalStatus] = useState({
    setBusy: (data) => {
      setStatusModalStatus({
        ...StatusModalStatus,
        isBusy: data
      });
    },
    isBusy: false
  });

  const scheduleModalHandle = (endTime) => {
    const Busytimes = schedules
      .filter((Schedule) => Schedule.is_busy === true)
      .map((time) => time.time);
    dispatch({
      type: 'SetScheduleModalData',
      scheduleModalData: {
        show: !bookingDisabled && true,
        startTime: startTime,
        endTime: endTime,
        room: email,
        schedules: schedules,
        Busytimes: Busytimes,
        Name: name,
        Description: description
      }
    });
  };

  const scheduleModalDataHandle = (data) => {
    if (data.StartTime && data.endTime === undefined) {
      setStartTime(data.StartTime);
    }
    if (data.StartTime && data.EndTime !== undefined) {
      setStartTime(data.StartTime);
      if (data.target) {
        const elements = data.target.target.offsetParent.querySelectorAll('.time-period');
        elements.forEach((element) => {
          if (element.dataset.value >= data.StartTime && element.dataset.value < data.EndTime) {
            element.classList.add('selected');
          }
        });
      }

      setTimeout(() => {
        scheduleModalHandle(data.EndTime);
      }, 500);
    }
  };

  const getStatus = (item) => {
    if (!item.is_busy || item.organizer === null) {
      return 'free';
    } else {
      if (item.organizer.email.toLowerCase() === userEmail.toLowerCase()) {
        return 'booked_by_you';
      } else if (
        item.required_attendees.filter(
          (attendess) => attendess.email.toLowerCase() === userEmail.toLowerCase()
        ).length > 0
      ) {
        return 'you_invited';
      }
      return 'booked';
    }
    // return 'booked'
  };

  const timeStartHour = parseInt(activeFilterValues.timeStart.split(':')[0]);
  const timeEndHour = parseInt(activeFilterValues.timeEnd.split(':')[0]);

  let Elements = schedules
    .filter((Schedule, index) => index % 4 === 0)
    .filter((item) => {
      const itemTimeHour = parseInt(item.time.split(':')[0]);
      return itemTimeHour >= timeStartHour && itemTimeHour < timeEndHour;
    })
    .map((Schedule, index) => {
      return (
        <React.Fragment key={Schedule.time}>
          <div className="time-hours d-flex flex-column noselect" data-time-group={Schedule.time}>
            <div>{Schedule.time}</div>
            <div className="d-flex justify-content-between">
              {schedules
                .slice(schedules.indexOf(Schedule), schedules.indexOf(Schedule) + 4)
                .map((item, x) => (
                  <TimeItem
                    timer={timer}
                    StatusModalStatus={StatusModalStatus}
                    schedules={schedules}
                    classes={[
                      'time-period',
                      'status',
                      'position-relative',
                      item.is_busy && !item.isFirstRepeat && 'repeating',
                      getStatus(item)
                    ].join(' ')}
                    type={getStatus(item)}
                    key={'by_period_' + x}
                    scheduleData={item}
                    Timevalue={item.time}
                    scheduleModalData={scheduleModalDataHandle}
                    startTime={startTime}
                    setisScheduleModalOpen={setisScheduleModalOpen}
                  />
                ))}
            </div>
          </div>
        </React.Fragment>
      );
    });

  return <React.Fragment>{Elements && Elements}</React.Fragment>;
};
export default TimesBar;
