import React, { useEffect, useState } from 'react';
import Navigation from '../../components/Navigation/Navigation';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getTemplateCategories, getLastOrderdTemplates } from '../../api/hrApi';

import { formatDate } from '../../lib/mixing';
import { ReactComponent as ArrowRight } from '../../assets/images/icon/ArrowRight.svg';

const References = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [categories, setCategories] = useState();
  const [lastOrderdTemplates, setLastOrderdTemplates] = useState();

  const GetCategories = () => {
    dispatch({ type: 'GlOBAL_LOADER', show: true });
    getTemplateCategories()
      .then((resp) => {
        const categoryies = resp.data
          .filter((item) => item.name !== 'Спецсправки')
          .filter((item) => item.templates.length > 0)
          .sort((a, b) => {
            return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
          });
        setCategories(categoryies);
      })
      .catch((error) => console.info(error))
      .finally(() => dispatch({ type: 'GlOBAL_LOADER', show: false }));
  };

  const GetLastOrderd = () => {
    dispatch({ type: 'GlOBAL_LOADER', show: true });
    getLastOrderdTemplates()
      .then((resp) => {
        const templates = resp.data
          .sort((a, b) => {
            return a.create_at > b.create_at ? 1 : b.create_at > a.create_at ? -1 : 0;
          })
          .reverse();
        setLastOrderdTemplates(templates);
      })
      .catch((error) => console.info(error))
      .finally(() => dispatch({ type: 'GlOBAL_LOADER', show: false }));
  };

  useEffect(() => {
    GetCategories();
    GetLastOrderd();
  }, []);

  const getStatus = (status) => {
    switch (status) {
      case 'done':
        return 'Готово';
      case 'process':
        return 'В процессе';
      case 'process_doc':
        return 'Передано в кадры';
      case 'new':
        return 'Создано';
      case 'cancel':
        return 'Отклонено';
    }
  };

  const downloadFile = (file) => {
    window.open(file, '_blank');
  };

  return (
    <div className="references">
      <Navigation blockName="references" title="Справки" />

      <div className="references__block main">
        <ul>
          <li className="main__info-item">
            <div className="main__info-item-wrapper first">
              <h3>Справки</h3>
            </div>
          </li>

          {categories &&
            categories.map((category) => (
              <li
                className="main__info-item"
                key={category.id}
                onClick={() =>
                  navigate(
                    category.templates.length > 1
                      ? `/hr/references/${category.id}`
                      : `/hr/references/${category.id}/${category.templates.pop().id}`
                  )
                }
              >
                <div className="main__info-item-wrapper">
                  <h4>{category.name}</h4>
                  <ArrowRight />
                </div>
              </li>
            ))}
        </ul>

        <ul className="other-section">
          <li className="main__info-item">
            <div className="main__info-item-wrapper first">
              <h3>Заказанные ранее</h3>
            </div>
          </li>

          {lastOrderdTemplates &&
            lastOrderdTemplates.map((template, index) => (
              <li
                className={[
                  'main__info-item',
                  template.status === 'done' && template.file != null ? 'cursor-pointer' : ''
                ].join(' ')}
                key={index}
                onClick={() =>
                  template.status === 'done' && template.file != null && downloadFile(template.file)
                }
              >
                <div className="main__info-item-wrapper">
                  <div>
                    <h4>{template.template.name}</h4>
                    <h5>
                      Статус:{' '}
                      <span style={template.status === 'cancel' ? { color: 'red' } : {}}>
                        {getStatus(template.status)}
                      </span>
                    </h5>
                  </div>
                  <div className="date">{`от ${formatDate(template.create_at)}`}</div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default References;
