import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Textarea.scss';

const TextArea = ({ className, rows, id, placeholder, onChange, disabled, active }) => {
  const classes = classNames('textarea', className, { active });
  return (
    <textarea
      onChange={onChange}
      disabled={disabled}
      className={classes}
      id={id}
      rows={rows}
      placeholder={placeholder}
    ></textarea>
  );
};

TextArea.defaultProps = {
  onChange: () => {},
  className: '',
  disabled: false,
  active: false,
  rows: '1',
  placeholder: '',
  id: ''
};

TextArea.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  rows: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string
};

export default TextArea;
