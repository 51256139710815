import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getTasksByCategory } from '../api/greenApi';

import {
  differenceInDays,
  formatDate,
  getDayAddition,
  numberWithSpaces,
  roundTONearestInteger,
  formatTextLength
} from '../lib/utils';

import TasksButtonActions from '../hooks/TasksButtonActions.jsx';

import { ReactComponent as StackIcon } from '../assets/images/icons/stackIcon.svg';
import { ReactComponent as LeftArrowIcon } from '../assets/images/icons/leftArrowIcon.svg';
import { ReactComponent as DialHandIcon } from '../assets/images/icons/dialHandIcon.svg';
import { ReactComponent as ReloadIcon } from '../assets/images/icons/reloadIcon.svg';

import Alert from '../components/UI/Alert.jsx';
import Shure from '../components/UI/Shure.jsx';
import Success from '../components/UI/Success.jsx';
import Error from '../components/UI/Error.jsx';
import ConfirmActionModal from '../components/UI/ConfirmActionModal.jsx';
import Loading from '../../../common/components/UI/Loading';

export default function Tasks() {
  const [searchfield, setSearchfield] = useState(false);
  const [tasks, setTasks] = useState({ name: '', code: '', tasks: [] });
  const { code, api } = useParams();
  const [load, setLoad] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  const [ordering, setOrdering] = useState('number');
  const [orderingFrontEnd, setOrderingFrontEnd] = useState('');
  const [viewType, setviewType] = useState('');
  const [TableTitles, SetTableTitles] = useState([]);
  const [GroupSaving, setGroupSaving] = useState(false);
  const [GroupSavingTitle, setGroupSavingTitle] = useState({
    text: 'Выберите заявки:',
    type: 'title'
  });

  const searchReference = useRef(null);

  const [buttondata, setButtonData] = useState([]);
  const showWarning = useSelector((state) => state.greenButton.showWarning);
  const showShure = useSelector((state) => state.greenButton.howShure);
  const showError = useSelector((state) => state.greenButton.showError);

  const [taskData, setTaskData] = useState([]);

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);

  const [answer, setAnswer] = useState('');
  const updateTask = (buttonLoad = false) => {
    setLoad(true);

    getTasksByCategory(code, searchInput, ordering)
      .then((resp) => {
        let allTasks = resp.data;
        setTaskData(allTasks);

        if (api === 'flex') {
          let titles = allTasks.tasks
            .map((task) => task.index.map((item) => ({ type: item.type, name: item.name })))
            .pop();
          SetTableTitles(titles);
          allTasks = {
            name: allTasks.name,
            tasks: allTasks.tasks.map((task) =>
              task.index.map((item) => ({
                ...item,
                planed_to_be_sucsessed: task.planed_to_be_sucsessed,
                id: task.id,
                buttons: task.buttons,
                guid_in_1c: task.guid_in_1c,
                pending_task: task.pending_task
              }))
            )
          };
        }

        if (buttonLoad) {
          setTimeout(() => {
            setTasks(allTasks);
          }, 500);
        } else {
          setTasks(allTasks);
        }

        setAnswer('');
      })
      .catch(() => {
        const message = 'Не удалось получить задачи по категориям';
        console.info('--getTasksByCategory Error', message);
        setAnswer(message);
      })
      .finally(() => setLoad(false));
    setviewType('');
  };

  const {
    onClickButton,
    commentField,
    showSuccess,
    ConfirmModal,
    postAction,
    loadingData,
    answerData
  } = TasksButtonActions({ tasks: selectedTasks, updateTask });

  useEffect(() => {
    setLoad(loadingData);
    setAnswer(answerData);
  }, [loadingData, answerData]);

  useEffect(() => {
    updateTask();
  }, [searchInput, ordering]);

  const activeSearch = () => {
    setSearchfield(!searchfield);
    setSearchInput('');

    setTimeout(() => {
      searchReference.current.focus();
    }, 50);
  };

  const handleSearch = (event) => {
    setSearchInput(event.target.value);
  };

  useEffect(() => {
    if (orderingFrontEnd) {
      let sortTasks = {
        tasks: tasks.tasks.sort((a, b) => {
          return a[orderingFrontEnd.split('order_')[1]].value >
            b[orderingFrontEnd.split('order_')[1]].value
            ? 1
            : b[orderingFrontEnd.split('order_')[1]].value >
              a[orderingFrontEnd.split('order_')[1]].value
            ? -1
            : 0;
        })
      };
      orderingFrontEnd.includes('-') && sortTasks.tasks.reverse();
      setTasks({
        ...tasks,
        sortTasks
      });
    }
  }, [orderingFrontEnd]);

  useEffect(() => {}, [tasks]);

  const confirmAction = (success) => {
    ConfirmModal.setActiveConfirmModal(false);
    if (success) {
      postAction();
    }
  };

  const HandleOnClick = (event, item) => {
    if (viewType === 'activated') {
      event.preventDefault();
      setSelectedItems(
        selectedItems.indexOf(item.id) === -1
          ? [...selectedItems, item.id]
          : selectedItems.filter((id) => id !== item.id)
      );
    }
    return true;
  };

  useEffect(() => {
    if (selectedItems && selectedItems.length > 0) {
      const buttons = taskData.tasks
        .filter((task) => selectedItems.indexOf(task.id) !== -1)
        .map((tasks) => tasks.buttons);

      const allButtons = [].concat.apply([], buttons);
      const buttonGroup = allButtons.reduce(function (r, a) {
        r[a.code] = r[a.code] || [];
        r[a.code].push(a);
        return r;
      }, Object.create(null));

      const buttonsToShow = [];
      Object.entries(buttonGroup).forEach((item) => {
        if (item[1].length === buttons.length) {
          buttonsToShow.push(item[1][0]);
        }
      });

      if (buttonsToShow.length > 0) {
        setGroupSaving(true);
      } else {
        setGroupSavingTitle({ text: 'Группповое действие - невозможно ', type: 'warning' });
      }
      setButtonData(buttonsToShow);
      setSelectedTasks(taskData.tasks.filter((item) => selectedItems.includes(item.id)));
    } else {
      setButtonData([]);
      setGroupSaving(false);
      setGroupSavingTitle({ text: 'Выберите заявки:', type: 'title' });
    }
    onClickButton({});
  }, [selectedItems]);

  useEffect(() => {
    viewType !== 'activated' && setSelectedItems([]);
  }, [viewType]);

  const renderLinkText = (element) => {
    switch (element.type) {
      case 'text':
        return (
          <span className="px-2 d-inline-block" title={element.value}>
            {formatTextLength(element.value)}
          </span>
        );
      case 'date':
        return <span className="">{element.value}</span>;
      default:
        return <span>{element.value}</span>;
    }
  };

  return (
    <>
      {showWarning ? <Alert>{answer}</Alert> : ''}
      {showError ? <Error>{answer}</Error> : ''}
      {showShure ? <Shure>{answer}</Shure> : ''}
      {showSuccess ? <Success>{answer}</Success> : ''}
      {ConfirmModal.activeConfirmModal && <ConfirmActionModal confirmAction={confirmAction} />}
      {load ? <Loading /> : ''}
      <div
        className={
          searchfield
            ? 'top-content-block flex align-center space-between py-3 px-3 search-activated'
            : 'top-content-block flex align-center space-between py-3 px-3'
        }
      >
        <div className="flex align-center space-between w100">
          <div className="flex align-center">
            <Link to={'/inbox'}>
              <LeftArrowIcon />
            </Link>
            <div className="h5 active-page m-0">
              {tasks.name} <span>| {tasks['tasks'].length}</span>
            </div>
          </div>
          <div className="search-by-name-field">
            <input
              type="text"
              name=""
              placeholder="Введите текст..."
              value={searchInput}
              onChange={(e) => handleSearch(e)}
              ref={searchReference}
            />
            <div className="close" onClick={() => activeSearch()}></div>
          </div>
        </div>

        <div className="flex align-center justify-center icons">
          <span
            className={[
              'mx-2',
              'cursor-pointer',
              'stend_icon',
              viewType === 'activated' ? 'activated' : ''
            ].join(' ')}
            onClick={() => {
              setviewType(viewType === 'activated' ? ' ' : 'activated');
            }}
          >
            {<StackIcon />}
          </span>
          <span className="mx-2 cursor-pointer" onClick={() => activeSearch()}>
            <DialHandIcon />
          </span>
          <span className="mx-2 cursor-pointer" onClick={() => updateTask(true)}>
            <ReloadIcon />
          </span>
        </div>
      </div>

      {viewType === 'activated' && (
        <section>
          <div
            className="container p-0 pt-3 mb-2 data-container-bg"
            style={{ height: 'auto', borderRadius: 20, minHeight: 89 }}
          >
            {buttondata && buttondata.length < 1 && (
              <div className="d-flex flex-wrap px-4 mb-0">
                <p style={{ paddingTop: '0.85em' }}>{GroupSavingTitle.text}</p>
              </div>
            )}

            {GroupSaving && (
              <>
                <div className="d-flex flex-wrap px-3 mb-3">
                  {buttondata &&
                    buttondata.map((item) => (
                      <button
                        key={item.code}
                        className={
                          item['code'] === 'otkl' ? 'btn reject mx-3 my-2' : 'btn approve mx-3 my-2'
                        }
                        onClick={() => onClickButton(item)}
                      >
                        {item['name']}
                      </button>
                    ))}
                </div>
                {commentField.html}
              </>
            )}
          </div>
        </section>
      )}

      <div className={[viewType, 'container', 'p-0', 'pt-3', 'data-container-bg'].join(' ')}>
        {api === 'old' && (
          <ul className="px-0">
            <li>
              <ul className="table d-flex m-0 p-0 pb-2 title">
                <li
                  className={[
                    ordering.includes('number') ? 'active noselect' : 'noselect',
                    'flex-1',
                    'text-center'
                  ].join(' ')}
                  onClick={() =>
                    ordering === 'number' ? setOrdering('-number') : setOrdering('number')
                  }
                >
                  Номер&nbsp;
                  <span
                    className={[
                      ordering.includes('number') ? 'arrow' : '',
                      'flex-1',
                      'text-center'
                    ].join(' ')}
                  ></span>
                </li>
                <li
                  className={[
                    ordering.includes('planed_to_be_sucsessed') ? 'active noselect' : 'noselect',
                    'flex-1',
                    'text-center'
                  ].join(' ')}
                  onClick={() =>
                    ordering === 'planed_to_be_sucsessed'
                      ? setOrdering('-planed_to_be_sucsessed')
                      : setOrdering('planed_to_be_sucsessed')
                  }
                >
                  Дата&nbsp;
                  <span
                    className={ordering.includes('planed_to_be_sucsessed') ? 'arrow' : ''}
                  ></span>
                </li>
                <li
                  className={[
                    ordering.includes('money') ? 'active noselect' : 'noselect',
                    'flex-1',
                    'text-center'
                  ].join(' ')}
                  onClick={() =>
                    ordering === 'money' ? setOrdering('-money') : setOrdering('money')
                  }
                >
                  Сумма&nbsp;
                  <span className={ordering.includes('money') ? 'arrow' : ''}></span>
                </li>
                <li
                  className={[
                    ordering.includes('contractor') ? 'active noselect' : 'noselect',
                    'flex-2',
                    'text-center'
                  ].join(' ')}
                  onClick={() =>
                    ordering === 'contractor'
                      ? setOrdering('-contractor')
                      : setOrdering('contractor')
                  }
                >
                  Контрагент&nbsp;
                  <span
                    className={
                      ordering.includes('contractor')
                        ? `${ordering.includes('-contractor') ? 'arrow' : 'arrow up'}`
                        : ''
                    }
                  ></span>
                </li>
              </ul>
            </li>

            <li className="auto-overflow fixed-height hide-scroll-bar">
              <ul className="table px-0">
                {tasks['tasks'].length
                  ? tasks['tasks'].map((item) => (
                      <div key={item.id}>
                        <li className="mb-1">
                          <ul
                            className={[
                              'd-flex m-0 p-0 bd-highlight flex-nowrap align-items-center justify-content-between table-row position-relative overflow-hidden',
                              selectedItems.indexOf(item.id) >= 0 ? 'li_selected' : ''
                            ].join(' ')}
                          >
                            <li className="bd-highlight flex-1 text-center cursor-pointer border_right position-relative">
                              <Link
                                to={'/inbox/tasks/' + tasks['code'] + '/' + api + '/' + item.id}
                                className="default_link py-3 d-inline-block w-100"
                                onClick={(event) => HandleOnClick(event, item)}
                              >
                                {item.number}
                              </Link>
                            </li>
                            <li className="bd-highlight flex-1 text-center cursor-pointer border_right position-relative">
                              <Link
                                to={'/inbox/tasks/' + tasks['code'] + '/' + api + '/' + item.id}
                                className="default_link  py-3 d-inline-block w-100"
                                onClick={(event) => HandleOnClick(event, item)}
                              >
                                {differenceInDays(
                                  new Date(),
                                  new Date(item.planed_to_be_sucsessed)
                                ) <= 0 ? (
                                  <span style={{ color: 'red' }}>
                                    {formatDate(item.planed_to_be_sucsessed)}{' '}
                                  </span>
                                ) : (
                                  <span>
                                    Осталось{' '}
                                    {getDayAddition(
                                      differenceInDays(
                                        new Date(),
                                        new Date(item.planed_to_be_sucsessed)
                                      )
                                    )}
                                  </span>
                                )}
                              </Link>
                            </li>
                            <li className="bd-highlight flex-1 text-center cursor-pointer border_right position-relative">
                              <Link
                                to={'/inbox/tasks/' + tasks['code'] + '/' + api + '/' + item.id}
                                className="default_link  py-3 d-inline-block w-100"
                                onClick={(event) => HandleOnClick(event, item)}
                              >
                                {numberWithSpaces(roundTONearestInteger(item.money))} ₽
                              </Link>
                            </li>
                            <li className="color-orrange text-uppercase bd-highlight flex-2 text-center cursor-pointer position-relative">
                              <Link
                                to={'/inbox/tasks/' + tasks['code'] + '/' + api + '/' + item.id}
                                className="default_link color-orrange py-3 d-inline-block w-100"
                                onClick={(event) => HandleOnClick(event, item)}
                              >
                                {item.contractor}
                              </Link>
                            </li>
                          </ul>
                        </li>
                        {item?.pending_task ? (
                          <div className="d-flex justify-content-end">
                            <p className="processing-badge mr-2 mb-1">
                              в обработке <span className="loader-dot">.</span>
                              <span className="loader-dot">.</span>
                              <span className="loader-dot">.</span>
                            </p>
                          </div>
                        ) : null}
                      </div>
                    ))
                  : ''}
              </ul>
            </li>
          </ul>
        )}

        {api === 'flex' && (
          <ul className="px-0">
            <li>
              <ul className="table d-flex m-0 p-0 pb-2 title">
                {TableTitles &&
                  TableTitles.map((title, index) => (
                    <li
                      key={index}
                      className={[
                        orderingFrontEnd.includes(index) ? 'active noselect' : 'noselect',
                        'flex-1',
                        'text-center'
                      ].join(' ')}
                      onClick={() =>
                        orderingFrontEnd === 'order_' + index
                          ? setOrderingFrontEnd(`-order_${index}`)
                          : setOrderingFrontEnd(`${'order_' + index}`)
                      }
                    >
                      {title.name} &nbsp;
                      <span
                        className={
                          orderingFrontEnd.includes('order_' + index)
                            ? `${
                                orderingFrontEnd.includes(`-${'order_' + index}`)
                                  ? 'arrow'
                                  : 'arrow up'
                              }`
                            : ''
                        }
                      ></span>
                    </li>
                  ))}
              </ul>
            </li>

            <li className="auto-overflow fixed-height hide-scroll-bar">
              <ul className="table px-0">
                {tasks['tasks'].length
                  ? tasks['tasks'].map((item) => (
                      <div key={Math.random()}>
                        <li className="mb-1">
                          <ul className="d-flex m-0 p-0 bd-highlight flex-nowrap align-items-center justify-content-between table-row position-relative overflow-hidden">
                            {item.map((element, index) => (
                              <li
                                className={[
                                  'bd-highlight flex-1 text-center border_right position-relative',
                                  selectedItems.indexOf(element.id) >= 0 ? 'li_selected' : ''
                                ].join(' ')}
                                key={index}
                              >
                                {viewType === 'activated' && item[0]?.pending_task ? (
                                  <p className="py-3 d-inline-block w-100">
                                    {renderLinkText(element)}
                                  </p>
                                ) : (
                                  <Link
                                    to={'/inbox/tasks/' + code + '/' + api + '/' + element.id}
                                    className="default_link cursor-pointer py-3 d-inline-block w-100"
                                    onClick={(event) => HandleOnClick(event, element)}
                                  >
                                    {renderLinkText(element)}
                                  </Link>
                                )}
                              </li>
                            ))}
                          </ul>
                        </li>
                        {item[0]?.pending_task ? (
                          <div className="d-flex justify-content-end">
                            <p className="processing-badge mr-2 mb-1">
                              в обработке <span className="loader-dot">.</span>
                              <span className="loader-dot">.</span>
                              <span className="loader-dot">.</span>
                            </p>
                          </div>
                        ) : null}
                      </div>
                    ))
                  : ''}
              </ul>
            </li>
          </ul>
        )}
      </div>
    </>
  );
}
