import React, { useState } from 'react';
import {
  handleAutoNavigateOnLoadSso,
  handleAutoNavigateOnLoadRegular,
  loginRegular,
  setUserSsoDataByKeycloakData
} from '../lib/auth';

import Keycloak from '../lib/keycloack.js';
import { saveKnopkaEmailApi } from '../lib/auth';
import { pushNotificationsInit } from '../plugins/pushNotifications/main.js';

import Loading from '../components/UI/Loading.jsx';
import InstallButton from '../components/InstallButton.jsx';
import { logSentryMessage } from '../lib/utils';
import { keycloakSsoAuthEnabled } from '../api/commonApi';
import { delayAfterSsoKeyRefreshMilliseconds } from '../../config';

export default function LoginPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userNameError, setUserNameError] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [commonError, setCommonError] = useState('');

  const validateForm = () => {
    setCommonError('');
    setPasswordError('');
    setUserNameError('');
    let hasFormErrors = false;

    if (!userName) {
      hasFormErrors = true;
      setUserNameError('Необходимо заполнить поле');
    }

    if (!password) {
      hasFormErrors = true;
      setPasswordError('Необходимо заполнить поле');
    }

    return hasFormErrors;
  };

  const handleAfterLoginSso = async (keycloackAuthResultData) => {
    const currentTimestamp = Date.now();
    localStorage.setItem('last_updated_sso_key_timestamp', currentTimestamp);

    setUserSsoDataByKeycloakData(keycloackAuthResultData);

    const hrUserKey = localStorage.getItem('hrUserKey');
    logSentryMessage(`user logged in to sso. Ad-fsk: ${hrUserKey ? 1 : 0}`);

    // await saveKnopkaEmailApi();

    handleAutoNavigateOnLoadSso();
    pushNotificationsInit();
  };

  const handleAfterLoginRegular = async () => {
    localStorage.setItem('donotclear_debug_username', userName);
    await saveKnopkaEmailApi();
    handleAutoNavigateOnLoadRegular();
    pushNotificationsInit();
  };

  const ssoOnSubmitForm = async () => {
    logSentryMessage('ssoOnSubmitForm');

    const keycloackAuthResult = await Keycloak.auth({ username: userName, password: password });

    if (keycloackAuthResult?.status === 200) {
      setTimeout(() => {
        handleAfterLoginSso(keycloackAuthResult?.data);
        setIsLoggedIn(true);
        setIsLoading(false);
      }, delayAfterSsoKeyRefreshMilliseconds);
    } else if (keycloackAuthResult?.response?.status === 401) {
      setCommonError('Неправильный логин или пароль');
      logSentryMessage('user typed wrong login or password');
      setIsLoading(false);
    } else if (keycloackAuthResult.message) {
      setCommonError(keycloackAuthResult.message);
      setIsLoading(false);
    } else {
      setCommonError('Ошибка');
      setIsLoading(false);
    }
  };

  const regularOnSumbitForm = async () => {
    const result = await loginRegular(userName, password);
    setIsLoading(false);
    if (result?.response?.status) {
      const errorMessage = result?.response?.data?.non_field_errors;
      if (errorMessage) {
        setCommonError(errorMessage);
      } else if (result?.message) {
        setCommonError(result?.message);
      } else {
        setCommonError('Возникла ошибка');
      }
    } else if (result?.message) {
      setCommonError(result?.message);
    } else if (result?.data?.key) {
      setIsLoggedIn(true);
      handleAfterLoginRegular();
    } else {
      setCommonError('Возникла неизвестная ошибка');
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    logSentryMessage('user submitted login form');

    const hasFormErrors = validateForm();
    if (!hasFormErrors) {
      setIsLoading(true);

      if (keycloakSsoAuthEnabled) {
        ssoOnSubmitForm();
      } else {
        regularOnSumbitForm();
        logSentryMessage('submit login form regular');
      }
    }
  };

  const processUserName = (e) => {
    if (e.key == '@' || e.key == '\\') {
      e.preventDefault();
      return false;
    }
    formatUserName(e.target.value.trim());
  };

  const formatUserName = (userName) => {
    if (userName.includes('@')) {
      setUserName(userName.split('@')[0]);
      return;
    }
    if (userName.includes('\\')) {
      setUserName(userName.split('\\')[1]);
      return;
    }
    setUserName(userName);
  };

  return (
    <>
      {isLoading ? <Loading /> : ''}

      <div className="position-absolute">
        <InstallButton />
      </div>

      {isLoggedIn && (
        <div className="d-flex align-items-center justify-content-center w-100 h-100 position-absolute flex-column">
          <p>Вы успешно авторизованы</p>
          <p>
            <span className="loader-dot">.</span>
            <span className="loader-dot">.</span>
            <span className="loader-dot">.</span>
          </p>
        </div>
      )}

      {!isLoggedIn && (
        <div className="container p-0 auto-overflow hide-scroll-bar">
          <div className="login-form-container">
            <div className="bg-shadow login-form bg-white">
              <h1 className="h4 text-center font-weight-bold pb-3 text-dark">Авторизация</h1>

              <div className="alert alert-success small" role="alert">
                Пример ввода: PetrovAF
              </div>

              {commonError ? (
                <div className="alert alert-danger small" role="alert">
                  {commonError}
                </div>
              ) : (
                ''
              )}

              <form onSubmit={submitForm}>
                <div className="input-group has-validation mb-3">
                  <input
                    type="text"
                    className={`form-control ${userNameError ? 'is-invalid' : ''}`}
                    data-testid="userName"
                    placeholder="Имя пользователя"
                    aria-label="Имя пользователя"
                    value={userName}
                    onChange={(e) => {
                      processUserName(e);
                    }}
                  />
                  <div className="invalid-feedback">{userNameError}</div>
                </div>
                <div className="input-group has-validation mb-4">
                  <input
                    type="password"
                    className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                    data-testid="userPassword"
                    placeholder="Пароль"
                    aria-label="Пароль"
                    autoComplete="on"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value.trim());
                    }}
                  />
                  <div className="invalid-feedback">{passwordError}</div>
                </div>
                <button
                  type="submit"
                  className="btn approve font-weight-ultra-light login-button w-100"
                  data-testid="loginButton"
                >
                  Войти
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
