import React, { useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';

import { registerLocale } from 'react-datepicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
registerLocale('ru', ru);

import ScheduleTypes from './ScheduleTypes';

import './Filters.css';

const SearchToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    type="button"
    id="dropdown-by-name"
    aria-expanded="false"
    className="btn text-start d-flex justify-content-between align-items-center dropdown-toggle btn btn-primary"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </button>
));

const SearchMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
        <Form.Control
          className="mx-3 my-2 w-auto input-search-by-name"
          placeholder="Введите название"
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter((child) => {
            return !value || child.props.children.toLowerCase().includes(value.toLowerCase());
          })}
        </ul>
      </div>
    );
  }
);

const Filters = ({
  isFiltersDiabled,
  isTypeModalShow,
  startDate,
  initialFiltersData,
  activeFilterValues,
  onClick,
  setDate,
  closeTypeModalHandle
}) => {
  return (
    <section className="filters-container d-flex align-items-end position-relative">
      <div className={['filter-item date', isFiltersDiabled ? 'disable' : ''].join(' ')}>
        <span className="label">Дата</span>
        <div className="wrap-element">
          {
            // eslint-disable-next-line jsx-a11y/label-has-associated-control
            <label className="btn text-start d-flex justify-content-between align-items-center">
              <DatePicker
                disabled={isFiltersDiabled}
                locale="ru"
                selected={startDate}
                onChange={(date) => setDate(date)}
                dateFormat="dd.MM.yyyy"
              />
              <span className="icon calendar"></span>
            </label>
          }
        </div>
      </div>

      <div className="filter-item name">
        <span className="label">Название</span>
        <div className="wrap-element">
          <Dropdown className="search-dropdown-menu">
            <Dropdown.Toggle
              id="dropdown-by-name"
              className={'btn'}
              disabled={isFiltersDiabled}
              as={SearchToggle}
            >
              <span>
                {activeFilterValues?.room?.name.length > 12
                  ? activeFilterValues.room.name.substring(0, 12) + '...'
                  : activeFilterValues?.room?.name}
              </span>
              <span className="icon caret"></span>
            </Dropdown.Toggle>

            <Dropdown.Menu as={SearchMenu}>
              {initialFiltersData?.rooms?.map((room) => (
                <Dropdown.Item
                  key={room.email}
                  onClick={(event) =>
                    onClick(event, { room: { name: room.name, email: room.email } })
                  }
                >
                  {room.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="filter-item floor">
        <span className="label">Этаж</span>
        <div className="wrap-element">
          <Dropdown variant="" disabled={true}>
            <Dropdown.Toggle
              id="dropdown-by-floor"
              className={'btn text-start d-flex justify-content-between align-items-center'}
              disabled={isFiltersDiabled}
            >
              <span>{activeFilterValues?.floors}</span>
              <span className="icon caret"></span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {initialFiltersData?.floors?.map((floor) => (
                <Dropdown.Item
                  key={floor}
                  onClick={(event) => {
                    onClick(event, { floors: floor });
                  }}
                >
                  {floor}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="filter-item capacity">
        <span className="label">Вместимость</span>
        <div className="wrap-element">
          <Dropdown variant="">
            <Dropdown.Toggle
              id="dropdown-by-seats"
              className={'btn text-start d-flex justify-content-between align-items-center'}
              disabled={isFiltersDiabled}
            >
              <span>{activeFilterValues?.seats}</span>
              <span className="icon caret"></span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {initialFiltersData?.seats?.map((seat, key) => (
                <Dropdown.Item
                  key={seat}
                  onClick={(event) => {
                    onClick(event, { seats: seat });
                  }}
                >
                  {seat}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="filter-item time-start">
        <span className="label">От</span>
        <div className="wrap-element">
          <Dropdown variant="">
            <Dropdown.Toggle
              id="dropdown-by-seats"
              className={'btn text-start d-flex justify-content-between align-items-center'}
              disabled={isFiltersDiabled}
            >
              <span>{activeFilterValues?.timeStart}</span>
              <span className="icon caret"></span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {initialFiltersData?.timeInterval?.map((time, key) => (
                <Dropdown.Item
                  key={time}
                  onClick={(event) => {
                    onClick(event, { timeStart: time });
                  }}
                >
                  {time}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="filter-item time-end">
        <span className="label">До</span>
        <div className="wrap-element">
          <Dropdown variant="">
            <Dropdown.Toggle
              id="dropdown-by-seats"
              className={'btn text-start d-flex justify-content-between align-items-center'}
              disabled={isFiltersDiabled}
            >
              <span>{activeFilterValues?.timeEnd}</span>
              <span className="icon caret"></span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {initialFiltersData?.timeInterval?.map((time, key) => (
                <Dropdown.Item
                  key={time}
                  onClick={(event) => {
                    onClick(event, { timeEnd: time });
                  }}
                >
                  {time}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="filter-item my-meetings">
        <div className="wrap-element">
          <button
            className={[
              'btn d-flex align-items-center justify-content-center',
              activeFilterValues.myMeetingsActive ? 'active' : ''
            ].join(' ')}
            onClick={(event) => {
              onClick(event, { myMeetingsActive: !activeFilterValues?.myMeetingsActive });
            }}
            disabled={isFiltersDiabled || !activeFilterValues.canShowMyMeetingsButton}
          >
            {' '}
            Мои встречи
          </button>
        </div>
      </div>

      <div className="filter-item visiblity warn">
        <div className="wrap-element d-flex">
          <button
            className="btn d-flex justify-content-center align-items-center"
            onClick={closeTypeModalHandle}
          >
            <div className="icon warning"></div>
          </button>
        </div>
      </div>

      {isTypeModalShow && <ScheduleTypes closeTypeModal={closeTypeModalHandle} />}
    </section>
  );
};

export default Filters;
