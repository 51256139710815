import axiosAuth from '../../../common/api/axiosAuth';
import { hrApiFullUrl } from '../../../common/api/commonApi';

export const getMeetings = () =>
  axiosAuth({ url: `${hrApiFullUrl}/booking/rooms/`, method: 'get' }, 'hr');

export const getSchedules = (params) =>
  axiosAuth({ url: `${hrApiFullUrl}/booking/schedule/`, params: params, method: 'get' }, 'hr');

export const getUsersData = (params) =>
  axiosAuth({ url: `${hrApiFullUrl}/accounts/`, params: params, method: 'get' }, 'hr');

export const CreateSchedule = (data) =>
  axiosAuth({ url: `${hrApiFullUrl}/booking/meeting/`, data, method: 'post' }, 'hr');

export const CreateRepeatableSchedule = (data) =>
  axiosAuth({ url: `${hrApiFullUrl}/booking/meeting-recurrence/`, data, method: 'post' }, 'hr');
