import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import { postScenarioAction } from '../../api/greenApi';

import Loading from '../../../../common/components/UI/Loading';
import Error from './Error.jsx';

export default function Shure({ user }) {
  const { code, action, api } = useParams();
  const dispatch = useDispatch();
  const vizacomment = useSelector((state) => state.greenButton.vizacomment);
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const [answer, setAnswer] = useState('');
  const showError = useSelector((state) => state.greenButton.showError);
  const tasks = useSelector((state) => state.greenButton.currentTasks);
  const vizadate = useSelector((state) => state.greenButton.vizadate);

  useEffect(() => {
    if (tasks) {
      setLoad(false);
    }
  }, [tasks]);

  const makePostScenario = () => {
    setLoad(true);
    tasks &&
      tasks.map((task, index) => {
        return postScenarioAction(task, action, vizacomment, user.mail, vizadate)
          .then((response) => response.data)
          .then((success) => {
            switch (success.code) {
              case '200':
                console.info('--Shure, Все ок');
                // setAnswer(success.description);
                // setShowSuccess(true);
                toast.success(success.description);
                break;
              case '400':
                console.info('--Входящие данные не корректны');
                // setAnswer(success.description);
                // dispatch({ type: "setWarningOpen" });
                console.info(success.description);
                break;
              case '423':
                console.info('--Администратор уже согласовал эту визу');
                // setAnswer(success.description);
                // setShowSuccess(true);
                toast.success(success.description);
                break;
              case '500':
                console.info('--Ошибка на стороне сервера 1С');
                // setAnswer(success.description);
                // dispatch({ type: "setWarningOpen" });
                toast(success.description, {
                  icon: '⚠️'
                });
                break;
              case '666':
                console.info('--Ошибка на стороне сервера 1С');
                // setAnswer(success);
                // dispatch({ type: "setErrorOpen" });
                console.info(success);
                break;
              default:
                console.info('--Shure не найдено');
                // setAnswer(success);
                // dispatch({ type: "setErrorOpen" });
                console.info(success);
            }
            if (index + 1 === tasks.length) {
              setTimeout(() => {
                setLoad(false);
                dispatch({ type: 'setShure' });
                navigate(`/inbox/tasks/${code}/${api}`, { replace: true });
              }, 500);
            }
          })
          .catch((error) => {
            if (error.response) {
              setLoad(false);
              setAnswer('Произошла ошибка сервера MSBOT');
              dispatch({ type: 'setErrorOpen' });
            }
          });
      });
    dispatch({ type: 'resetVizacomment' });
    dispatch({ type: 'resetVizadate' });
  };

  const scenarioAction = () => {
    makePostScenario();
  };

  return (
    <div className="modal fade show" style={{ display: 'block' }}>
      {showError ? <Error>{answer}</Error> : ''}
      {load ? <Loading /> : ''}

      {user && (
        <div className="modal-dialog m-0">
          <div className="modal-content">
            <div className="modal-body flex align-center justify-center flex-column">
              <div>
                <p className="mt-0 mb-0">Выбран:</p>
                <p className="mt-1 mb-1">{user.f_i_o}</p>
                <p className="mt-4 mb-5">Вы уверены?</p>
              </div>

              <div className="flex mt-5">
                <button className="btn approve" onClick={() => scenarioAction()}>
                  Да
                </button>
                <button className="btn reject ml-2" onClick={() => dispatch({ type: 'setShure' })}>
                  Нет
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
