import axios from 'axios';
import store from '../../store/redux';

import { clearUserData } from './handleAuthData';
import { setUserSsoDataByKeycloakData } from './auth';
import { logSentryError } from './utils';

const Keycloak = {
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  clientSecret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET,
  auth: async function (params) {
    params = {
      ...params,
      client_id: this.clientId,
      client_secret: this.clientSecret,
      grant_type: 'password'
    };
    const data = Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join('&');
    return new Promise((resolve) => {
      axios
        .post(`${this.url}/realms/fsk/protocol/openid-connect/token`, data, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        .then((data) => {
          resolve(data);
          localStorage.setItem('sso_data', JSON.stringify(data.data));
        })
        .catch((error) => {
          resolve(error);
          clearUserData();
        });
    });
  },
  refreshAccessToken: async function () {
    store.dispatch({ type: 'GlOBAL_LOADER', show: true });

    const ssoData = JSON.parse(localStorage.getItem('sso_data'));
    if (ssoData == null) {
      return 'empty_ssodata';
    }

    const params = {
      client_id: this.clientId,
      grant_type: 'refresh_token',
      client_secret: this.clientSecret,
      refresh_token: ssoData.refresh_token
    };

    const data = Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join('&');

    return axios
      .post(`${this.url}/realms/fsk/protocol/openid-connect/token`, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        timeout: 5000
      })
      .then((sso_data) => {
        const status = sso_data.status;
        if (status !== 200) {
          return status;
        }

        const currentTimestamp = Date.now();
        localStorage.setItem('last_updated_sso_key_timestamp', currentTimestamp);

        const ssoData = sso_data.data;
        localStorage.setItem('sso_data', JSON.stringify(ssoData));
        setUserSsoDataByKeycloakData(ssoData);
        return 'success';
      })
      .catch((error) => {
        logSentryError(`error during refreshAccessToken ${error}`);
        return error;
      })
      .finally(() => {
        setTimeout(() => {
          store.dispatch({ type: 'GlOBAL_LOADER', show: false });
        }, 1500);
      });
  }
};

export default Keycloak;
