import axiosAuth from '../../../common/api/axiosAuth';
import { hrApiFullUrl } from '../../../common/api/commonApi';

export const getNews = () => axiosAuth({ url: `${hrApiFullUrl}/news/`, method: 'get' }, 'hr');

export const getSpecialNews = () =>
  axiosAuth({ url: `${hrApiFullUrl}/news/daily/`, method: 'get' }, 'hr');

export const getStaticPagesCategories = (autoLogout = true) =>
  axiosAuth({ url: `${hrApiFullUrl}/static-pages/categories/`, method: 'get' }, 'hr', autoLogout);

export const getStaticPage = (pageID) =>
  axiosAuth({ url: `${hrApiFullUrl}/static-pages/pages/${pageID}/`, method: 'get' }, 'hr');

export const getTemplateCategories = () =>
  axiosAuth({ url: `${hrApiFullUrl}/docs/categories/`, method: 'get' }, 'hr');

export const getTemplateCategory = (categoryID) =>
  axiosAuth({ url: `${hrApiFullUrl}/docs/categories/${categoryID}/`, method: 'get' }, 'hr');

export const getTemplate = (templateID) =>
  axiosAuth({ url: `${hrApiFullUrl}/docs/${templateID}/`, method: 'get' }, 'hr');

export const getLastOrderdTemplates = () =>
  axiosAuth({ url: `${hrApiFullUrl}/docs/orders/`, method: 'get' }, 'hr');

export const CreateReferenceOrder = (data) =>
  axiosAuth({ url: `${hrApiFullUrl}/docs/orders/`, data, method: 'post' }, 'hr');

export const SetLike = (id) =>
  axiosAuth({ url: `${hrApiFullUrl}/articles/${id}/like/`, method: 'post' }, 'hr');

export const getNewsByID = (id) =>
  axiosAuth({ url: `${hrApiFullUrl}/news/${id}/`, method: 'get' }, 'hr');

export const getCommentsByID = (id) =>
  axiosAuth({ url: `${hrApiFullUrl}/articles/${id}/comments/`, method: 'get' }, 'hr');

export const CreateComment = (id, data) =>
  axiosAuth({ url: `${hrApiFullUrl}/articles/${id}/comments/`, data, method: 'post' }, 'hr');

export const getUsersByFilters = (page, value) =>
  axiosAuth(
    { url: `${hrApiFullUrl}/accounts/phonebook/?page=${page}&search=${value}`, method: 'get' },
    'hr'
  );

export const getNotificationsData = () => {
  return axiosAuth({ url: `${hrApiFullUrl}/accounts/notification/`, method: 'get' }, 'hr');
};

export const updateNotificationsData = (data) =>
  axiosAuth({ url: `${hrApiFullUrl}/accounts/notification/`, data, method: 'patch' }, 'hr');
