export const appVersion = '1.58.8';
export const knopkaUserEmailKey = 'knopka_user_email';
export const axiosTimeoutMilliseconds = 15000;
export const askAgainToAllowPushIntervalHours = 8;
export const renewPushSubIntervalSeconds = 1 * 60 * 60;
export const refreshSsoTokenIntervalMilliseconds = 5 * 60 * 1000;
export const expireTokenTimeMarginSeconds = 20;
export const refreshSsoTokenLockTimeoutMilliseconds = 20 * 1000;
export const delayAfterSsoKeyRefreshMilliseconds = 1 * 2000;
// export const sentryDsnKey =
// 'https://19fc6cbe846a492da4c37b91593398cc@o4505193117908992.ingest.sentry.io/4505193135013888';

// for vps test stand
//export const sentryDsnKey =
//  'https://5fa1b43cbc894e22a6293ea914fe02cb@o4504792025726976.ingest.sentry.io/4504849353408513';

// for fsk stands
//export const sentryDsnKey =
//  'https://e030ed87d69945349c1844eb7db07ceb@o4504792025726976.ingest.sentry.io/4504842079830016';

export const shouldBePresentEnvs = [
  'REACT_APP_HR_DJANGO_API',
  'REACT_APP_DJANGO_API',
  'REACT_APP_KEYCLOAK_REALM',
  'REACT_APP_PUSH_LOG_SECRETKEY'
];
