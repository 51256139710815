import axiosAuth from '../../api/axiosAuth';
import { greenApiFullUrl } from '../../api/commonApi';
import { urlB64ToUint8Array } from './utils';

export const getVapidKeyApi = () => {
  return axiosAuth({ url: `${greenApiFullUrl}/webpush/vapid/`, method: 'get' }, 'green').then(
    (data) => {
      return urlB64ToUint8Array(data.data);
    }
  );
};

export const subscribeApi = (data) => {
  return axiosAuth(
    { url: `${greenApiFullUrl}/webpush/subscription/`, data, method: 'post' },
    'green'
  ).then((data) => {
    return data;
  });
};

export const unsubscribeApi = (data) => {
  return axiosAuth(
    { url: `${greenApiFullUrl}/webpush/subscription/`, data, method: 'delete' },
    'green'
  ).then((data) => {
    return data;
  });
};

export const sendDemoPushApi = () => {
  return axiosAuth({ url: `${greenApiFullUrl}/webpush/notify/`, method: 'get' }, 'green').then(
    (data) => {
      return data;
    }
  );
};

export const getPushIsEnabledApi = () => {
  return axiosAuth(
    { url: `${greenApiFullUrl}/accounts/notification/`, method: 'get' },
    'green'
  ).then((data) => {
    return data;
  });
};

export const pausePushApi = () => {
  const data = {};
  return axiosAuth(
    { url: `${greenApiFullUrl}/webpush/subscription/pause/`, data, method: 'post' },
    'green'
  ).then((data) => {
    return data;
  });
};

export const resumePushApi = () => {
  const data = {};
  return axiosAuth(
    {
      url: `${greenApiFullUrl}/webpush/subscription/resume/`,
      data,
      method: 'post'
    },
    'green'
  ).then((data) => {
    return data;
  });
};
