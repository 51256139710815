import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { getTaskById } from '../../api/greenApi';
import { errorMessages } from '../../../../messages';

export default function ExtraActionModal() {
  const dispatch = useDispatch();
  const showExtraAction = useSelector((state) => state.greenButton.showExtraAction);
  //const [load, setLoad] = useState(true);

  const { id } = useParams();
  const [itemLinks, setItemLinks] = useState([]);

  useEffect(() => {
    getTaskById(id)
      .then((resp) => {
        // resp.data.buttons
        if (resp.data.buttons.length > 2) {
          let getdata = resp.data.buttons.slice(2);
          setItemLinks(getdata);
        }
      })
      .catch(() => {
        return new Error(errorMessages.noResponse);
      })
      .finally();
  }, []);

  return (
    <div
      className="modal fade show"
      id="LoadingModal"
      role="dialog"
      style={{ display: showExtraAction }}
    >
      <div className="modal-dialog m-0">
        <div className="modal-content">
          <div className="modal-body flex align-center justify-end flex-column">
            <ul className="m-0 p-0 modal-menu with_arrows">
              {itemLinks.length
                ? itemLinks.map((item, key) => (
                    <li key={key} onClick={() => dispatch({ type: 'closeExtraAction' })}>
                      <Link to={(location) => `${location.pathname}/${item['code']}`}>
                        <i className="icon type-1"></i>
                        {item['name']}
                      </Link>
                    </li>
                  ))
                : ''}
            </ul>
            <span
              onClick={() => dispatch({ type: 'closeExtraAction' })}
              className="cancel cursor-pointer btn mt-4 flex justify-center align-center w-100"
            >
              Отмена
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
