import React from 'react';

import HrClientLayout from '../apps/hr_client/components/HrClientLayout.jsx';
import GreenButtonLayout from '../apps/green_button/components/GreenButtonLayout.jsx';
import BookingsLayout from '../apps/bookings_app/components/BookingsLayout';

import GreenButtonRoutes from './SubRoutes/GreenButtonRoutes.jsx';
import HrClientRoutes from './SubRoutes/HrClientRoutes.jsx';
import BookingsRoutes from './SubRoutes/BookingsRoutes';

const Layouts = [
  {
    path: '/hr',
    element: <HrClientLayout />,
    subElements: HrClientRoutes
  },
  {
    path: '/inbox',
    element: <GreenButtonLayout />,
    subElements: GreenButtonRoutes
  },
  {
    path: '/bookings',
    element: <BookingsLayout />,
    subElements: BookingsRoutes
  }
];

export default Layouts;
