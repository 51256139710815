import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as OkIcon } from '../../assets/images/icons/success-icon.svg';

import './modals.scss';

export default function Success({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Modal = useSelector((state) => state.modal);
  const HandleClose = () => {
    dispatch({ type: 'SUCCESS_MODAL', ...{ show: false, text: '', path: '/hr/' } });
    navigate(Modal.path);
  };

  return (
    Modal &&
    Modal.type === 'SUCCESS_MODAL' &&
    Modal.show && (
      <div
        className="modal fade show success-modal"
        id="success-modal"
        role="dialog"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <OkIcon />
              <p>{Modal.text ? Modal.text : 'Все ок!'}</p>
              <div onClick={() => HandleClose()}>
                <button className="btn return mt-5">Назад</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
