export default function timeLineScrollToHour(
  parentDivSelector,
  verticalBarTimeSelector,
  timeStart,
  timeEnd,
  multiplVerticalBarTime = false
) {
  let parentDiv = document.querySelector(parentDivSelector);

  let verticalBarTime;
  if (multiplVerticalBarTime) {
    verticalBarTime = document.querySelectorAll(verticalBarTimeSelector);
  } else {
    verticalBarTime = document.querySelector(verticalBarTimeSelector);
  }

  if (multiplVerticalBarTime) {
    if (!verticalBarTime.length) return;
  } else {
    if (!verticalBarTime) return;
  }

  let parentDivWidth = parentDiv.offsetWidth;

  let timeStartHour = 8;
  let timeEndHour = 20;

  if (timeStart) {
    timeStartHour = timeStart.split(':')[0];
  }
  if (timeEnd) {
    timeEndHour = timeEnd.split(':')[0] - 1;
  }

  const timeRangeHours = timeEndHour - timeStartHour + 1;

  const sliderTimeItemWidth = parentDivWidth / timeRangeHours;

  const date = new Date();
  let currentHour = date.getHours();

  const startTimeString = `${timeStartHour}:00`;
  const endTimeString = `${timeEndHour + 1}:00`;
  const currentMin = date.getMinutes();
  const currentMinString = currentMin < 10 ? `0${currentMin}` : currentMin;

  let currentTimeString = `${currentHour}:${currentMinString}`;
  if (currentHour < timeStartHour || currentHour > timeEndHour) {
    currentTimeString = startTimeString;
  }

  // Convert time strings to Date objects
  const startDate = new Date(`2000-01-01T${startTimeString}:00`);
  const endDate = new Date(`2000-01-01T${endTimeString}:00`);

  // Convert time strings to minutes since midnight
  const startMinutes = startDate.getHours() * 60 + startDate.getMinutes();
  const endMinutes = endDate.getHours() * 60 + endDate.getMinutes();

  const currentTime = new Date(`2000-01-01T${currentTimeString}:00`);
  const currentMinutes = currentTime.getHours() * 60 + currentTime.getMinutes();

  // Calculate the total minutes between start and end time
  const totalMinutes = endMinutes - startMinutes;
  // Calculate the minutes between start time and current time
  const elapsedMinutes = currentMinutes - startMinutes;
  // Calculate the percentage of time that has passed

  let passedPercentage = (elapsedMinutes / totalMinutes) * 100;
  passedPercentage = passedPercentage.toFixed(2);

  const currentRangeWidth = sliderTimeItemWidth * timeRangeHours;

  if (multiplVerticalBarTime) {
    verticalBarTime.forEach((element) => {
      element.style.left = (passedPercentage / 100) * currentRangeWidth + 'px';
    });
  } else {
    verticalBarTime.style.left = (passedPercentage / 100) * currentRangeWidth + 'px';
  }
}
