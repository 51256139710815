import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
registerLocale('ru', ru);

import { getUsersData, CreateSchedule } from '../../api/bookingApi';
import { useTheme } from '../../../../common/hooks/useTheme';

import InlineLoader from './InlineLoader';
import Loader from './loader';
import { getFirstLetterFromString, minTwoDigits } from '../../lib/mixing';

import '../../assets/css/modals.css';

const scheduleModal = () => {
  const { theme } = useTheme();
  const dateSelected = useSelector((state) => state.bookings.date);
  const [date, setDate] = useState(new Date());
  useEffect(() => {
    if (dateSelected) {
      setDate(dateSelected);
    }
  }, [dateSelected]);
  const [repeatDateStart, setRepeatDateStart] = useState(new Date());
  const [repeatDateEnd, setRepeatDateEnd] = useState(new Date());
  const [RepeatNumber, setRepeatNumber] = useState(1);

  const [modalData, setModalData] = useState({
    room: '',
    scheduleModalShow: false,
    StartTime: '',
    EndTime: '',
    schedules: [],
    Busytimes: [],
    Name: '',
    Description: ''
  });

  const [isRepeatschedules, setIsRepeatschedules] = useState(false);
  const [RepeatType, setRepeatType] = useState('days');
  const [isOrganizerInitiatorSame, setIsOrganizerInitiatorSame] = useState(true);
  const [isFormDisable, isSetFormDisable] = useState(true);
  const dispatch = useDispatch();

  const [endTimes, setEndTimes] = useState();
  const [userData, setUserData] = useState();
  const [membersData, setMembersData] = useState();
  const [initiatorsData, setInitiatorsData] = useState();
  const [selectedInitiator, setSelectedInitiator] = useState();
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [externalMembers, setExternalMembers] = useState([]);
  const [formsErrors, setFormsErrors] = useState([]);
  const [FormData, setFormData] = useState({});
  const userMail = useSelector((state) => state.bookings.userData.mail);
  const memberInput = React.createRef();
  const externalMemberInput = React.createRef();
  const InitiatorInput = React.createRef();

  const scheduleModalStates = useSelector((state) => state.bookings.scheduleModalData);
  useEffect(() => {
    if (scheduleModalStates.show) {
      setModalData({
        room: scheduleModalStates.room,
        scheduleModalShow: scheduleModalStates.show,
        StartTime: scheduleModalStates.startTime,
        EndTime: scheduleModalStates.endTime,
        schedules: scheduleModalStates.schedules,
        Busytimes: scheduleModalStates.Busytimes,
        Name: scheduleModalStates.Name,
        Description: scheduleModalStates.Description
      });
    }
  }, [scheduleModalStates]);

  // wheninit
  useEffect(() => {
    if (modalData.scheduleModalShow) {
      const obj = {
        start: modalData.StartTime,
        end: modalData.EndTime,
        room: modalData.room,
        organizer: userMail,
        required_attendees: []
      };
      setEndTimesHandle(obj);
      setFormData(obj);
      // initUserData
      if (userMail) {
        getUsersData({ search: userMail }).then((resp) => {
          setUserData(resp.data[0]);
        });
      }
    }
  }, [modalData.StartTime, modalData.room, userMail]);

  const setData = (newData) => {
    setFormData({
      ...FormData,
      ...newData
    });
  };

  useEffect(() => {
    if (isOrganizerInitiatorSame && Object.keys(FormData).length > 0) {
      setData({ ...FormData, organizer: userMail });
    } else {
      setSelectedInitiator(false);
    }
  }, [isOrganizerInitiatorSame]);

  const handleCloseModal = () => {
    dispatch({ type: 'SetScheduleModalData', scheduleModalData: { show: false } });
  };

  const dropDownHandle = (obj, action = '') => {
    if (action === 'startAction') {
      setEndTimesHandle(obj);
    }
    setData(obj);
  };

  const MembersDataHandel = (event) => {
    getUsersData({ search: event.target.value }).then((resp) => {
      setMembersData(resp.data);
    });
  };
  const MembersListHandle = (member) => {
    memberInput.current.value = '';
    const newMembers = [];
    if (FormData.required_attendees) {
      FormData.required_attendees.map((attendees) => newMembers.push(attendees));
    }
    newMembers.push(member.email);
    setSelectedMembers([...selectedMembers, member]);
    setData({ required_attendees: newMembers });
  };
  const MemberRemove = (selectedMember) => {
    const filtredMembers = selectedMembers.filter(
      (member) => member.email !== selectedMember.email
    );
    setSelectedMembers(filtredMembers);
    const members = [];
    filtredMembers.map((member) => members.push(member.email));
    externalMembers.map((member) => members.push(member));
    setData({ required_attendees: members });
  };

  const InitiatorDataHandel = (event) => {
    getUsersData({ search: event.target.value }).then((resp) => {
      setInitiatorsData(resp.data);
    });
  };

  const InitiatorSelectorHandel = (member) => {
    InitiatorInput.current.value = '';
    setSelectedInitiator(member);
    setData({ organizer: member.email });
  };

  const setEndTimesHandle = (obj) => {
    const firstBusyTime = modalData.Busytimes.filter(
      (time) =>
        parseFloat(String(time).replace(':', '.')) > parseFloat(String(obj.start).replace(':', '.'))
    ).shift();
    const newEndTimes = modalData.schedules
      .filter(
        (time) =>
          parseFloat(String(time.time).replace(':', '.')) >
            parseFloat(String(obj.start).replace(':', '.')) &&
          (firstBusyTime === undefined
            ? true
            : parseFloat(String(time.time).replace(':', '.')) <=
              parseFloat(String(firstBusyTime).replace(':', '.')))
      )
      .map((time) => time.time);
    if (firstBusyTime === undefined) {
      newEndTimes.push('21:00');
    }

    setEndTimes(newEndTimes);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const AddExternalMember = (event) => {
    event.preventDefault();
    let mail = externalMemberInput.current.value;
    if (mail.length > 0) {
      if (validateEmail(mail)) {
        setExternalMembers([...externalMembers, externalMemberInput.current.value]);
      } else {
        setFormsErrors([...formsErrors, 'external_members']);
      }
    }
  };

  const RemoveExternalMember = (email) => {
    const filtredMembers = externalMembers.filter(
      (memberMail) => memberMail.toLowerCase() !== email.toLowerCase()
    );
    setExternalMembers(filtredMembers);
    setData({ required_attendees: FormData.required_attendees.filter((item) => item !== email) });
  };

  useEffect(() => {
    if (externalMembers && externalMembers.length > 0) {
      if (externalMemberInput.current) {
        externalMemberInput.current.value = '';
      }
      const newMembers = [];
      if (FormData.required_attendees) {
        selectedMembers.map((attendees) => newMembers.push(attendees.email));
      }
      externalMembers.map((member) => newMembers.push(member));
      setData({ required_attendees: newMembers });
    }
  }, [externalMembers]);

  const getDateTime = (hours, minutes) => {
    let newdate = new Date(date);
    newdate.setHours(parseFloat(hours));
    newdate.setMinutes(parseFloat(minutes));
    newdate.setSeconds(0);
    return newdate.toISOString();
  };

  const getDate = (dateData) => {
    let newdate = new Date(dateData);
    const [month, day, year] = [
      minTwoDigits(newdate.getMonth() + 1),
      newdate.getDate(),
      newdate.getFullYear()
    ];
    return `${year}-${month}-${day}`;
  };

  const createschedule = (event) => {
    event.preventDefault;
    let mail = externalMemberInput.current.value;
    if (mail.length > 0) {
      if (validateEmail(mail)) {
        setExternalMembers([...externalMembers, externalMemberInput.current.value]);
        FormData.required_attendees.push(externalMemberInput.current.value);
      }
    }
    const newFormData = {};
    Object.assign(newFormData, FormData);
    newFormData.start = getDateTime(FormData.start.split(':')[0], FormData.start.split(':')[1]);
    newFormData.end = getDateTime(FormData.end.split(':')[0], FormData.end.split(':')[1]);

    dispatch({ type: 'ShowLoader' });

    if (!isRepeatschedules) {
      CreateSchedule(newFormData)
        .then((resp) => {
          toast.success('Успешно создано', { id: Math.random, position: 'top-right' });
        })
        .catch((error) => {
          logSentryError(`Error createschedule ${error}`);
        })
        .finally(() => {
          dispatch({ type: 'HideLoader' });
          dispatch({ type: 'RefreshMeetings', action: true });
          handleCloseModal();
        });
    } else {
      newFormData.start_date = getDate(repeatDateStart);
      newFormData.end_date = getDate(repeatDateEnd);
      newFormData.pattern = RepeatType;
      newFormData.number = RepeatNumber;
    }
  };

  const RemoveFormError = (type) => {
    if (formsErrors.length > 0 && formsErrors.includes(type)) {
      setFormsErrors([formsErrors.filter((item) => item !== type)]);
    }
  };

  useEffect(() => {
    if (FormData) {
      if (
        FormData.start !== '' &&
        FormData.end !== '' &&
        FormData.room !== '' &&
        FormData.organizer !== ''
      ) {
        isSetFormDisable(false);
      } else {
        isSetFormDisable(true);
      }
    }
  }, [FormData]);

  useEffect(() => {
    setFormsErrors([]);
    setExternalMembers([]);
    setSelectedMembers([]);
  }, [modalData.scheduleModalShow]);

  const onChangeExternalMembers = () => {
    RemoveFormError('external_members');
  };

  return (
    <Modal
      show={modalData.scheduleModalShow}
      onHide={handleCloseModal}
      backdrop="static"
      keyboard={false}
      id="schedule_modal"
      className={[theme === 'light' ? 'white-theme' : '', 'meetings_booking'].join(' ')}
    >
      <Modal.Header closeButton className="mb-0">
        <Modal.Title>Бронирование: {modalData.Name}</Modal.Title>
        <p className="light-color">{modalData.Description}</p>
      </Modal.Header>

      <Modal.Body>
        <form>
          <Row>
            <Col className="px-3">
              <div className="d-flex flex-column">
                <div className="wrap-element">
                  {
                    // eslint-disable-next-line jsx-a11y/label-has-associated-control
                    <label className="btn py-0 d-flex justify-content-between align-items-center">
                      <DatePicker
                        locale="ru"
                        selected={date}
                        onChange={(date) => setDate(date)}
                        dateFormat="dd.MM.yyyy"
                      />
                      <span className="icon calendar"></span>
                    </label>
                  }
                </div>

                <div className="d-flex justify-content-between align-items-center py-2 time_selectors">
                  <div className="d-flex justify-content-start align-items-center col-md-6">
                    <div className="">
                      <span className="px-2 light-color">с</span>
                    </div>
                    <div className="wrap-element">
                      <Dropdown>
                        <Dropdown.Toggle
                          id="dropdown-by-name"
                          className={
                            'btn col-md-12 text-start d-flex justify-content-between align-items-center'
                          }
                        >
                          <span>{FormData.start}</span>
                          <span className="icon caret"></span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="list">
                          {modalData?.schedules &&
                            modalData?.schedules
                              // .filter(time => time != Freetimes.slice(-1).pop())
                              .map((time, key) => (
                                <Dropdown.Item
                                  key={key}
                                  className={time.is_busy ? 'busy' : 'free'}
                                  title={time.is_busy ? 'Занять' : ''}
                                  onClick={(e) =>
                                    time.is_busy === false
                                      ? dropDownHandle({ start: time.time, end: '' }, 'startAction')
                                      : ''
                                  }
                                >
                                  {time.time}
                                </Dropdown.Item>
                              ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center col-md-6">
                    <div className="light-color px-2">до</div>
                    <div className="wrap-element">
                      <Dropdown>
                        <Dropdown.Toggle
                          id="dropdown-by-name"
                          className={
                            'btn col-md-12 text-start d-flex justify-content-between align-items-center'
                          }
                        >
                          <span>{FormData.end}</span>
                          <span className="icon caret"></span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="list">
                          {endTimes &&
                            endTimes.map((time, key) => (
                              <Dropdown.Item
                                key={key}
                                onClick={(e) => dropDownHandle({ end: time })}
                              >
                                {time}
                              </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>

                {isRepeatschedules && (
                  <div className="d-none">
                    {
                      // eslint-disable-next-line jsx-a11y/interactive-supports-focus
                      <div
                        className="py-3 d-flex align-items-center noselect"
                        role="button"
                        onClick={() => setIsRepeatschedules(!isRepeatschedules)}
                      >
                        <div
                          className={[
                            isRepeatschedules ? 'active' : '',
                            ' checkbox mr-2 inline'
                          ].join(' ')}
                        ></div>
                        <span className="light-color">Сделать встречу повторяющейся</span>
                      </div>
                    }

                    {isRepeatschedules && (
                      <div className="block-group wrap-element my-2 px-3 col-md-12">
                        <div className="d-flex m-2">
                          <div className="d-flex justify-content-start align-items-center col-md-6">
                            Дата начало:
                          </div>

                          <div className="wrap-element">
                            {
                              // eslint-disable-next-line jsx-a11y/label-has-associated-control
                              <label className="btn py-0 d-flex justify-content-between align-items-center">
                                <DatePicker
                                  locale="ru"
                                  selected={repeatDateStart}
                                  onChange={(date) => setRepeatDateStart(date)}
                                  dateFormat="dd.MM.yyyy"
                                />
                                <span className="icon calendar"></span>
                              </label>
                            }
                          </div>
                        </div>

                        <div className="d-flex m-2">
                          <div className="d-flex justify-content-start align-items-center col-md-6">
                            Дата завершения:
                          </div>

                          <div className="wrap-element">
                            {
                              // eslint-disable-next-line jsx-a11y/label-has-associated-control
                              <label className="btn py-0 d-flex justify-content-between align-items-center">
                                <DatePicker
                                  locale="ru"
                                  selected={repeatDateEnd}
                                  onChange={(date) => setRepeatDateEnd(date)}
                                  dateFormat="dd.MM.yyyy"
                                />
                                <span className="icon calendar"></span>
                              </label>
                            }
                          </div>
                        </div>

                        <div className="d-flex m-2">
                          <ul className="list-unstyled m-0">
                            <li className="py-1">
                              <label
                                htmlFor="every-day"
                                className="d-flex align-items-center"
                                onClick={(e) => setRepeatType('days')}
                              >
                                <span
                                  className={[
                                    'radio-button inline',
                                    RepeatType === 'days' ? 'active' : ''
                                  ].join(' ')}
                                >
                                  <input type="radio" name="user-subsriptions" id="every-day" />
                                </span>
                                Ежедневно
                              </label>
                            </li>

                            <li className="py-1">
                              <label
                                htmlFor="every-week"
                                className="d-flex align-items-center"
                                onClick={(e) => setRepeatType('weeks')}
                              >
                                <span
                                  className={[
                                    'radio-button inline',
                                    RepeatType === 'weeks' ? 'active' : ''
                                  ].join(' ')}
                                >
                                  <input type="radio" name="user-subsriptions" id="every-week" />
                                </span>
                                Еженедельно
                              </label>
                            </li>

                            <li className="py-1">
                              <label
                                htmlFor="every-months"
                                className="d-flex align-items-center"
                                onClick={(e) => setRepeatType('months')}
                              >
                                <span
                                  className={[
                                    'radio-button inline',
                                    RepeatType === 'months' ? 'active' : ''
                                  ].join(' ')}
                                >
                                  <input type="radio" name="user-subsriptions" id="every-months" />
                                </span>
                                Ежемесячно
                              </label>
                            </li>

                            <li className="py-1">
                              <label
                                htmlFor="every-year"
                                className="d-flex align-items-center"
                                onClick={(e) => setRepeatType('years')}
                              >
                                <span
                                  className={[
                                    'radio-button inline',
                                    RepeatType === 'years' ? 'active' : ''
                                  ].join(' ')}
                                >
                                  <input type="radio" name="user-subsriptions" id="every-year" />
                                </span>
                                Ежегодно
                              </label>
                            </li>
                          </ul>
                        </div>

                        <div className="d-flex m-2 justify-content-between align-items-center">
                          Кажд.
                          <div className="wrap-element px-2 py-0">
                            <input
                              type="text"
                              value={RepeatNumber}
                              onChange={(e) => setRepeatNumber(e.target.value)}
                            />
                          </div>
                          дн.
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div className="wrap-element my-2">
                  <div className="py-0 d-flex justify-content-between align-items-center">
                    <input
                      type="text"
                      placeholder="Тема"
                      className="px-3 py-2"
                      onChange={(e) => setData({ subject: e.target.value })}
                    />
                  </div>
                </div>

                <div className="wrap-element my-2">
                  <textarea
                    placeholder="Описание"
                    className="px-3 py-2"
                    onChange={(e) => setData({ body: e.target.value })}
                  ></textarea>
                </div>

                <div className="disable d-none">
                  <div className="block-group">
                    <div className="py-3 d-flex align-items-center justify-content-between title-box position-relative mb-3">
                      <div className="title-text">Документы</div>
                      <button className="icon warning btn"></button>
                    </div>

                    <div className="file-item d-flex align-items-center justify-content-between position-relative py-2 px-2 mb-2">
                      <div className="file-image">
                        <div className="image"></div>
                      </div>
                      <div className="file-info">
                        <div>Карта местности.pdf</div>
                        <div className="light-color mt-1">20 mb</div>
                      </div>
                      <div className="icon close-small position-absolute"></div>
                    </div>

                    <div className="wrap-element wih-orrange-border my-3">
                      <button className="btn load-files">Загрузить документ</button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col className="px-3">
              <div className="user-item d-flex align-items-center justify-content-between position-relative py-2 px-2 mb-2">
                {userData ? (
                  <React.Fragment>
                    <div className="user-image">
                      <div className="text">
                        <div className="text-label text-uppercase">
                          {getFirstLetterFromString(
                            `${userData.first_name + ' ' + userData.last_name}`,
                            2
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="user-info">
                      <div>{`${userData.first_name} ${userData.last_name}`}</div>
                      <div className="light-color mt-1">{userData.position}</div>
                    </div>
                  </React.Fragment>
                ) : (
                  <InlineLoader size={'small'} />
                )}
              </div>

              <div className="block-group">
                <div className="py-3 d-flex align-items-center justify-content-between title-box position-relative">
                  <div className="title-text">Инициатор</div>
                </div>
              </div>

              {
                // eslint-disable-next-line jsx-a11y/interactive-supports-focus
                <div
                  className="py-3 d-flex align-items-center"
                  role="button"
                  onClick={() => setIsOrganizerInitiatorSame(!isOrganizerInitiatorSame)}
                >
                  <div
                    className={[
                      isOrganizerInitiatorSame ? 'active' : '',
                      ' checkbox mr-2 inline'
                    ].join(' ')}
                  ></div>
                  <span className="light-color">Организатор и инициатор совпадают</span>
                </div>
              }

              {!isOrganizerInitiatorSame && (
                <div className="block-group">
                  <div className="d-flex justify-content-start align-items-center w-100 wrap-element mb-2">
                    <Dropdown className="w-100">
                      <Dropdown.Toggle
                        id="dropdown-by-members"
                        className={
                          'btn w-100 text-start d-flex justify-content-between align-items-center p-0'
                        }
                      >
                        <input
                          type="text"
                          className="px-3"
                          placeholder="Введите Инициатор"
                          onChange={(event) => InitiatorDataHandel(event)}
                          ref={InitiatorInput}
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {initiatorsData &&
                          initiatorsData.map((member, key) => (
                            <Dropdown.Item
                              key={key}
                              onClick={(event) => InitiatorSelectorHandel(member)}
                            >
                              <div className="user-item d-flex align-items-center justify-content-between position-relative py-2 px-2 mb-2">
                                <div className="user-image">
                                  <div className="text">
                                    <div className="text-label text-uppercase">
                                      {getFirstLetterFromString(
                                        `${member.first_name + ' ' + member.last_name}`,
                                        2
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="user-info">
                                  <div>{`${member.first_name} ${member.last_name}`}</div>
                                  <div className="light-color mt-1">{member.position}</div>
                                </div>
                              </div>
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  {selectedInitiator && (
                    <div className="user-item d-flex align-items-center justify-content-between position-relative py-2 px-2 mb-2">
                      <div className="user-image">
                        <div className="text">
                          <div className="text-label text-uppercase">
                            {getFirstLetterFromString(
                              `${selectedInitiator.first_name + ' ' + selectedInitiator.last_name}`,
                              2
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="user-info">
                        <div>{`${selectedInitiator.first_name} ${selectedInitiator.last_name}`}</div>
                        <div className="light-color mt-1">{selectedInitiator.position}</div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className="block-group users-content">
                <div className="block-group">
                  <div className="py-3 d-flex align-items-center justify-content-between title-box position-relative mb-3">
                    <div className="title-text">Сотрудники</div>
                  </div>

                  <div className="d-flex justify-content-start align-items-center w-100 wrap-element mb-2">
                    <Dropdown className="w-100">
                      <Dropdown.Toggle
                        id="dropdown-by-members"
                        className={
                          'btn w-100 text-start d-flex justify-content-between align-items-center p-0'
                        }
                      >
                        <input
                          type="text"
                          className="px-3"
                          placeholder="Введите участник"
                          onChange={(event) => MembersDataHandel(event)}
                          ref={memberInput}
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {membersData &&
                          membersData.map((member, key) => (
                            <Dropdown.Item key={key} onClick={(event) => MembersListHandle(member)}>
                              <div className="user-item d-flex align-items-center justify-content-between position-relative py-2 px-2 mb-2">
                                <div className="user-image">
                                  <div className="text">
                                    <div className="text-label text-uppercase">
                                      {getFirstLetterFromString(
                                        `${member.first_name + ' ' + member.last_name}`,
                                        2
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="user-info">
                                  <div>{`${member.first_name} ${member.last_name}`}</div>
                                  <div className="light-color mt-1">{member.position}</div>
                                </div>
                              </div>
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="vertical-scroll">
                    {selectedMembers &&
                      selectedMembers.map((member, index) => (
                        <div
                          className="user-item d-flex align-items-center justify-content-between position-relative py-2 px-2 mb-2"
                          key={index}
                        >
                          <div className="user-image">
                            <div className="text">
                              <div className="text-label text-uppercase">
                                {getFirstLetterFromString(
                                  `${member.first_name + ' ' + member.last_name}`,
                                  2
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="user-info">
                            <div>{`${member.first_name} ${member.last_name}`}</div>
                            <div className="light-color mt-1">{member.position}</div>
                          </div>
                          <div
                            className="icon close-small position-absolute"
                            onClick={(e) => MemberRemove(member)}
                          ></div>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="block-group">
                  <div className="py-3 d-flex align-items-center justify-content-between title-box position-relative mb-3">
                    <div className="title-text">Гости</div>
                  </div>

                  <div className="vertical-scroll">
                    <ul className="list-unstyled">
                      {externalMembers.map((mail, index) => (
                        <li
                          className="pb-1 d-flex justify-content-flex-start align-items-center"
                          key={index}
                        >
                          <span>{mail}</span>
                          <div
                            className="icon close-small mx-3"
                            onClick={(e) => RemoveExternalMember(mail)}
                          ></div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div
                    className={[
                      'd-flex justify-content-start align-items-center col-md-12 wrap-element position-relative',
                      formsErrors.includes('external_members') ? 'error' : ''
                    ].join(' ')}
                  >
                    <input
                      type="text"
                      className="col-md-12 px-3"
                      placeholder="E-mail участника"
                      ref={externalMemberInput}
                      onChange={onChangeExternalMembers}
                    />
                    <button
                      className="d-inline-block add-external-user"
                      onClick={AddExternalMember}
                    >
                      +{' '}
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex flex-wrap">
          <div className="wrap-element wih-orrange-border mx-1">
            <button className="btn" onClick={(e) => handleCloseModal()}>
              Отмена
            </button>
          </div>

          <div className="wrap-element wih-orrange-border mx-3">
            <button
              className="btn orrange-button"
              onClick={createschedule}
              disabled={isFormDisable}
            >
              Забронировать
            </button>
          </div>
        </div>
      </Modal.Footer>
      <Loader />
    </Modal>
  );
};

export default scheduleModal;
