import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from 'redux-thunk';
const middleware = [thunk];

import createRootReducer from './reducers';

// const initialState = {};
const store = createStore(createRootReducer, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
