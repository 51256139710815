import React from 'react';

const InlineLoader = ({ size = ' ' }) => {
  return (
    <div className={[size, 'inline-loading-block'].join(' ')}>
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export default InlineLoader;
