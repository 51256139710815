import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { logSentryMessage } from '../lib/utils';

import fullLogoFsk from '../assets/images/full_logo_fsk.svg';

import './NotFoundPage.css';

export default function NotFoundPage() {
  const location = useLocation();
  const [showLinkToMain, setShowLinkToMain] = useState(false);

  const waitUntilShowLinkToMainMilsec = 30 * 1000;

  useEffect(() => {
    logSentryMessage(`page not found ${location.pathname}`);

    setTimeout(() => {
      setShowLinkToMain(true);
    }, waitUntilShowLinkToMainMilsec);
  }, []);

  return (
    <>
      <main className="page-container">
        <img className="full-logo-fsk mb-4" src={fullLogoFsk} alt="" />

        <p className="">
          Идет проверка доступа
          <span className="loader-dot">.</span>
          <span className="loader-dot">.</span>
          <span className="loader-dot">.</span>
        </p>
      </main>

      {showLinkToMain && (
        <a className="go-to-main-link" href="/">
          Перейти на главную
        </a>
      )}
    </>
  );
}
