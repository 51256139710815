import { axiosInstance } from './axiosInstance';
import { hardLogout } from '../lib/auth';
import { getUserLocalData } from '../lib/handleAuthData';
import { logSentryError, logSentryMessage } from '../lib/utils';
import { expireTokenTimeMarginSeconds, refreshSsoTokenLockTimeoutMilliseconds } from '../../config';

import toast from 'react-hot-toast';

import { refreshSsoAccessTokenByRefreshToken } from '../lib/auth';

const logWithToast = (text = '') => {
  toast(
    (t) => (
      <span>
        {text}
        <button onClick={() => toast.dismiss(t.id)}>Закрыть</button>
      </span>
    ),
    {
      icon: '⚠️',
      duration: 60000
    }
  );
  logSentryMessage(text);
};

const axiosAuth = async (data, app = undefined, autoLogout = true) => {
  const request_instance = Math.floor(Math.random() * 100);

  let checkLastUpdatedTokenFlag = true;
  let currentTimestamp;

  // Prevent token update from concurrent axios requests (lock via sso_key_updating_started_timestamp)
  let ssoKeyOngoingUpdateTimestamp = localStorage.getItem('sso_key_updating_started_timestamp');
  if (ssoKeyOngoingUpdateTimestamp) {
    ssoKeyOngoingUpdateTimestamp = parseInt(ssoKeyOngoingUpdateTimestamp, 10);
    currentTimestamp = Date.now();
    const passedMilliseconds = currentTimestamp - ssoKeyOngoingUpdateTimestamp;
    if (passedMilliseconds > refreshSsoTokenLockTimeoutMilliseconds) {
      // Failed update token process detected
      localStorage.removeItem('sso_key_updating_started_timestamp');
    } else {
      checkLastUpdatedTokenFlag = false;

      let wait = false;
      while (!wait) {
        await new Promise((resolve) => setTimeout(resolve, 100));
        // Check the condition and update it if necessary
        wait = localStorage.getItem('sso_key_updating_started_timestamp') === null;
      }
    }
  }

  const lastUpdatedSsoKeyTimestamp = parseInt(
    localStorage.getItem('last_updated_sso_key_timestamp'),
    10
  );

  currentTimestamp = Date.now();
  const passedMilliseconds = currentTimestamp - lastUpdatedSsoKeyTimestamp;

  const ssoData = JSON.parse(localStorage.getItem('sso_data'));
  let ssoTokenExpiresInMilliseconds = (ssoData?.expires_in - expireTokenTimeMarginSeconds) * 1000;
  const keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL;
  if (keycloakUrl === 'https://sso.dev.fsk.ru/auth') {
    ssoTokenExpiresInMilliseconds = 60 * 1000;
  }

  if (checkLastUpdatedTokenFlag) {
    if (!lastUpdatedSsoKeyTimestamp || passedMilliseconds > ssoTokenExpiresInMilliseconds) {
      localStorage.setItem('sso_key_updating_started_timestamp', currentTimestamp);

      const refreshTokenResult = await refreshSsoAccessTokenByRefreshToken();

      if (refreshTokenResult?.config) delete refreshTokenResult?.config;

      if (refreshTokenResult === 'success') {
        localStorage.removeItem('sso_key_updating_started_timestamp');
      } else if (refreshTokenResult === 'empty_ssodata') {
        logWithToast('Вы не авторизованы');
        hardLogout();
      } else if (refreshTokenResult?.response?.status === 401) {
        logWithToast('Устаревший токен (401)');
        hardLogout();
      } else if (refreshTokenResult?.response?.status === 400) {
        logWithToast('Устаревший токен (400)');
        hardLogout();
      } else if (refreshTokenResult.response?.status === 500) {
        logWithToast('Ошибка 500');
      } else if (refreshTokenResult?.name === 'AxiosError') {
        logWithToast(`Сетевая ошибка ${JSON.stringify(refreshTokenResult)}`);
      } else {
        logWithToast(`Неизвестная Ошибка ${JSON.stringify(refreshTokenResult)}`);
      }
    }
  }

  return axiosInstance(data, app)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      const unauthorizedStatus = 401;
      const userLocalData = getUserLocalData();

      if (!e.response) {
        logSentryError(`axiosAuth network error, no response ${e}`);
        console.info(`Возникла ошибка: ${e.message}`, {
          id: Math.random,
          position: 'top-right',
          style: { marginRight: '40px' }
        });
        return;
      }

      if (autoLogout && e.response.status === unauthorizedStatus) {
        // console.info('Вы не авторизованы в системе', {id: Math.random, position: 'top-right', style: {marginRight: '40px'}});
        logSentryError('axiosAuth error 401');
        logSentryError(e);

        if (userLocalData.ssoUserKey || userLocalData.hrUserKey || userLocalData.greenUserKey) {
          logWithToast('Ошибка 401');
        }
        return;
      }

      if (e.response.status === 403) {
        console.info('Чтение данной страницы ограничено');
        logSentryError(`Чтение данной страницы ограничено ${e}`);
      }

      if (!e.response.data) {
        logSentryError('axiosAuth network error, no data');

        console.info('Не удалось загрузить данные', {
          id: Math.random,
          position: 'top-right',
          style: { marginRight: '40px' }
        });
      }
    });
};

export default axiosAuth;
