import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getAllUsers } from '../api/greenApi';
import { errorMessages } from '../../../messages';

import { ReactComponent as LeftArrowIcon } from '../assets/images/icons/leftArrowIcon.svg';
import { ReactComponent as UnderLineIcon } from '../assets/images/icons/underLineIcon.svg';
import { ReactComponent as DialHandIcon } from '../assets/images/icons/dialHandIcon.svg';
import { ReactComponent as ReloadIcon } from '../assets/images/icons/reloadIcon.svg';

import Loading from '../../../common/components/UI/Loading';
import Shure from '../components/UI/Shure.jsx';

export default function UserList() {
  const [searchfield, setSearchfield] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [ordering, setOrdering] = useState('f_i_o');
  const [users, setUsers] = useState([]);
  const [load, setLoad] = useState(true);
  const [currentUser, setCurrentUser] = useState({});

  const dispatch = useDispatch();
  const showShure = useSelector((state) => state.greenButton.showShure);
  const navigate = useNavigate();

  const searchReference = useRef(null);

  useEffect(() => {
    getAllUsers(searchInput, ordering)
      .then((resp) => {
        const allUsers = resp.data;
        setUsers(allUsers);
        setLoad(false);
      })
      .catch(() => {
        setLoad(false);
        return new Error(errorMessages.noResponse);
      });
  }, [setUsers, setSearchfield, setLoad, setSearchInput, searchInput, ordering]);

  const activeSearch = () => {
    setSearchfield(!searchfield);
    setSearchInput('');
  };

  const handleSearch = (event) => {
    setSearchInput(event.target.value);
    setTimeout(() => {
      searchReference.current.focus();
    }, 50);
  };

  return (
    <>
      {load ? <Loading /> : ''}

      <div
        className={
          searchfield
            ? 'top-content-block flex align-center space-between py-3 px-3 search-activated'
            : 'top-content-block flex align-center space-between py-3 px-3'
        }
      >
        <div className="flex align-center space-between w100">
          <div className="flex align-center">
            <span onClick={() => navigate(-1)} className="cursor-pointer">
              <LeftArrowIcon />
            </span>
            <div className="h5 active-page m-0">Выбор пользователя</div>
          </div>
          <div className="search-by-name-field">
            <input
              type="text"
              name=""
              placeholder="Введите текст..."
              value={searchInput}
              onChange={(e) => handleSearch(e)}
              ref={searchReference}
            />
            <div className="close" onClick={() => activeSearch()}></div>
          </div>
        </div>

        <div className="flex align-center justify-center icons">
          <span className="mx-2">
            <UnderLineIcon />
          </span>
          <span className="mx-2 cursor-pointer" onClick={() => activeSearch()}>
            <DialHandIcon />
          </span>
          <span className="mx-2">
            <ReloadIcon />
          </span>
        </div>
      </div>

      <div className="container p-0 pt-3 data-container-bg hide-scroll-bar">
        <ul className="px-0">
          <li>
            <ul className="table d-flex m-0 p-0 pb-2 title">
              <li
                className={[
                  ordering.includes('otdel') ? 'active noselect' : 'noselect',
                  'flex-1',
                  'text-center'
                ].join(' ')}
                onClick={() =>
                  ordering === 'otdel' ? setOrdering('-f_i_o') : setOrdering('f_i_o')
                }
              >
                Отдел&nbsp;
                <span className={ordering.includes('otdel') ? 'arrow' : ''}></span>
              </li>
              <li
                className={[
                  ordering.includes('position') ? 'active noselect' : 'noselect',
                  'flex-1',
                  'text-center'
                ].join(' ')}
                onClick={() =>
                  ordering === 'position' ? setOrdering('-f_i_o') : setOrdering('f_i_o')
                }
              >
                Должность&nbsp;
                <span className={ordering.includes('position') ? 'arrow' : ''}></span>
              </li>
              <li
                className={[
                  ordering.includes('f_i_o') ? 'active noselect' : 'noselect',
                  'flex-1',
                  'text-center'
                ].join(' ')}
                onClick={() =>
                  ordering === 'f_i_o' ? setOrdering('-f_i_o') : setOrdering('f_i_o')
                }
              >
                ФИО&nbsp;
                <span className={ordering.includes('f_i_o') ? 'arrow' : ''}></span>
              </li>
            </ul>
          </li>

          <li className="auto-overflow fixed-height hide-scroll-bar">
            <ul className="table px-0">
              {users.length
                ? users.map((item) => (
                    <li key={item.id} className="mb-2 table-row">
                      <ul
                        className="d-flex m-0 p-0"
                        onClick={() => {
                          dispatch({ type: 'setShure' });
                          setCurrentUser(item);
                        }}
                      >
                        <li className="flex-1 text-center border_right overflow-hidden position-relative">
                          <span className="default_link py-4 d-inline-block w-100">&nbsp;</span>
                        </li>
                        <li className="flex-1 text-center border_right overflow-hidden position-relative">
                          <span className="default_link py-4 d-inline-block w-100">&nbsp;</span>
                        </li>
                        <li className="flex-1 text-center position-relative">
                          <span className="default_link color-orrange py-4 d-inline-block w-100">
                            {item.f_i_o}
                          </span>
                        </li>
                      </ul>
                    </li>
                  ))
                : ''}
            </ul>
          </li>
        </ul>
      </div>

      {showShure ? <Shure user={currentUser} /> : ''}
    </>
  );
}
