import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import TasksButtonActions from '../hooks/TasksButtonActions.jsx';
import { getCategory, getTaskById, cancelSending } from '../api/greenApi';
import TaskOldApi from '../components/tasks/taskOldApi.jsx';
import TaskFlexApi from '../components/tasks/taskFlexApi.jsx';

import { ReactComponent as LeftArrowIcon } from '../assets/images/icons/leftArrowIcon.svg';
import { ReactComponent as DottedMenuIcon } from '../assets/images/icons/dottedMenuIcon.svg';

import Loading from '../../../common/components/UI/Loading.jsx';
import Alert from '../components/UI/Alert.jsx';
import ExtraActionModal from '../components/UI/ExtraActionModal.jsx';
import Shure from '../components/UI/Shure.jsx';
import Success from '../components/UI/Success.jsx';
import Error from '../components/UI/Error.jsx';
import ConfirmActionModal from '../components/UI/ConfirmActionModal.jsx';

export default function ItemTask() {
  const [task, setTask] = useState({});
  const [currentCat, setcurrentCat] = useState({});
  const [checkboxFieldValue, setCheckboxFieldValue] = useState(false);

  const [buttons, setButtons] = useState([]);

  const { id, code, api } = useParams();
  const [load, setLoad] = useState(true);

  const navigate = useNavigate();

  const commentFieldReference = useRef(null);
  const dispatch = useDispatch();
  const showWarning = useSelector((state) => state.greenButton.showWarning);
  const showShure = useSelector((state) => state.greenButton.showShure);
  const showError = useSelector((state) => state.greenButton.showError);

  const [answer, setAnswer] = useState('');
  const [selectedTasks, setSelectedTasks] = useState([]);

  const updateTask = () => {
    console.info('--Tasks Updated');
  };

  const pageUrlParams = { pageCode: code, pageApi: api };

  const handleChangeCheckboxField = (event) => {
    const val = event.target.type == 'checkbox' ? event.target.checked : event.target.value;
    setCheckboxFieldValue(val);
  };

  const {
    onClickButton,
    clickedButton,
    commentField,
    showSuccess,
    ConfirmModal,
    postAction,
    loadingData,
    answerData
  } = TasksButtonActions({
    tasks: selectedTasks,
    updateTask,
    pageUrlParams,
    handleChangeCheckboxField
  });

  useEffect(() => {
    if (task && Object.keys(task).length > 0) {
      setSelectedTasks([task]);
    }
  }, [task]);

  useEffect(() => {
    setLoad(loadingData);
    setAnswer(answerData);
  }, [loadingData, answerData]);

  useEffect(() => {
    getCategory()
      .then((resp) => {
        const allCat = resp.data;
        let getCategory = allCat.filter((e) => e.code === code);
        if (getCategory.length > 0) {
          setcurrentCat(getCategory[0]);
        }

        setAnswer('');
      })
      .catch(() => {
        const message = 'Не удалось получить категории';
        console.info('--getCategory Error', message);
        setAnswer(message);
      })
      .finally(() => setLoad(false));

    getTaskById(id)
      .then((resp) => {
        if (!resp) {
          console.info('страница не найдена', { id: Math.random, position: 'top-right' });
          navigate('/inbox');
          return;
        }
        const getTask = resp.data;
        setTask(getTask);
        setButtons(getTask.buttons);

        setAnswer('');
      })
      .catch(() => {
        const message = 'Не удалось получить категории';
        console.info('--getTaskById Error', message);
        setAnswer(message);
      })
      .finally(() => setLoad(false));
  }, []);

  const confirmAction = (success) => {
    ConfirmModal.setActiveConfirmModal(false);
    const is_checkbox = task.buttons?.find((item) => item.checkbox === 1)?.checkbox;

    if (success) {
      postAction({
        guid_in_1c: task.guid_in_1c,
        is_checkbox: is_checkbox === 1,
        checkbox_value: checkboxFieldValue
      });
    }
  };

  useEffect(() => {
    if (
      commentField.status === 1 &&
      commentFieldReference !== null &&
      commentFieldReference !== undefined
    ) {
      if (commentFieldReference.current) {
        commentFieldReference.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [commentField]);

  const revokeTask = () => {
    cancelSending(id, {})
      .then((response) => {
        toast.success('Задача отменена');
        navigate(-1);
      })
      .catch((error) => {
        console.info('Error', error);
      });
  };

  return (
    <>
      {showWarning ? <Alert>{answer}</Alert> : ''}
      {showError ? <Error>{answer}</Error> : ''}
      {showShure ? <Shure>{answer}</Shure> : ''}
      {showSuccess ? <Success>{answer}</Success> : ''}
      {load ? <Loading /> : ''}
      {ConfirmModal.activeConfirmModal && <ConfirmActionModal confirmAction={confirmAction} />}
      <ExtraActionModal guid={task.guid_in_1c} />
      <div className="top-content-block flex align-center space-between py-3 px-3">
        <div className="flex justify-center align-center">
          <span onClick={() => navigate(-1)} className="cursor-pointer">
            <LeftArrowIcon />
          </span>

          {currentCat && (
            <div className="h5 active-page m-0 font-weight-500">
              {' '}
              {currentCat.name} <span>| {task && task.number}</span>
            </div>
          )}
        </div>

        <div className="flex align-center justify-center">
          <span
            className="mx-0 px-2 cursor-pointer d-none"
            onClick={() => dispatch({ type: 'openExtraAction' })}
          >
            <DottedMenuIcon />
          </span>
        </div>
      </div>

      <div className="container p-0 pt-3 auto-overflow data-container-bg rounded-container hide-scroll-bar">
        {!load && api === 'flex' ? <TaskFlexApi task={task} /> : <TaskOldApi task={task} />}

        {clickedButton.comment === 6 && (
          <div className="warningSix mb-2">
            <h3 className="warningSixText">
              Для этого поручения необходимо приложить документы, для этого пройдите в систему{' '}
              {currentCat?.name}
            </h3>
          </div>
        )}

        {!task?.pending_task ? (
          <div className="d-flex flex-wrap px-3 mb-3">
            {buttons &&
              buttons.map((item, id_key) => (
                <button
                  key={id_key}
                  className={
                    item['code'] === 'otkl' ? 'btn reject mx-3 my-2' : 'btn approve mx-3 my-2'
                  }
                  onClick={() => onClickButton(item)}
                >
                  {item['name']}
                </button>
              ))}
          </div>
        ) : null}

        {clickedButton?.name && (
          <div className="px-4 mb-2 color-gray">Выбрано: {clickedButton?.name}</div>
        )}

        {clickedButton.comment !== 6 && (
          <div className="commentField" ref={commentFieldReference}>
            <>{commentField.html}</>
          </div>
        )}

        {task?.pending_task ? (
          <div className="d-flex flex-wrap px-3 mb-3">
            <button onClick={revokeTask} className="btn mx-3 my-2 black-btn">
              Отменить
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
}
